import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    borderRadius: 12,
    backgroundColor: "#EAECED",
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
  },

  header: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5em 7.7em 2.5em',
    [theme.breakpoints.down('sm')]: {
      padding: '5em 1.6em 2.5em',
    },
  },

  errorBox: {
    display: 'flex',
  },

  alertTitle: {
    color: "#B00020",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "600",
    margin: 0,
    marginLeft: 5,
  },

  alertBtn: {
    width: 223,
    display: 'block',
    textTransform: "inherit",
    // marginBottom: 35,
    backgroundColor: "#B00020",
    color: "#FFF",
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    fontWeight: "600",
    '&:hover': {
      backgroundColor: "#B00020",
    }
  },

  noPointer: {
    cursor: 'auto',
  },

  title: {
    color: " rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: '500',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },

  cardBody: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    // alignItems: 'center',
    padding: '0 7.7em 3.5em',
    [theme.breakpoints.down('sm')]: {
      padding: '0 1.6em 3.5em',
    },
  },

  statusContainer: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 20,
    },
  },

  paymentText: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "600",
    marginBottom: 12,
    marginTop: 0,
  },

  statusTitle: {
    color: "rgba(0,0,0,0.74)",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "500",
    marginBottom: 12,
    marginTop: 0,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 7,
    },
  },

  statusBody: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "bold",
  },

  duydownText: {
    color: "#198100",
    fontFamily: "Montserrat",
    fontSize: 11,
    fontWeight: "700",
  },

  cardFooter: {
    width: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    padding: '2.6em 3em 2.6em 7.7em',
    boxShadow: "0 1px 5px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('sm')]: {
      padding: '2.6em 1.6em',
    },
  },

  footerText: {
    color: "rgba(0,0,0,0.74)",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "400",
    marginBottom: 12,
    letterSpacing: "0.5px",
    margin: 0,
  },

  bankDetails: {
    maxWidth: '190px'
  },

  footerItem: {
    maxWidth: '33%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: '100%',
      marginBottom: 10,
    },
  },



  btn: {
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "600",
    [theme.breakpoints.down('xs')]: {
      width: 264,
      display: 'block',
      margin: '0 auto',
    },
  },

  disabledBtn: {
    width: 307,
    backgroundColor: "#A0A0A0",
    color: "#FFF",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "500",
    '&:hover': {
      backgroundColor: "#A0A0A0",
    },
    [theme.breakpoints.down('xs')]: {
      width: 264,
      display: 'block',
      margin: '0 auto',
    },
  },

  paddingFix: {
    padding: '0 7.8em 4em',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '0 1.6em 4em',
    },
  },

  alertBtnCurrent: {
    cursor: "default",
    backgroundColor: "#76BD1D",
    '&:hover': {
      backgroundColor: "#76BD1D",
    }
  },
  alertBtnDelequent: {
    backgroundColor: "#C3791A",
    cursor: 'auto',
    '&:hover': {
      backgroundColor: "#C3791A",
    }
  },

  flexBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 7.8em',
    [theme.breakpoints.down('sm')]: {
      padding: '0 1.6em',
    },
  },

  flexBoxAuto: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },

  flexBetween: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
  },

  financialText: {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    color: 'rgba(0,0,0,0.74)',
    fontWeight: '500',
    margin: 0,
  },

  financialLink: {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    color: 'rgba(0,0,0,0.87)',
    fontWeight: '600',
    margin: 0,
  },

  currentLoanBox: {
    margin: '0 0 15px'
  },

  currentLoanMsg: {
    color: '#C3791A',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '0.1px',
    lineHeight: '16px',
    marginLeft: '5px'
  },

  linkStyle: {
    color: theme.palette.secondary.main,
    fontSize: '1.4em',
    fontWeight: '600',
  }

}));

