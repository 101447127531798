import React, { useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  Box, Toolbar, IconButton,
  Menu, MenuItem, Badge, Button,
  FormControl, Select, Tooltip
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { SideBar } from '../../components/SideBar'
import { useStyles, BootstrapInput } from './styles'
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import bsiLogo from '../../assets/bsiLogo.svg';
import sunAmericaLogo from '../../assets/sunAmericaLogo.svg';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Cookies from 'js-cookie';
import { maskMobile, signOut, maskLoan, isAdmin, getPointPrivateLabel } from '../../helpers';
import { decrypt } from '../../helpers/encrypt';
import {
  getAllLoans, getNotifications, selectCurrenAccountNum,
  setPaymentOption, selectCurrenAccount, userLogoutState,
  deleteAccessTokenID, getDocLanverDetails
} from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { MarqueeText, AlertBannerText, LogoutPopup, LeavingSiteConfirmation } from '../../components';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import New from "../../assets/new-release.png";
import HelpIcon from '@material-ui/icons/Help';
import { makeStyles } from '@material-ui/core/styles';

import { getAppSource, getUserType, getMortgageLabel } from '../../helpers'
import { insertActivityLog, hideAnnouncement,updateReleaseAnnouncements, getClientName } from '../../actions'
import { LogoutConfirmation } from '../LogoutConfirmation/LogoutConfirmation';

const tooltipStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  customWidth: {
    maxWidth: 800,
    fontSize: 14,
    bottom: 23,
  },
  noMaxWidth: {
    maxWidth: 'none',
  },

}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    marginTop: '15px'
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    borderBottom: '2px solid #c3c3c3',
    padding: '5px 10px',
    '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
      color: '#202020',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      letterSpacing: '0.36px',
      lineHeight: '19px',
      maxWidth: '200px',
      wordWrap: 'break-word',
    },
    '&:last-child': {
      borderBottom: 'none',
    },
    '&:focus': {
      backgroundColor: '#e8e8e8',
    },
  },
}))(MenuItem);

const LightTooltip = withStyles((theme) => ({
  arrow: {
    color: '#235A86',
    // backgroundColor: '#ffffff',
  },
  tooltip: {
    backgroundColor: '#235A86',
    color: '#fffff',
    fontFamily: "Montserrat",
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    padding: "16px",
    fontSize: 14,
    maxWidth: 350,
    fontWeight: 500,
    borderRadius: 8,
    // border: "1px solid #4D4D4D"
  },
}))(Tooltip);

export const PrivateHeader = (props) => {
 
  const location = useLocation();
  const theme = useSelector(state => state.theme);
  const releaseAnnouncements = useSelector(state => state.releaseAnnouncement);
  const selectedAccountData = useSelector(state => state.selectedAccountData);
  const accInfo = useSelector(state => state.accountInfoData);
  const { primaryColorCode, secondaryColorCode } = theme;
  const privateLabelUrl = Cookies.get("privateLabelUrl")
  const isDocRequestAllowed = Cookies.get('isDocRequestAllowed');
 
  const {
    privateLabelLogoURL, privateLabelName, maintenanceMessage
  } = theme;
  // const refinancePage = lstPageMenuDetails && lstPageMenuDetails.length !== 0 ? lstPageMenuDetails.find(element => element.pageCode === 'PC014') : null;
  const messages = useSelector(state => state.messages?.data);
  const count = useSelector(state => state.messages?.count);
  const subOrdinationStatus = Cookies.get('subOrdinate');
  const isMenuExtentionMaturity = Cookies.get('maturityExtensionMenu');
  const escrowStatus = Cookies.get('escrow');
  const promisePlan =  Cookies.get('promisePlan');
  const browserName = Cookies.get('browser');
  const [messageCount, setMessageCount] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [loan, setLoan] = React.useState('');
  const [data, setData] = React.useState([])
  const [anchorE3, setAnchorE3] = React.useState(null);
  const [login, setLogin] = React.useState(false);
  const [menuOption, setMenuOption] = React.useState('');
  const [anchorEls, setAnchorEls] = React.useState({
    notificationAnchorEl: null,
    userAnchorEl: null,
    paymentOptionAnchorEl: null,
    loanDetailAnchorEl: null,
    documentCenterAnchorEl: null,
    helpAnchorEl: null,
    getNewLoanOptionAnchorEl: null
  });
 
  const selectedAccount = useSelector(state => state.selectedAccount);
  const status = useSelector(state => state.announcementReducer);
  const [taxInsuranceMenuStatus, setTaxInsuranceMenu] = React.useState(false);
  const [openToolTip, setOpenToolTip] = useState(false);
  const [logoutStatus, setLogoutStatus] = React.useState(false);
  const [fetchMsgs, setFechMsgs] = useState(false);
  const [popupStatus, setPopup] = useState(false);
  const [link, setLink] = useState();
  const [activityRemark, setActivityRemark] = useState('');
  const [stayHereRemark , setStayHereActivityRemark] = useState('');

 
  const handleTooltipClose = () => {
    setOpenToolTip(false);
  };

  const handleTooltipOpen = () => {
    setOpenToolTip(true);
  };
  
  useEffect(() => {
    if ((location.search.split('?loan=')[1] !== undefined) && data?.length > 0) {
      const queryParams = new URLSearchParams(location.search)
      if (queryParams.has('loan')) {
        queryParams.delete('loan')
        handleLoanChange(location.search.split('?loan=')[1])
        history.replace({
          search: queryParams.toString(),
        })
      }
    }
  }, [(location.search.split('?loan=')[1] !== undefined) && data?.length > 0])
  
  const handleLoanChange = (val) => {
    setLoan(val);
    Cookies.set('selectedAccount', val);
    Cookies.set('isUpcomingPayDueOpened',true);
    const selectedAccountData = data.filter(item => item.accountNumber === val)[0];

    dispatch(selectCurrenAccount(selectedAccountData));
    dispatch(selectCurrenAccountNum(val))
    getAccessPermission(val)
    if (props.switchPage) {
      props.switchPage(val)
    }
    getData()
  };

  useEffect(() => {
     const timer = setTimeout(() => {
        updateReleaseAnnouncement()
      }, 60000);
      return () => {
        clearTimeout(timer);
      }
  }, [])
 
  const hideReleaseAnnouncement = async () => {
    await dispatch(hideAnnouncement(false));
  }

  useEffect(() => {
    if (Cookies.get('privateToken')) {
      setLogin(true)
      getData();
    }
  }, [])
  
  useEffect(() => {
    if (releaseAnnouncements?.length > 0) {
      var filterData = releaseAnnouncements?.filter(item => item.pageURL == 'EscrowManage')
      if (filterData?.length > 0) {
        setTaxInsuranceMenu(true)
      } else {
        setTaxInsuranceMenu(false)
      }
    } else {
      if (Cookies.get('releaseIds') !== undefined) {
      const releaseIds = JSON.parse(Cookies.get('releaseIds'))
      var releaseIdData = releaseIds?.filter(item => item.pageURL == 'EscrowManage')
      if (releaseIdData?.length > 0) {
        setTaxInsuranceMenu(true)
      } else {
        setTaxInsuranceMenu(false)
      }
    }
  }
 
  }, [releaseAnnouncements])

 
  const updateReleaseAnnouncement = async () => {
      if (status && !isAdmin() && Cookies.get('privateToken') && (Cookies.get('announcement') == 'true')) {
        const response = await updateReleaseAnnouncements();
        Cookies.remove('releaseIds');
        Cookies.remove('announcement');
        setTaxInsuranceMenu(false)
        hideReleaseAnnouncement()
      }
  }

  useEffect(() => {
    if (messages && messages?.length !== 0) {
      setMessageCount(count)
    } else if (!fetchMsgs) {
      setFechMsgs(true)
      setTimeout(() => {
        dispatch(getNotifications());
      }, 1000)
    }
    if (messages?.length == 0) {
      setMessageCount(0)
    }
  }, [messages])
  
  useEffect(() => {
    if (props.accountNumber && props.accountNumber.length !== 0 && data.length) {
      setLoan(props.accountNumber);
      Cookies.set('selectedAccount', props.accountNumber);
      if (data.length) {
        const selectedAccountData = data.filter(item => item.accountNumber === props.accountNumber)[0];
        dispatch(selectCurrenAccount(selectedAccountData));
      }
    }
  }, [props.accountNumber]);


  const handleLogOut = async () => {
    await deleteAccessTokenID();
    setLogin(false);
    handleClose();
    signOut(() => {
    const privatelabel = window.location.pathname.split('/')[1];
    // if (privatelabel == 'bsi' && Cookies.get('clientName') != 'VRM') {
    //    Cookies.remove('clientName');
    //    history.push({pathname : '/SignIn', state: { fromLogout: true }})
    //    window.location.reload(false)
    //  } else {
       Cookies.remove('clientName');
       history.push({pathname : '/SignIn', state: { fromLogout: true }})
      
    //  }
    })
    setTimeout(() => {
      dispatch(userLogoutState());
      window.location.reload(false)
    }, 1000);
  }

  const getData = async () => {
    const selectedAccount = await Cookies.get('selectedAccount')
    const res = await dispatch(getAllLoans())
    if (res.length > 0) {
      setData(res);
      getAccessPermission(selectedAccount)
      await Cookies.set('totalAccounts', res.length)
      if ((Cookies.get('loanAccounts') == undefined) || (Cookies.get('loanAccounts') == 'undefined')) {
        const accounts = res?.map((item) => ({accountNumber: decrypt(item.accountNumber) , eaPopup1Status: true, eaPopup2Status: true, propertyAddress1: item.propertyAddress1}))
        await Cookies.set('loanAccounts' ,accounts)
      }
      const selectedAccountData = res.filter(item => item.accountNumber === selectedAccount)[0];
      if (selectedAccountData) {
        setLoan(selectedAccount);
        dispatch(selectCurrenAccount(selectedAccountData));
        dispatch(selectCurrenAccountNum(selectedAccount))
      } else {
        await Cookies.set('selectedAccount', res[0].accountNumber);
        setLoan(res[0].accountNumber);
        dispatch(selectCurrenAccount(res[0]));
        dispatch(selectCurrenAccountNum(res[0].accountNumber))
      }
    }
    
    return;
  }
 
  const getAccessPermission = async (loan) => {
    const data = {
      "accountNumber": loan,
      "userName": "",
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType()
    }

    const result = await dispatch(getClientName(data)); 
    if (result) {
      const { lstResponseStatus, bankDetail, featureToggle, purgeLoanInfo } = result
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        Cookies.set("paidInFullFlag", ((purgeLoanInfo.liquidationStatus) && (purgeLoanInfo.liquidationType == 'PayOff')) ? true : false);
        Cookies.set("serviceReleasedFlag", ((purgeLoanInfo.liquidationStatus) && (purgeLoanInfo.liquidationType == 'ServiceTransfer')) ? true : false)
        Cookies.set('escrow', bankDetail?.displayEscrowMenu)
        Cookies.set('chargedOffFlag', ((purgeLoanInfo.liquidationStatus) && (purgeLoanInfo.liquidationType == 'ChargeOff')) ? true : false)
        Cookies.set('fclCompletedFlag', ((purgeLoanInfo.liquidationStatus) && (purgeLoanInfo.liquidationType == 'FCLCompleted')) ? true : false)
      }
    }    
  }

  const handleAddressChange = (event) => {
    setLoan(event.target.value);
    Cookies.set('selectedAccount', event.target.value);
    Cookies.set('isUpcomingPayDueOpened',true);
    const selectedAccountData = data.filter(item => item.accountNumber === event.target.value)[0];
    dispatch(selectCurrenAccount(selectedAccountData));
    dispatch(selectCurrenAccountNum(event.target.value))
    getAccessPermission(event.target.value)
    if (props.switchPage) {
      props.switchPage(event.target.value)
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClick = (prop) => (event) => {
    setMenuOption(prop);
    setAnchorEls({ ...anchorEls, [prop]: event.currentTarget });
  };
  
  const handleClose = (prop,) => {
    setMenuOption(null);
    setAnchorEls({ ...anchorEls, [prop]: null });
  }

  const handleCloseRefinance = (prop,) => {
    sendActivityData(loan)
    setMenuOption(null);
    setAnchorEls({ ...anchorEls, [prop]: null });
  }

  const sendActivityDataOnWhatsNew = async (accountNumber) => {
    const text = "User Clicked on “What’s New” Icon from Header.";
    const data = {
      "accountNumber": accountNumber,
      "activityCode": "UAL194",
      "activityStatus": "Success",
      "action": "GetAccountInfo",
      "controller": "Dashboard",
      "apiName": "GetAccountInfo",
      "activityRemark": text,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    await insertActivityLog(data);
  }
 
  const sendActivityData = async (accountNumber) => {
   
    const text = "User clicked on Refinance Your Loan option under Loan Details Menu.";
    const remark =  accInfo.refinanceURL.includes('gomortgage') ? 'GoMortgage - ' + text : 
    accInfo.refinanceURL.includes('alliedmg') ? 'AlliedMortgage - ' + text :  `User clicked on Refinance Your Loan submenu under Loan Details Menu of MLW and redirected to refinance tile (${accInfo?.refiCompany})`
    const data = {
      "accountNumber": accountNumber,
      "activityCode": "UAL202",
      "activityStatus": "Success",
      "action": accInfo?.refiCompany,
      "controller": "Dashboard",
      "apiName": "GetAccountInfo",
      "activityRemark": remark,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    await insertActivityLog(data);
  }

  const sendActivityLog = async (remark) => {
   
    const data = {
      "accountNumber": loan,
      "activityCode": "UAL202",
      "activityStatus": "Success",
      "action": accInfo?.refiCompany,
      "controller": "Dashboard",
      "apiName": "GetAccountInfo",
      "activityRemark": remark,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    await insertActivityLog(data);
  }

  const handleUserClick = (event) => {
    setAnchorE3(event.currentTarget);
  };

  const handleUserClose = () => {
    setAnchorE3(null);
  };

  const handleNavigation = (link) => {
    handleUserClose()
    history.push(`/${link}`)
  }

  const handleNavigationToPaymentPage = (option) => {
    handleClose('paymentOptionAnchorEl');
    dispatch(setPaymentOption(option));
    history.push('/PaymentOption');
  }

  const handleBackToAdmin = async () => {
    const roleID = Number(decrypt(Cookies.get('roleID')));
    let to = '';
    switch (roleID) {
      case 1:
        to = '/Admin/Admindashboard';
        break;
      case 2:
        to = '/Admin/AdminUserDetails';
        break;
      case 3:
        to = '/Admin/Admindashboard';
        break;
      default:
        to = '/Admin'
    }
    setLogin(false);
    await deleteAccessTokenID();
    Cookies.remove('privateToken');
    Cookies.remove('accessTokenID');
    Cookies.remove('selectedAccount');
    Cookies.remove('totalAccounts');
    window.location.href = to;
  }

  const routePage = () => {
    const base = window.location.pathname.split('Notification')[0];
    const base1 = window.location.pathname.split('Notification')[1];
    handleClose('notificationAnchorEl');
    history.push('/Notification')
    if (base1) {
      window.location.href = base + 'Notification'
    } else {
      history.replace('/Notification');
    }
  }

  const route = () => {
    handleCloseRefinance('loanDetailAnchorEl');
    if (accInfo.refinanceURL !== null && accInfo.refinanceURL !== " ") {
      if (accInfo.refinanceURL.toLowerCase().includes('RefinanceAdvertisement'.toLowerCase())) {
        history.push(accInfo.refinanceURL + `?refiCompany=${accInfo?.refiCompany}`)
      } else {
        window.open(accInfo.refinanceURL, '_blank')
      }
  }
  }

  const routeToWhatsNew = () => {
      sendActivityDataOnWhatsNew()
      history.push('Whatsnew')
  }
  
  const routeToDocumentCenter = async () => {
    handleClose('documentCenterAnchorEl');
    const res = await getDocLanverDetails(selectedAccount);
    if (res) {
      var newWin = window.open(res.url,"_blank");             
      if(!newWin || newWin.closed || typeof newWin.closed=='undefined') 
        { 
            alert('A popup blocker is preventing the application from opening the page. To allow pop-ups, go to Safari app and choose Safari>Preferences, then click websites and allow popup for your website.')
        }
      }
  }
 
  const handleCancelIcon = () => {
    setLogoutStatus(false)
  }
 
  const handleSubmitClose = () => {
    setLogoutStatus(false)
  }

  const handleSubmit = () => {
    setLogoutStatus(false)
    handleLogOut()
  }

  const handleLogOutConfirmation = () => {
    setLogoutStatus(true)
  } 

  const openPopup = (url, remark, stayHereRemark) => {
    handleClose('getNewLoanOptionAnchorEl')
    setLink(url)
    setActivityRemark(remark)
    setStayHereActivityRemark(stayHereRemark)
    // sendActivityLog(remark)
    setPopup(true)
  }

  const handleClosePopup = () => {
    setActivityRemark('')
    sendActivityLog(stayHereRemark)
    setStayHereActivityRemark('')
    setPopup(false)
  }

  const handleOk = () => {
    setPopup(false)
    sendActivityLog(activityRemark)
    window.open(link, '_blank')
  }

  const {
    notificationAnchorEl,
    paymentOptionAnchorEl,
    loanDetailAnchorEl,
    documentCenterAnchorEl,
    helpAnchorEl,
    getNewLoanOptionAnchorEl
  } = anchorEls

  return (
    <div className={`${classes.root} ${(!getPointPrivateLabel() && maintenanceMessage !== '' && maintenanceMessage !== null) ? classes.marqueeRoot : classes.marqueeNotRoot}`}>
        {(!getPointPrivateLabel() && maintenanceMessage !== '' && maintenanceMessage !== null) && <MarqueeText text={maintenanceMessage} />}
      <CssBaseline />
      <AppBar position="fixed" className={`${classes.appBar} ${(!getPointPrivateLabel() && maintenanceMessage !== '' && maintenanceMessage !== null) ? classes.marqueeAppBar : classes.marqueeNotAppBar}`} >
        <Toolbar>
          {!props.isMulti && data && data.length !== 0 &&
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          }

          <Link to="/Dashboard" className={classes.logoContainer}>
            {/* {privateLabelUrl.toUpperCase() === "BSI" ? <img className={classes.logoStyle} src={bsiLogo} alt="logo" /> : <img className={classes.logoStyle} src={sunAmericaLogo} alt="logo" />} */}
            <img className={classes.logoStyle} src={privateLabelLogoURL} alt={privateLabelName} />
          </Link>

          <Box className={classes.grow} />

          {!props.isMulti && data && data.length !== 0 &&
            <div className={classes.addressContainer}>
              <FormControl variant="outlined" color='primary' className={classes.formControl}>
                <Select
                  className={classes.selectStyle}
                  labelId="select-address"
                  id="address-select-outlined"
                  label="Address"
                  value={loan}
                  onChange={handleAddressChange}
                  IconComponent={ExpandMoreIcon}
                  classes={{
                    select: classes.addressListStyle
                  }}
                  input={<BootstrapInput />}
                >
                  {data.map((item, index) => <MenuItem key={`${index}_${item.propertyAddress}`} className={classes.addressListStyle} value={item?.accountNumber}>{`${maskLoan(decrypt(item?.accountNumber))} - ${item.propertyAddress1}`}</MenuItem>)}
                </Select>
              </FormControl>
            </div>
          }

          <Box className={classes.grow} />

          <Box className={classes.iconTopMargin}>
            <MenuItem>
              <IconButton
                aria-label={`show ${messageCount} new notifications`}
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleClick('notificationAnchorEl')}
                onMouseOver={handleClick('notificationAnchorEl')}
                color="inherit"
              >
                <Badge badgeContent={messageCount} color="primary">
                  <NotificationsIcon classes={{ root: classes.colorIcon }} />
                </Badge>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={notificationAnchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                style={{top : '50px'}}
                open={Boolean(notificationAnchorEl)}
                onClose={() => handleClose('notificationAnchorEl')}
                MenuListProps={{ onMouseLeave: () => handleClose('notificationAnchorEl') }}
              >
                {((messages?.length > 0) && (messages != 'null')) ? messages?.map((item, index) =>
                  (<MenuItem component={Link} to={`/Notification/${item.notificationId}`} key={index} onClick={() => handleClose('notificationAnchorEl')}>{item.notificationTitle}</MenuItem>)) : null}
                <MenuItem onClick={() => routePage()}>Message center</MenuItem>
              </Menu>
            </MenuItem>
          </Box>

          <Box className={classes.iconTopMargin2}>
            <MenuItem>
              <IconButton
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => routeToWhatsNew()}
                color="inherit"
              >
                <LightTooltip
                arrow  placement="bottom"
                  title={"What's New"}
                >
                  <HelpIcon classes={{ root: classes.colorIcon }} onClick={handleTooltipOpen} />
                </LightTooltip>
               
              </IconButton>
             
            </MenuItem>
          </Box>

          <Box className={classes.iconTopMargin3}>
            <IconButton className={classes.profileIcon} aria-controls="user-menu" aria-haspopup="true" onClick={handleUserClick}>
              <AccountCircle classes={{ root: classes.colorIcon }} />
              <ExpandMoreIcon classes={{ root: classes.colorIcon }} />
            </IconButton>
            <Menu
              style={{top : '40px'}}
              id="user-menu"
              anchorEl={anchorE3}
              keepMounted
              open={Boolean(anchorE3)}
              onClose={handleUserClose}
              MenuListProps={{ disablePadding: true }}
            >
              <MenuItem onClick={() => handleNavigation('UserProfile/Profile')} className={classes.menuItemStyle}>My Profile</MenuItem>
              <MenuItem onClick={() => handleNavigation('UserProfile/CommunicationPreferences')} className={classes.menuItemStyle}>Communication Preferences</MenuItem>
              <MenuItem onClick={() => handleNavigation('UserProfile/ChangePassword')} className={classes.menuItemStyle}>Change Password</MenuItem>
              <MenuItem onClick={handleLogOutConfirmation} className={classes.menuItemStyle}>Sign Out</MenuItem>
            </Menu>
          </Box>
        </Toolbar>

        <Box className={classes.menuContainer}>
          {data && data.length !== 0 &&
            <Box className={classes.menuWrapper}>
              {!props.isMulti && data && data.length !== 0 &&
                <React.Fragment>
                  <div className={classes.positionRelative}>
                    <Button className={`${classes.linkStyle} ${menuOption == 'homeAnchorEl' ? 'isActive' : ''}`}
                      component={Link} to='/Dashboard'
                      aria-controls="simple-menu" aria-haspopup="true"
                      onClick={handleClick('homeAnchorEl')}>
                      Home
                    </Button>
                    {menuOption == 'homeAnchorEl' && <span className={classes.borderBottom}></span>}
                  </div>
                  { !getPointPrivateLabel() &&
                  <div className={classes.positionRelative}>
                    <Button className={`${classes.linkStyle} ${menuOption == 'paymentOptionAnchorEl' ? 'isActive' : ''}`}
                      aria-controls="paymentOptionAnchorEl-menu"
                      aria-haspopup="true" onClick={handleClick('paymentOptionAnchorEl')}
                      endIcon={<ExpandMoreIcon />}
                      paperprops={{
                        style: {
                          borderBottom: '4px solid red'
                        },
                      }}>
                      Payments
                    </Button>
                    <StyledMenu
                      id="paymentOptionAnchorEl-menu"
                      anchorEl={paymentOptionAnchorEl}
                      keepMounted
                      open={Boolean(paymentOptionAnchorEl)}
                      onClose={() => handleClose('paymentOptionAnchorEl')}
                      PaperProps={{
                        style: {
                          width: '193px',
                          paddingLeft: 5,
                        },
                      }}
                    >
                      <StyledMenuItem className={classes.menuLinkStyle} onClick={() => handleNavigationToPaymentPage('FAQ')}>
                        <ListItemText primary="Payment Options" />
                      </StyledMenuItem>
                      <StyledMenuItem className={classes.menuLinkStyle} onClick={() => handleNavigationToPaymentPage('ONETIMEPAYMENT')}>
                        <ListItemText primary="One-Time Payment" />
                      </StyledMenuItem>
                      <StyledMenuItem className={classes.menuLinkStyle} onClick={() => handleNavigationToPaymentPage('MANAGEAUTOPAY')}>
                        <ListItemText primary="Set Up Autopay" />
                      </StyledMenuItem>
                      <StyledMenuItem component={Link} to='/ManageBankAccount' className={classes.menuLinkStyle} onClick={() => handleClose('paymentOptionAnchorEl')}>
                        <ListItemText primary="My Bank Accounts" />
                      </StyledMenuItem>
                      <StyledMenuItem component={Link} to="/TransactionHistory" className={classes.menuLinkStyle} onClick={() => handleClose('paymentOptionAnchorEl')}>
                        <ListItemText primary="Transaction History" />
                      </StyledMenuItem>
                      <StyledMenuItem component={Link} to="/FinancialHardship" className={classes.menuLinkStyle} onClick={() => handleClose('paymentOptionAnchorEl')}>
                        <ListItemText primary="Financial Hardship" />
                      </StyledMenuItem>
                      {promisePlan == 'true' &&
                        <StyledMenuItem component={Link} to="/PromiseToPay" className={classes.menuLinkStyle} onClick={() => handleClose('paymentOptionAnchorEl')}>
                          <ListItemText primary="Promise Plan" />
                        </StyledMenuItem> }
                    </StyledMenu>
                    {menuOption == 'paymentOptionAnchorEl' && <span className={classes.borderBottom}></span>}
                  </div>
                 }
                { ((Cookies.get('refinanceFlag') == 'true') && !getPointPrivateLabel()) &&
                  <div className={classes.positionRelative}>
                    
                     <div className={classes.badge} style={{backgroundColor: primaryColorCode}}>new</div>
                    
                    <Button className={`${classes.linkStyle} ${menuOption == 'getNewLoanOptionAnchorEl' ? 'isActive' : ''}`}
                      aria-controls="getNewLoanOptionAnchorEl-menu"
                      aria-haspopup="true" onClick={handleClick('getNewLoanOptionAnchorEl')}
                      endIcon={<ExpandMoreIcon />}
                      paperprops={{
                        style: {
                          borderBottom: '4px solid red'
                        },
                      }}>
                      Get A new loan 
                    </Button>
                    <StyledMenu
                      id="getNewLoanOptionAnchorEl-menu"
                      anchorEl={getNewLoanOptionAnchorEl}
                      keepMounted
                      open={Boolean(getNewLoanOptionAnchorEl)}
                      onClose={() => handleClose('getNewLoanOptionAnchorEl')}
                      PaperProps={{
                        style: {
                          width: '193px',
                          paddingLeft: 5,
                        },
                      }}
                    >
                      <StyledMenuItem className={classes.menuLinkStyle} onClick={() => openPopup('https://bsifinancialrefinanceonline.mymortgage-online.com/', 'User clicked on  Get a New Loan>Refinance Submenu with “Continue” button on MLW', 'User clicked on  Get a New Loan>Refinance Submenu with “Stay Here” button on MLW')}>
                        <ListItemText primary="Refinance" />
                      </StyledMenuItem>
                      <StyledMenuItem className={classes.menuLinkStyle} onClick={() => openPopup('https://bsifinancialrefinanceonline.mymortgage-online.com/', 'User clicked on  Get a New Loan>Equity Loan Submenu with “Continue” button on MLW', 'User clicked on  Get a New Loan>Equity Loan Submenu with “Stay Here” button on MLW')}>
                        <ListItemText primary="Equity Loan" />
                      </StyledMenuItem>
                      <StyledMenuItem className={classes.menuLinkStyle} onClick={() => openPopup('https://bsifinancialrefinanceonline.mymortgage-online.com/','User clicked on  Get a New Loan>Purchase Submenu with “Continue” button on MLW', 'User clicked on  Get a New Loan>Purchase  Submenu with “Stay Here” button on MLW')}>
                        <ListItemText primary="Purchase" />
                      </StyledMenuItem>
                      <StyledMenuItem className={classes.menuLinkStyle} onClick={() => openPopup('https://bsifinancialrefinanceonline.mymortgage-online.com/RATES.html', 'User clicked on  Get a New Loan>Personal Quote Submenu with “Continue” button on MLW', 'User clicked on  Get a New Loan>Personal Quote Submenu with “Stay Here” button on MLW')}>
                        <ListItemText primary="Personal Quote" />
                      </StyledMenuItem>
                      <StyledMenuItem className={classes.menuLinkStyle} onClick={() => openPopup('https://bsifinancialrefinanceonline.mymortgage-online.com/Calculators.html', 'User clicked on  Get a New Loan>Calculators Submenu with “Continue” button on MLW', 'User clicked on  Get a New Loan>Calculators Submenu with “Stay Here” button on MLW')}>
                        <ListItemText primary="Calculators" />
                      </StyledMenuItem>
                      <StyledMenuItem className={classes.menuLinkStyle} onClick={() => openPopup('https://bsifinancialrefinanceonline.mymortgage-online.com/index.html', 'User clicked on  Get a New Loan>Learn More Submenu with “Continue” button on MLW', 'User clicked on  Get a New Loan>Learn More Submenu with “Stay Here” button on MLW')}>
                        <ListItemText primary="Learn More" />
                      </StyledMenuItem>
                      
                    </StyledMenu>
                    {menuOption == 'getNewLoanOptionAnchorEl' && <span className={classes.borderBottom}></span>}
                  </div>
              }
              { !getPointPrivateLabel() &&
                  <div className={classes.positionRelative}>
                    {((taxInsuranceMenuStatus && status) || isMenuExtentionMaturity=='true') &&
                    <div className={classes.badge} style={{backgroundColor: primaryColorCode}}>new</div>
                    }
                    <Button className={`${classes.linkStyle} ${menuOption == 'loanDetailAnchorEl' ? 'isActive' : ''}`}
                      aria-controls="loanDetailAnchorEl-menu" aria-haspopup="true" onClick={handleClick('loanDetailAnchorEl')} endIcon={<ExpandMoreIcon />}>
                      Loan Details
                    </Button>
                    <StyledMenu
                      id="loanDetailAnchorEl-menu"
                      anchorEl={loanDetailAnchorEl}
                      keepMounted
                      open={Boolean(loanDetailAnchorEl)}
                      onClose={() => handleClose('loanDetailAnchorEl')}
                      PaperProps={{
                        style: {
                          width: '280px',
                        }
                      }}
                      anchorPosition={{
                        left: 0,
                        top: 80,
                      }}
                    >
                      <StyledMenuItem component={Link} to='/LoanInfo' className={classes.menuLinkStyle} onClick={() => handleClose('loanDetailAnchorEl')}>
                        <ListItemText primary="Loan Information" />
                      </StyledMenuItem>
                    
                      {escrowStatus == 'true' ?
                      <StyledMenuItem component={Link} to='/EscrowInfo' className={classes.menuLinkStyle} onClick={() => handleClose('loanDetailAnchorEl')}>
                        <ListItemText primary="Taxes & Insurance Information" />
                        {taxInsuranceMenuStatus && status &&
                          <div className={classes.menuBadge} style={{backgroundColor: primaryColorCode}}>new</div>
                          }
                      </StyledMenuItem> : null} 

                      {/* {accInfo && accInfo.isRefinance && accInfo.refinanceURL.includes('Refinance') && <StyledMenuItem component={Link} to='/Refinance' className={classes.menuLinkStyle} onClick={() => handleCloseRefinance('loanDetailAnchorEl')}>
                        <ListItemText primary="Refinance Your Loan" />
                      </StyledMenuItem>
                      }  */}
                    
                       {accInfo && accInfo.isRefinance && (privateLabelUrl.toUpperCase() === "BSI") &&  <StyledMenuItem  className={classes.menuLinkStyle} 
                      onClick={() => route()}>
                        <ListItemText primary="Refinance Your Loan" />
                      </StyledMenuItem>
                      }
                      <StyledMenuItem component={Link} to='/vom' className={classes.menuLinkStyle} onClick={() => handleClose('loanDetailAnchorEl')}>
                        <ListItemText primary="Request Mortgage Verification" />
                      </StyledMenuItem>
                      <StyledMenuItem component={Link} to='/PayoffRequest' className={classes.menuLinkStyle} onClick={() => handleClose('loanDetailAnchorEl')}>
                        <ListItemText primary="Request Payoff" />
                      </StyledMenuItem>
                      { subOrdinationStatus == 'true' ? <StyledMenuItem component={Link} to='/RequestSubordination' className={classes.menuLinkStyle} onClick={() => handleClose('loanDetailAnchorEl')}>
                        <ListItemText primary="Request Subordination" />
                      </StyledMenuItem> : null}
                      <StyledMenuItem component={Link} to='/ManageThirdPartyRequest' className={classes.menuLinkStyle} onClick={() => handleClose('loanDetailAnchorEl')}>
                        <ListItemText primary="Manage Third-Party Requests" />
                      </StyledMenuItem>
                      <StyledMenuItem component={Link} to='/WorkoutStatus' className={classes.menuLinkStyle} onClick={() => handleClose('loanDetailAnchorEl')}>
                        <ListItemText primary="Workout Status" />
                      </StyledMenuItem>
                      {isMenuExtentionMaturity=='true' && <StyledMenuItem component={Link} to='/MaturityExtension' className={classes.menuLinkStyle} onClick={() => handleClose('loanDetailAnchorEl')}>
                        <ListItemText primary="Maturity Extension Application" />
                        {isMenuExtentionMaturity=='true' &&
                          <div className={classes.menuBadge} style={{backgroundColor: primaryColorCode}}>new</div>
                        }
                      </StyledMenuItem>}
                      
                      {/* <StyledMenuItem component={Link} to='/vom' className={classes.menuLinkStyle} onClick={() => handleClose('loanDetailAnchorEl')}>
                      <ListItemText primary="VOM Request" />
                    </StyledMenuItem> */}

                    </StyledMenu>
                    {menuOption == 'loanDetailAnchorEl' && <span className={classes.borderBottom}></span>}
                  </div>}

                  { getPointPrivateLabel() &&
                  <div className={classes.positionRelative}>
                    
                    <Button className={`${classes.linkStyle} ${menuOption == 'loanDetailAnchorEl' ? 'isActive' : ''}`}
                      aria-controls="loanDetailAnchorEl-menu" aria-haspopup="true" onClick={handleClick('loanDetailAnchorEl')} endIcon={<ExpandMoreIcon />}>
                      Contract Details
                    </Button>
                    <StyledMenu
                      id="loanDetailAnchorEl-menu"
                      anchorEl={loanDetailAnchorEl}
                      keepMounted
                      open={Boolean(loanDetailAnchorEl)}
                      onClose={() => handleClose('loanDetailAnchorEl')}
                      PaperProps={{
                        style: {
                          width: '280px',
                        }
                      }}
                      anchorPosition={{
                        left: 0,
                        top: 80,
                      }}
                    >
                      <StyledMenuItem component={Link} to='/LoanInfo' className={classes.menuLinkStyle} onClick={() => handleClose('loanDetailAnchorEl')}>
                        <ListItemText primary="Contract Information" />
                      </StyledMenuItem>
                  
                      <StyledMenuItem component={Link} to='/vom' className={classes.menuLinkStyle} onClick={() => handleClose('loanDetailAnchorEl')}>
                        <ListItemText primary={`Request ${getMortgageLabel()} Verification`} />
                      </StyledMenuItem>
                      <StyledMenuItem component={Link} to='/PayoffRequest' className={classes.menuLinkStyle} onClick={() => handleClose('loanDetailAnchorEl')}>
                        <ListItemText primary="Request Payoff" />
                      </StyledMenuItem>
                  
                      <StyledMenuItem component={Link} to='/ManageThirdPartyRequest' className={classes.menuLinkStyle} onClick={() => handleClose('loanDetailAnchorEl')}>
                        <ListItemText primary="Manage Third-Party Requests" />
                      </StyledMenuItem>
                   
                    </StyledMenu>
                    {menuOption == 'loanDetailAnchorEl' && <span className={classes.borderBottom}></span>}
                  </div>}
               { !getPointPrivateLabel() &&
                <>
                {browserName.includes('Safari ') ?
                  <div className={classes.positionRelative}>
                   
                    <Button className={`${classes.linkStyle} ${menuOption == 'documentCenterAnchorEl' ? 'isActive' : ''}`}
                      aria-controls="documentCenterAnchorEl-menu" aria-haspopup="true" endIcon={<ExpandMoreIcon />}
                     onClick={handleClick('documentCenterAnchorEl')}
                      >
                     {(privateLabelUrl.toUpperCase() === "BSI") ? 'Documents & Forms' : 'Documents'}
                    </Button>
                    <StyledMenu
                      id="documentCenterAnchorEl-menu"
                      anchorEl={documentCenterAnchorEl}
                      keepMounted
                      open={Boolean(documentCenterAnchorEl)}
                      onClose={() => handleClose('documentCenterAnchorEl')}
                      PaperProps={{
                        style: {
                          width: '230px',
                        },
                      }}
                    >
                      <StyledMenuItem  className={classes.menuLinkStyle} onClick={() => routeToDocumentCenter()}>
                        <ListItemText primary="Document Center" />
                      </StyledMenuItem>
                      { (privateLabelUrl.toUpperCase() === "BSI") &&
                        <StyledMenuItem component={Link} to="/FormCenter" className={classes.menuLinkStyle} onClick={() => handleClose('documentCenterAnchorEl')}>
                          <ListItemText primary="Form Center" />
                        </StyledMenuItem>
                      }
                      {  ((privateLabelUrl.toLowerCase() !== "aafmaa") && (isDocRequestAllowed == 'true')) &&
                      <StyledMenuItem component={Link} to="/DocumentRequest"  className={classes.menuLinkStyle} onClick={() => handleClose('documentCenterAnchorEl')}>
                        <ListItemText primary="Document Requests" />
                      </StyledMenuItem>
                      }
                    </StyledMenu>
                    {menuOption == 'documentCenterAnchorEl' && <span className={classes.borderBottom}></span>}
                  </div> : 
                  <div className={classes.positionRelative}>
                    
                  <Button className={`${classes.linkStyle} ${menuOption == 'documentCenterAnchorEl' ? 'isActive' : ''}`}
                      endIcon={<ExpandMoreIcon />}
                    aria-controls="documentCenterAnchorEl-menu" aria-haspopup="true" onClick={handleClick('documentCenterAnchorEl')}>
                      {(privateLabelUrl.toUpperCase() === "BSI") ? 'Documents & Forms' : 'Documents'}
                  </Button>
                  <StyledMenu
                      id="documentCenterAnchorEl-menu"
                      anchorEl={documentCenterAnchorEl}
                      keepMounted
                      open={Boolean(documentCenterAnchorEl)}
                      onClose={() => handleClose('documentCenterAnchorEl')}
                      PaperProps={{
                        style: {
                          width: '230px',
                        },
                      }}
                    >
                      <StyledMenuItem  component={Link}  to='/DocumentCenter'  className={classes.menuLinkStyle}
                      onClick={() => handleClose()}
>                        <ListItemText primary="Document Center" />
                      </StyledMenuItem>
                      { (privateLabelUrl.toUpperCase() === "BSI") &&
                        <StyledMenuItem component={Link} to="/FormCenter" className={classes.menuLinkStyle} onClick={() => handleClose('documentCenterAnchorEl')}>
                          <ListItemText primary="Form Center" />
                        </StyledMenuItem>
                      }
                      {  ((privateLabelUrl.toLowerCase() !== "aafmaa") && (isDocRequestAllowed == 'true')) &&
                      <StyledMenuItem component={Link} to="/DocumentRequest"  className={classes.menuLinkStyle} onClick={() => handleClose('documentCenterAnchorEl')}>
                        <ListItemText primary="Document Requests" />
                      </StyledMenuItem>
                     }
                    </StyledMenu>
                  {menuOption == 'documentCenterAnchorEl' && <span className={classes. borderBottom}></span>}
                  </div> 
                 }
                 </>
                 }
                { getPointPrivateLabel() &&
                              <>
                {browserName.includes('Safari ') ?
                  <div className={classes.positionRelative}>
                   
                    <Button className={`${classes.linkStyle} ${menuOption == 'documentCenterAnchorEl' ? 'isActive' : ''}`}
                      aria-controls="documentCenterAnchorEl-menu" aria-haspopup="true" 
                     onClick={() => {handleClick('documentCenterAnchorEl');routeToDocumentCenter()}} 
                    
                      >
                      Documents
                    </Button>
                    {menuOption == 'documentCenterAnchorEl' && <span className={classes.borderBottom}></span>}
                  </div> : 
                  <div className={classes.positionRelative}>
                  
                  <Button className={`${classes.linkStyle} ${menuOption == 'documentCenterAnchorEl' ? 'isActive' : ''}`}
                     component={Link}  to='/DocumentCenter'
                    aria-controls="documentCenterAnchorEl-menu" aria-haspopup="true" onClick={handleClick('documentCenterAnchorEl')}>
                    Documents
                  </Button>
               
                  {menuOption == 'documentCenterAnchorEl' && <span className={classes. borderBottom}></span>}
                  </div> 
                 }
              </>
                 }
                 { !getPointPrivateLabel() &&
                  <div className={classes.positionRelative}>
                    <Button
                      className={`${classes.linkStyle} ${menuOption == 'helpAnchorEl' ? 'isActive' : ''}`}
                      aria-controls="helpAnchorEl-menu" aria-haspopup="true" onClick={handleClick('helpAnchorEl')} endIcon={<ExpandMoreIcon />}>
                      Support
                    </Button>
                    <StyledMenu
                      id="helpAnchorEl-menu"
                      anchorEl={helpAnchorEl}
                      keepMounted
                      open={Boolean(helpAnchorEl)}
                      onClose={() => handleClose('helpAnchorEl')}
                      PaperProps={{
                        style: {
                          width: '202px',
                        },
                      }}
                    >
                      <StyledMenuItem component={Link} to="/HelpLanding" className={classes.menuLinkStyle} onClick={() => handleClose('helpAnchorEl')}>
                        <ListItemText primary="Help" />
                      </StyledMenuItem>
                      <StyledMenuItem component={Link} to="/HelpDescription/FAQs" className={classes.menuLinkStyle} onClick={() => handleClose('helpAnchorEl')}>
                        <ListItemText primary="FAQs" />
                      </StyledMenuItem>
                      {/* <StyledMenuItem component={Link} to="/FinancialHardship" className={classes.menuLinkStyle} onClick={() => handleClose('helpAnchorEl')}>
                      <ListItemText primary="Financial Hardship" />
                    </StyledMenuItem> */}
                      {/* <StyledMenuItem component={Link} to="/Covid19" className={classes.menuLinkStyle} onClick={() => handleClose('helpAnchorEl')}>
                      <ListItemText primary="COVID - 19" />
                    </StyledMenuItem>*/}
                      {/* <StyledMenuItem component={Link} to="/FinancialInfo" className={classes.menuLinkStyle} onClick={() => handleClose('helpAnchorEl')}>
                        <ListItemText primary="Financial Information" />
                      </StyledMenuItem> */}
                      <StyledMenuItem component={Link} to="/FeesSchedule" className={classes.menuLinkStyle} onClick={() => handleClose('helpAnchorEl')}>
                        <ListItemText primary="Fees Schedule" />
                      </StyledMenuItem>
                    
                    </StyledMenu>
                    {menuOption == 'helpAnchorEl' && <span className={classes.borderBottom}></span>}
                  </div>
                 }
                   { getPointPrivateLabel() &&
                  <div className={classes.positionRelative}>
                    <Button
                      className={`${classes.linkStyle} ${menuOption == 'helpAnchorEl' ? 'isActive' : ''}`}
                      aria-controls="helpAnchorEl-menu" aria-haspopup="true" onClick={handleClick('helpAnchorEl')} endIcon={<ExpandMoreIcon />}>
                      Support
                    </Button>
                    <StyledMenu
                      id="helpAnchorEl-menu"
                      anchorEl={helpAnchorEl}
                      keepMounted
                      open={Boolean(helpAnchorEl)}
                      onClose={() => handleClose('helpAnchorEl')}
                      PaperProps={{
                        style: {
                          width: '202px',
                        },
                      }}
                    >
                      <StyledMenuItem component={Link} to="/HelpLanding" className={classes.menuLinkStyle} onClick={() => handleClose('helpAnchorEl')}>
                        <ListItemText primary="Help" />
                      </StyledMenuItem>
                     
                     
                    </StyledMenu>
                    {menuOption == 'helpAnchorEl' && <span className={classes.borderBottom}></span>}
                  </div>
                 }
                </React.Fragment>}
            </Box>
          }
          {Cookies.get('adminToken') &&
            <div className={classes.backtoAdmin}>
              <Button color='primary' className={classes.adminLinkStyle} onClick={handleBackToAdmin}>
                Back to admin
              </Button>
            </div>
          }
        </Box>
        {!props.isMulti && data && data.length !== 0 &&
          <div className={classes.addressContainerSmallScreen}>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="select-address"
                id="address-select-outlined"
                label="Address"
                value={loan}
                onChange={handleAddressChange}
                input={<BootstrapInput />}

              >
                {data.map((item) => <MenuItem key={item.propertyAddress} className={classes.addressListStyle} value={item.accountNumber}>{`${maskLoan(decrypt(item.accountNumber))} - ${item.propertyAddress1}`}</MenuItem>)}
              </Select>
            </FormControl>
          </div>
        }
      </AppBar>
      <SideBar
        login={login}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
        { logoutStatus &&
        <LogoutConfirmation
         open={logoutStatus}
         handleCancelIcon={handleCancelIcon}
         handleSubmitClose={handleSubmitClose}
         handleSubmit={handleSubmit}
       />
      }
      <LeavingSiteConfirmation 
        open={popupStatus}
        handleClose={handleClosePopup}
        handleOk={handleOk}
      />
     {login && <LogoutPopup/>}
    </div >
  );
}
