import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Grid, Button } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useStyles } from './styles';
import { getPrivacySecurityContent, getMyProfile } from '../../actions'
import { scrollTop } from '../../helpers';
import { insertActivityLog } from '../../actions';
import { getAppSource, getUserType } from '../../helpers';
import { useHistory, useLocation } from 'react-router-dom';
import InfoIcon from '@material-ui/icons/Info';
import callRefinance from '../../assets/callRefinance.png';
import call from '../../assets/call.png';
import message from '../../assets/message.png';
import email from '../../assets/email.png';
import { CallMeAlert } from './CallMe';
import { TextMeAlert } from './TextMe';
import { EmailMeAlert } from './EmailMe';
import { SnackBar } from '../../components';
import Cookies from 'js-cookie';
import './index.css';
import clsx from 'clsx';

export const RefinanceAdvertisement = (props) => {

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [data, setData] = useState('');
  const [refinanceDesc, setRefinanceDesc] = useState('');
  const [userProfile, setUserProfile] = useState();
  const [callMePopStatus, setCallMePopStatus] = useState(false);
  const [textMePopStatus, setTextMePopStatus] = useState(false);
  const [emailMePopStatus, setEmailMePopStatus] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");

  var accountInfo = useSelector(state => state.accountInfoData);
  const [buttonState, setButtonState] = useState(accountInfo?.refiEligibleStateFlag !== undefined ? accountInfo?.refiEligibleStateFlag : Cookies.get("isRefiStateEligible") == 'true' ? true : false);
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  let refiCompany = params.get('refiCompany')

  useEffect(() => {
    scrollTop()
    getData();
    getUserDetail();
  }, []);

  useEffect(() => {
    if (!props?.status) {
      history.push('/Dashboard');
    }
  }, [props?.status])

  const sendActivityData = async () => {
    const remark = `User clicked on Apply Online button on landing page of refinance tile (${refiCompany})`;
    const data = {
      "accountNumber":  Cookies.get("selectedAccount"),
      "activityCode": "UAL202",
      "activityStatus": "Success",
      "action": refiCompany,
      "controller": "Dashboard",
      "apiName": "GetAccountInfo",
      "activityRemark":  remark,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    await insertActivityLog(data);
  }

  const getData = async () => {
    const res = await dispatch(getPrivacySecurityContent('PC031'));
    if (res && res.lstPageContentDetails) {
      let searchdData = res.lstPageContentDetails.filter(item => item.contentKey === refiCompany)
      let refDisclaimerData = res.lstPageContentDetails.filter(item => item.contentKey === 'RefDisclaimer')
      setData(searchdData[0].contentValue);
      setRefinanceDesc(refDisclaimerData[0].contentValue)
      // if (!buttonState) {
      //   document.getElementById('Applynow').style.display = 'none';
      // }
    } else {
      setData('');
    }
    scrollTop();
  }

  const getUserDetail = async () => {
    const requestData = {
      "accountNumber": props.accountNumber,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    }
    const result = await getMyProfile(requestData)
    if (result) {
      const { lstResponseStatus } = result
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        setUserProfile(result)
      }
    }
  }

  const closeCallMePopup = (status) => {
    setCallMePopStatus(!callMePopStatus)
    if (status) {
      setOpenSnackBar(true)
      setSnackBarMsg("Your “Call Me” request was sent successfully. Our representative will reach out to you shortly.")
    } else {
      setOpenSnackBar(false)
      setSnackBarMsg('')
    }
  }

  const closeTextMePopup = (status) => {
    setTextMePopStatus(!textMePopStatus)
    if (status) {
      setOpenSnackBar(true)
      setSnackBarMsg("Your “Text Me” request was sent successfully. Our representative will reach out to you shortly.")
    } else {
      setOpenSnackBar(false)
      setSnackBarMsg('')
    }
  }

  const closeEmailMePopup = (status) => {
    setEmailMePopStatus(!emailMePopStatus)
    if (status) {
      setOpenSnackBar(true)
      setSnackBarMsg("Your “Email Me” request was sent successfully. Our representative will reach out to you shortly.")
    } else {
      setOpenSnackBar(false)
      setSnackBarMsg('')
    }
  }

  const handleCloseSnackBar = () => {
    setOpenSnackBar(false)
  }
 
  if (data) {
    return (
     <Box>
      <Box className={classes.rootBg}>
        <Box className={classes.root}>
          <Box>
            <a className={classes.applyNow} onClick={() => sendActivityData()} href="https://bsifinancialrefinanceonline.mymortgage-online.com/" target="_blank">Click here to Apply Online</a>
           </Box>
           <Box className={classes.cardContent}>
                <InfoIcon className={classes.cardIcon} /> 
                <p className={classes.info}> This will redirects you to our application portal.</p>
           </Box>
          <div dangerouslySetInnerHTML={{ __html: data }} />
          <Grid container>
            <Grid item md={3} xs={12} lg={3}>
                <Box>
                  <a className={classes.applyNow} onClick={() => sendActivityData()} href="https://bsifinancialrefinanceonline.mymortgage-online.com/" target="_blank">Click here to Apply Online</a>
                </Box>
                <Box className={classes.cardContent}>
                    <InfoIcon className={classes.cardIcon} /> 
                    <p className={classes.info}> This will redirects you to our application portal.</p>
                </Box>
              </Grid>
              <Grid item md={9} xs={12} lg={9}>
                <a href={"tel:" + "8773359048"}>
                  <img className={classes.logo} src={callRefinance} alt='logo' />
                </a>
              </Grid>
            </Grid>   
           <Box className={classes.title}>Need expert advice ? Talk to our loan officer.</Box>
           
            <Box display='flex'>
              {/* <a className={classes.btn} onClick={() => closeTextMePopup()}>
                <img src={message} className={classes.icon} />
                  TEXT ME</a> */}
              <a className={clsx(classes.btn, classes.btnMrg)} onClick={() => closeCallMePopup()}><img src={call} className={classes.icon} />
                  CALL ME</a>
              <a className={clsx(classes.btn, classes.btnMrg)} onClick={() => closeEmailMePopup()}> <img src={email} className={classes.icon} />
                  EMAIL ME</a>
              {/* <Button className={classes.textMe} onClick={() => closeTextMePopup()}  variant="outlined" type='submit'
                            
               >
              
                <img src={message} className={classes.icon} />
                TEXT ME
                </Button>
                
                <Button className={classes.textMeCallMe} onClick={() => closeCallMePopup()} disableRipple
                variant="outlined">
                 <img src={call} className={classes.icon} />
                CALL ME
                </Button>
                <Button className={classes.textMeCallMe} onClick={() => closeEmailMePopup()} disableRipple
                variant="outlined">
                  <img src={email} className={classes.icon} />
                EMAIL ME
              </Button> */}
            </Box>
             
          <div dangerouslySetInnerHTML={{ __html: refinanceDesc}} />
        </Box>
       </Box>
       {
          (textMePopStatus && userProfile) && 
           <TextMeAlert textMeAlertStatus={textMePopStatus} userProfile={userProfile} closeTextMePopup={closeTextMePopup}  />
        }
        {
          (callMePopStatus && userProfile) && 
           <CallMeAlert callMeAlertStatus={callMePopStatus} userProfile={userProfile} closeCallMePopup={closeCallMePopup}/>
        }
  
        {
          (emailMePopStatus && userProfile) &&
           <EmailMeAlert emailMeAlertStatus={emailMePopStatus} userProfile={userProfile} closeEmailMePopup={closeEmailMePopup}/>
        }
        {snackBarMsg.length !== 0 && <SnackBar
          severity={"success"}
          message={snackBarMsg} open={openSnackBar}
          handleClose={handleCloseSnackBar}
        />} 
      </Box>
    )
  } else {
    return (
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100vh'
        }}
      >
        <CircularProgress />
      </Box>
    )
  }
};
