import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Routes from './Routes';
import RoutesAdmin from './RoutesAdmin';
import { preLoginToken, getTheme,showToaster } from '../src/actions';
import Cookies from 'js-cookie';
import { useStyles } from './styles';
import { CircularProgress } from '@material-ui/core';
import { setBrowser, getPublicIp, getMyTheme, getAdminTheme } from './helpers';
import { MuiThemeProvider, makeStyles } from '@material-ui/core/styles';
import { NoBase } from './components';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

function App() {

  const dispatch = useDispatch();
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [hasNoBase, setHasNoBase] = useState(false);
  const mytheme = useSelector(state => state.theme);

  //Generic toaster implementation
  const {status:toaster,message,successToast} = useSelector(state => state.toaster);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    // loadScript();
  }, []);

  // useEffect(() => {
  //   const loadScript = () => {
  //     return new Promise((resolve, reject) => {
  //       const script = document.createElement('script');
  //       script.src = 'https://cdn.jsdelivr.net/npm/flowise-embed/dist/web.js';
  //       script.async = true;
  //       script.type = 'module'
  //       script.onload = resolve;
  //       script.onerror = reject;
  //       document.body.appendChild(script);
  //     });
  //   };
 
  //   loadScript()
  //     .then(() => {
  //       console.log('Flowise script loaded successfully');
  //       if (window.customElements && window.customElements.get('flowise-chatbot')) {
  //         console.log('Flowise chatbot element is available');
  //         const flowiseChatbot = window.customElements.get('flowise-chatbot');
  //         console.log(flowiseChatbot)
  //         if (flowiseChatbot) {
  //           flowiseChatbot.setAttribute('chatflowid', '84432cf6-9fbc-44f4-b697-f1816b81e611');
  //           flowiseChatbot.setAttribute('apiHost', 'http://172.20.46.220:3000');
  //           console.log('Chatbot attributes set:');
  //         } else {
  //           console.error('Flowise chatbot element not found');
  //         }
  //       } else {
  //         console.error('Flowise chatbot element not found');
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error loading Flowise script:', error);
  //     }); 
  // }, []);

  const init = async () => {
    const base = window.location.pathname.split('/')[1];
    if (!base) {
      setHasNoBase(true);
      return;
    }
    if (Cookies.get('privateLabelUrl') && Cookies.get('privateLabelUrl').toUpperCase() !== "ADMIN") {
      if (Cookies.get('privateLabelUrl') && base !== Cookies.get('privateLabelUrl')) {
        Cookies.remove('privateToken');
        Cookies.remove('selectedAccount');
        Cookies.remove('totalAccounts');
      }
    }
    const publicIp = await getPublicIp()
    Cookies.set("privateLabelUrl", base);
    Cookies.set("publicIp", publicIp)
    await setBrowser();
    if (base.toUpperCase() === "ADMIN") {
      // add admin settings
      setIsAdmin(true);
      setLoading(false);
    } else {
      const token = Cookies.get('publicToken');
      if (token) {
        await dispatch(getTheme());
        setLoading(false)
      } else {
        await preLoginToken();
        await dispatch(getTheme());
        setLoading(false)
      }
    }
  };

  //app

  if (hasNoBase) {
    return (
      <Suspense fallback={<div className='loader'><CircularProgress /></div>}>
        <NoBase />
      </Suspense>
    )
  }

  //Generic toaster implementation
  const handleSnakClose = () => {
     dispatch(showToaster({status:false,message:'',successToast:false}));
  };
  
  if (!loading && isAdmin) {
    return (
      <Suspense fallback={<div className='loader'><CircularProgress /></div>}>
        <MuiThemeProvider theme={getAdminTheme()}>
          <RoutesAdmin />
        </MuiThemeProvider>
      </Suspense>
    ) 
  }
  
  if (!loading && mytheme) {
    return (
      <Suspense fallback={<div className='loader'><CircularProgress /></div>}>
        <MuiThemeProvider theme={getMyTheme(mytheme)}>
          <Routes />
          {/* { window.customElements.get('flowise-chatbot') && <flowise-chatbot chatflowid='84432cf6-9fbc-44f4-b697-f1816b81e611' apiHost='http://172.20.46.220:3000'></flowise-chatbot>} */}
          </MuiThemeProvider>
            {/* //Generic toaster implementation */}
             <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={toaster}
              autoHideDuration={10000}
              onClose={handleSnakClose}
              className={successToast?classes.snackBarSuccess:(toaster?classes.snackBarError:'')}
              // severity="success"
              message={message}
              // action={
              //     <IconButton
              //       aria-label="close"
              //       color="inherit"
              //       className={classes.close}
              //       onClick={handleSnakClose}
              //     >
              //       <CloseIcon />
              //     </IconButton>
              // }
            /> 
      </Suspense>
    )
  }

  return (
    <Suspense fallback={<div className='loader'><CircularProgress /></div>}>
      <div className='loader'>
        <CircularProgress />
      </div>
    </Suspense>
  );
}

export default App;
