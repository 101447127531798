import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useStyles } from './styles';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker, DateTimePicker
} from '@material-ui/pickers';

import moment from 'moment';

export const DateRangecustom = (props) => {

  const classes = useStyles();
  const [inputDate, setInputDate] = React.useState('');
  const [inputendDate, setInputendDate] = React.useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isOpensecond, setisOpensecond] = useState(false);
  const [values, setValues] = React.useState({
    sDate: props.start,
    endD: props.end
  });

  useEffect(() => {
    if(values.sDate){
      //console.log(values.sDate)
    }
    if(values.endD){
      //console.log(values.endD)
    }
  }, [values.sDate,values.endD])
 
  const handleDateChange = (date) => {
      let startDate = moment(date).format('YYYY-MM-DD');
      setInputDate(date);
      setInputendDate(values.endD);
      setValues({...values,sDate:startDate})
      setIsOpen(false);
      props.dateUpdate(startDate,values.endD)
  }

  const handleendDateChange = (date) => {
          let endDate = moment(date).format('YYYY-MM-DD');
          setInputendDate(date);
          setValues({...values,endD:endDate})
          setisOpensecond(false);
          props.dateUpdate(values.sDate,endDate)
   }

  const setOpenState = () => {
    setIsOpen(true);
  }
  const setOpensecondState = () => {
    setisOpensecond(true);
  }


  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Box className={`${classes.inputContainer} ${classes.marBtmFixed}`}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              color='primary'
              variant="inline"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              fullWidth
              id="date-picker-inline"
              label="Start Date"
              placeholder="Start Date"
              minDate={new Date()}
              value={inputDate ? inputDate : props.start}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              inputProps={{
                className: classes.inputStyle,
                
            }}

              KeyboardButtonProps={{
                onFocus: e => {
                  setOpenState();
                }
              }}
              PopoverProps={{
                disableRestoreFocus: true,
                onClose: () => {
                  setIsOpen(false);
                }
              }}
              InputProps={{
                onFocus: () => {
                  setOpenState();
                }
              }}
              InputLabelProps={{
                shrink: true,
              }}
              open={isOpen}

            />
          </MuiPickersUtilsProvider>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box className={`${classes.inputContainer} ${classes.marBtmFixed}`}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              color='primary'
              minDate={new Date()}
              variant="inline"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              fullWidth
              id="date-picker-inline-End"
              label="End Date"
              placeholder="End Date"
              value={inputendDate ? inputendDate : props.end}
              onChange={handleendDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              inputProps={{
                className: classes.inputStyle,
                
            }}

              KeyboardButtonProps={{
                onFocus: e => {
                  setOpensecondState();
                }
              }}
              PopoverProps={{
                disableRestoreFocus: true,
                onClose: () => {
                  setisOpensecond(false);
                }
              }}
              InputProps={{
                onFocus: () => {
                  setOpensecondState();
                }
              }}
              InputLabelProps={{
                shrink: true,
              }}
              open={isOpensecond}

            />
          </MuiPickersUtilsProvider>
        </Box>
      </Grid>

    </Grid>
  )
}