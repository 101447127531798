import React, { useEffect, useState } from 'react';
import { useStyles, CutomTextField } from './styles';
import { Box, Button, CircularProgress } from '@material-ui/core';
import {
  CircularProgressbar
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Cookies from 'js-cookie';
import { encrypt, decrypt } from '../../helpers/encrypt';
import { getPublicIp, maskEmail, maskMobile, getUserType, getAppSource } from '../../helpers';
import { resendCode, resendEmail } from '../../actions';
import { Link, useHistory } from 'react-router-dom';
import phone_android from '../../assets/VerifyAccount/phone_android.png'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export const ResetPasswordOption = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { email, mobileNumber, userName, firstName } = props.data;

  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [radioValue, setRadioValue] = useState(true);
  const [radioValue2, setRadioValue2] = useState(false);
  const [radioValue3, setRadioValue3] = useState(false);
  
  const handleRadioChange2 = (event) => {
    setRadioValue2(event.target.value);
    setRadioValue(false);
    setRadioValue3(false);
    setSuccess(false);
  };

  const handleRadioChange3 = (event) => {
    setRadioValue3(event.target.value);
    setRadioValue(false);
    setRadioValue2(false);
  };

  const handleResendEmail = async () => {
    setLoading(true);
    setErrorMsg("");
    const data = {
      "userName": userName,
      "firstName": firstName,
      "email": email,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": await getPublicIp(),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
    }
    const res = await resendEmail(data);
    if (res) {
      setLoading(false);
      setSuccess(true);
    } else {
      setErrorMsg("Something went wrong");
      setLoading(false);
    }
  }

  const handleResendCode = async () => {
    setLoading(true);
    setErrorMsg("");
    const data = {
      "userName": userName,
      "mediumType": "SMS",
      "mobileNumber": mobileNumber,
      "email": "",
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": await getPublicIp(),
      "browser": Cookies.get("browser"),
      "otpType": "RecoverPassword",
      "appSource": getAppSource(),
      "userType": getUserType(),
      "loanNo": "",
    }
    const res = await resendCode(data);
    if (res) {
      setLoading(false);
      props.handleStep('3', null)
    } else {
      setErrorMsg("Something went wrong");
      setLoading(false);
      props.handleStep('3', null)
    }
  }

  const handleSubmit = () => {
    if (radioValue) {
      history.replace({ pathname: "/RecoverPasswordBySsn" });
    } else if (radioValue2) {
      handleResendCode()
    } else if (radioValue3) {
      handleResendEmail();
    }
  }

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
    setRadioValue2(false);
    setRadioValue3(false);
    setSuccess(false);
  };

  return (
    <Box container="true" maxWidth='xl' className={classes.root}>
      <Box className={classes.wrapper}>
        <p className={classes.title}>Reset Password</p>
        <p className={classes.errorMsg}>{errorMsg.length !== 0 ? errorMsg : ''}</p>
         
        <div className={classes.radioBtn}>
            <RadioGroup row aria-label="account_type" name="account_type" value={radioValue} style={{ justifyContent: "flex-start" }}
              onChange={handleRadioChange}>
              <FormControlLabel value="true" checked={radioValue}
                control={<Radio />} 
                label={<span style={{ fontSize: '18px' }}>By Account Details</span>}
              />
            </RadioGroup>
         </div>

        <p className={classes.hintText}>
           By providing the last 4 digits of your SSN/EIN and Property Zip Code you will be able to reset your password.
           {/* <button disabled={loading} className={classes.proceedBtnText} onClick={() => handleVerifyBySsn()}>PROCEED</button> */}
        </p>
        {mobileNumber ?
        <Box>
        <Box className={classes.orContainer}>
            <Box className={classes.orWraper}><p className={classes.orText}>or</p></Box>
        </Box>
        
        <div className={classes.radioBtn}>
            <RadioGroup row aria-label="account_type" name="account_type" value={radioValue2} style={{ justifyContent: "flex-start" }}
              onChange={handleRadioChange2}>
              <FormControlLabel value="option2"
                control={<Radio />} 
                label={<span style={{ fontSize: '18px' }}>By Mobile</span>}
              />
            </RadioGroup>
        </div>
        <Box>
          {/* <p className={classes.verifyBoldText}><b>Option 3</b> – Verify by Mobile</p> */}
          {/* <Box className={classes.iconTextContainer}> */}
            {/* <img className={classes.iconStyle} src={phone_android} alt='icon' /> */}
            <p className={classes.hintText}>We will send a code to your mobile number ending in {mobileNumber ? maskMobile(decrypt(mobileNumber)) : null} 
            {/* <button disabled={loading} className={classes.resendBtnText} onClick={() => handleResendCode()}>Send Code</button> */}
            </p>
          {/* </Box> */}
        </Box>
        </Box>
         : 
         <Box>
        <Box className={classes.orContainer}>
            <Box className={classes.orWraper}><p className={classes.orText}>or</p></Box>
        </Box>
        
        <div className={classes.radioBtn}>
            <RadioGroup row aria-label="account_type" name="account_type" value={radioValue2} style={{ justifyContent: "flex-start" }} 
              onChange={handleRadioChange2}>
              <FormControlLabel value="option2"
                disabled
                control={<Radio />} 
                label={<span style={{ fontSize: '18px' }}>By Mobile</span>}
              />
            </RadioGroup>
        </div>
        <Box>
            <p className={classes.hintText}>This option is currently not available. We do not have your consent to text your mobile number. 
            </p>
        </Box>
        </Box> 
         }
       
        <Box className={classes.orContainer}>
            <Box className={classes.orWraper}><p className={classes.orText}>or</p></Box>
         </Box>

        <div className={classes.radioBtn}>
            <RadioGroup row aria-label="account_type" name="account_type" value={radioValue3} style={{ justifyContent: "flex-start" }}
              onChange={handleRadioChange3}>
              <FormControlLabel value="option3" 
                control={<Radio />}
                label={<span style={{ fontSize: '18px' }}>By Email</span>}
              />
            </RadioGroup>
        </div>
        {/* <p className={classes.verifyBoldText}><b>Option 2</b> – Verify by Email</p> */}
        
        <p className={classes.hintText}> We will send a link to your email address {email ? maskEmail(decrypt(email)) : null}</p>
        {/* <p className={classes.hintText}>Did not receive email?<button disabled={loading} className={classes.resendBtnText} onClick={() => handleResendEmail()}>Resend Email</button></p> */}
   
        {/* <Box className={classes.btnContainer}>
            <Button type='submit' onClick={() => onSubmit()} className={classes.submitBtn} variant="contained" color="primary" fullWidth>Submit</Button>
        </Box> */}
        <Box className={classes.loaderContainer}>
          {loading ? <CircularProgress /> : success ? <p className={classes.successMsg}>Email sent successfully.</p> : null}
        </Box> 

         {/* <Box textAlign="center" pt={2}>
            <Button
              onClick={() => handleSubmit()}
              className={classes.saveBtn}
              variant="contained"
              color="primary">
              {radioValue3 ? 'SUBMIT' : 'NEXT'}</Button>
        </Box> */}
        <Box >
              <Button  onClick={() => handleSubmit()} className={classes.submitBtn} size="large" fullWidth variant="contained" color="primary">
              {radioValue3 ? 'SUBMIT' : 'NEXT'}
              </Button>
              <Button  onClick={() => props.backToForgotPassword()} fullWidth className={classes.sendBtn} variant='outlined' color="primary" >Back</Button>
        </Box>
      
      </Box>
    </Box >
  )
}
