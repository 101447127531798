import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '120em',
    margin: '0 auto',
    padding: '2.5em 1.6em 9em',
  },

  loadingRoot: {
    width: '100%',
    height: '100vh',
    padding: '6.4em 6%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ccc',
  },

  noDataRoot: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  wrapper: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },

  title: {
    color: "#1A4664",
    fontFamily: "Montserrat",
    fontSize: '22px',
    fontWeight: "700",
    marginBottom: 26,
    marginTop: 24
  },

  infoBox: {
    width: '100%',
    // display: 'flex',
    alignItems: 'center',
    backgroundColor: '#1A4664',
    borderRadius: 4,
    padding: '0.5em',
    marginBottom: '2em',
    borderRadius: '4px'
  },

  informationText: {
    color: "#000000",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "400",
  },

  infoText: {
    color: "#fff",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "500",
  },

  infoIcon: {
    color: "#fff",
    width: 25,
    height: 25,
    marginRight: 10,
  },

  leftPannel: {
    width: '66%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  rightPannel: {
    width: '32%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: 28,
    },
  },

  loanDetail: {
    color: '#0C0B0B',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    letterSpacing: '0.47px',
    lineHeight: '18px',
    marginBottom: '18px'
  },
  allLoanDetails: {
    margin: '12px 0',
    borderBottom: '1px solid #dcdfe0',
  },
  addedRemovedLoan: {
    color: '#0C0B0B',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: '400',
    letterSpacing: '0.47px',
    lineHeight: '18px',
  },

  textBold: {
    fontWeight: '600'
  },

  loanErrorContect: {
    margin: '18px 0'
  },
  loanConfirm: {
    margin: '20px auto 5px auto',
    display: 'block',
    width: '180px',
    textAlign: 'center',
  },
  popupBody: {
    padding: '20px 30px 30px 30px',
    fontSize: '14px'
  },
  closeIcon: {
    fill: '#535D6D', fontSize: '30px', textAlign: 'right', position: 'absolute', right: '3px', top: '5px', cursor: 'pointer'
  },

  transferBox: {
    width: '100%',
    alignItems: 'center',
    backgroundColor: '#FFF2E1',
    borderRadius: 4,
    padding: '1em',
    marginBottom: '2em',
    borderRadius: '8px',
    border: '4px solid #EDB342'
  },

  warningIcon: {
    color: "#F7B15C",
    width: 25,
    height: 25,
    marginRight: 10
  },

  transferInfoIcon: {
    color: "rgba(0,0,0,0.87)",
    width: 25,
    height: 25,
    marginRight: 10,
    cursor: "pointer"
  },
  quoteBox: {
    width: '100%',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    borderRadius: 10,
    marginBottom: '2em',
    border: '1px solid #106691'
  },
  quote: {
    color: "#2B2D30",
    fontFamily: "Montserrat",
    fontSize: '20px',
    fontWeight: "700",
    paddingLeft: 34,
    paddingTop: 18,
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
      fontWeight: "600",
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      fontWeight: "700",
      textAlign: "center",
      paddingLeft: 0,
      paddingTop: 10,
      lineHeight: "24px"
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
      fontWeight: "700",
      textAlign: "center",
      paddingLeft: 0,
      paddingTop: 10
    }
  },
  customize: {
    color: "#106691",
    fontFamily: "Montserrat",
    fontSize: '17px',
    fontWeight: "700",
    paddingTop: 20,
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
      fontWeight: "600"
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
      fontWeight: "700",
      textAlign: "center",
      paddingTop: 2
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
      fontWeight: "700",
      textAlign: "center",
      paddingLeft: 0,
      paddingTop: 4
    }
  },
  quickQuote: {
    width: '242px',
    alignItems: 'center',
    backgroundColor: '#045986',
    color: '#FFFFFF',
    borderRadius: 48,
    border: '1px solid',
    fontSize: 16,
    fontFamily: "Montserrat",
    fontWeight: "600",
    '&:hover': {
      backgroundColor: '#045986',
      color: '#FFFFFF',
    },
    [theme.breakpoints.down('md')]: {
      width: '190px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '190px',
      fontSize: '15px',
      fontWeight: "700",
      textAlign: "center"
    },
    [theme.breakpoints.down('xs')]: {
      width: '156px',
      fontSize: '12px',
      fontWeight: "700",
      textAlign: "center",
      height: '32px'
    }
  },
  quickQuoteBtn: {
    paddingLeft: 20,
    // paddingTop: 12,
    [theme.breakpoints.down('sm')]: {
      textAlign: "center",
      paddingLeft: 0,
      paddingBottom: 10,
      paddingTop: 4
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: "center",
      paddingLeft: 0,
      paddingBottom: 10,
      paddingTop: 8
    }
  },
  window: {
    position: 'relative',
    top: '-13px',
    right: '-11px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '60px',
      right: "0px",
    },
  },
  banner2paragraph: {
    color: "#1A4664",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "700",
    // paddingTop: 15,
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
      fontWeight: "600"
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
      fontWeight: "700",
      textAlign: "center",
      paddingTop: 8
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
      fontWeight: "700",
      textAlign: "center",
      paddingLeft: 0,
      paddingTop: 4
    }
  },
  banner2heading: {
    color: "#1A4664",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "700",
    paddingLeft: 34,
    paddingTop: 18,
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
      fontWeight: "600",
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      fontWeight: "700",
      textAlign: "center",
      paddingLeft: 0,
      paddingTop: 10,
      lineHeight: "24px"
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
      fontWeight: "700",
      textAlign: "center",
      paddingLeft: 0,
      paddingTop: 10
    }
  },
  banner3heading: {
    color: "#1A4664",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "700",
    paddingLeft: '34px !important',
    // paddingTop: '10px !important',
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
      fontWeight: "600",
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      fontWeight: "700",
      textAlign: "center",
      paddingLeft: 0,
      paddingTop: 10,
      lineHeight: "24px"
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
      fontWeight: "700",
      textAlign: "center",
      paddingLeft: 0,
      paddingTop: 10
    }
  },
  inquireNow: {
    width: '214px',
    alignItems: 'center',
    backgroundColor: '#045986',
    color: '#FFFFFF',
    borderRadius: 48,
    border: '1px solid',
    fontSize: 16,
    fontFamily: "Montserrat",
    fontWeight: "600",
    '&:hover': {
      backgroundColor: '#045986',
      color: '#FFFFFF',
    },
    [theme.breakpoints.down('md')]: {
      width: '190px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '190px',
      fontSize: '14px',
      fontWeight: "700",
      textAlign: "center"
    },
    [theme.breakpoints.down('xs')]: {
      width: '156px',
      fontSize: '10px',
      fontWeight: "700",
      textAlign: "center",
      height: '32px'
    }
  },
  openIcon: {
    marginLeft: 10,
    [theme.breakpoints.down('md')]: {
      right: "0px",
    },
  },
  homeImage: {
    float: 'right',
    paddingTop: 76,
    paddingRight: 6,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      paddingRight: 0
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0,
      paddingRight: 0
    }
  },
  windowIcon: {
    float: 'right',
    padding: 2
  },

  cardContainer: {
    width: '100%',
    justifyContent: 'center',
  },

  card: {
    width: '100%',
    height: '320px',
    textAlign: 'center',
    borderRadius: 10,
    backgroundColor: "#FFF",
    boxShadow: " 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: '0 0 25px',
    },
  },

  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '17px 53px',
    borderBottom: '1px solid #ddd',
    [theme.breakpoints.down('md')]: {
      padding: '16px',
    },
  },

  cardImg: {
    height: 90,
    width: 90,
    marginRight: 12
  },

  cardTitle: {
    color: "#121D22",
    letterSpacing: "0.18px",
    fontFamily: "Montserrat",
    fontSize: 20,
    fontWeight: "400",
    paddingTop: 10,
    [theme.breakpoints.down('md')]: {
      fontSize: 18
    },
  },

  cardBody: {
    width: '100%',
    minHeight: 212,
    padding: '24px 28px 10px',
    [theme.breakpoints.down('md')]: {
      padding: 16
    }
  },

  cardBody2: {
    width: '100%',
    minHeight: 212,
    padding: '24px 28px 0px',
    [theme.breakpoints.down('md')]: {
      padding: 16
    }
  },

  bodyText: {
    color: "#4D4D4D",
    fontFamily: "Montserrat",
    fontSize: '16px',
    fontWeight: "400",
    lineHeight: 1.2,
    letterSpacing: "0.5px",
    padding: "5px 0px"
  },

  bodyTitle: {
    margin: 0,
    marginBottom: 12,
    color: theme.palette.primary.main,
    fontFamily: "Montserrat",
    fontSize: '1.8em',
    fontWeight: 'bold'
  },

  bodyTextMarginFix: {
    marginTop: '13px'
  },

  cardFooter: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '0 53px 30px',
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      padding: '0 45px 30px',
    },
  },
  
  cardBtn: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '0 53px 30px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 45px 30px',
    },
  },

  btn: {
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    width: '230px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  securityBox: {
    width: '100%',
    border: "2px solid rgba(0,0,0,0.12)",
    padding: '25px 53px',
    marginTop: 25,
    borderRadius: 12,
    display: 'flex',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      padding: '25px 16px',
    },
  },
  securityBoxHeader: {
    marginRight: 25,
  },

  securityContent: {
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
  },

  securityIcon: {
    width: 73,
    height: 89,
    marginRight: 10,
  },

  securityTitle: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2.4em',
    fontWeight: '400',
    marginBottom: '25px',
    paddingBottom: 25,
    borderBottom: "solid 1px rgba(0,0,0,0.12)",
  },

  listContainer: {
    padding: 0
  },

  listStyle: {
    displat: 'block',
    color: "#193559",
    fontFamily: "Montserrat",
    fontSize: '1.4em',
    marginBottom: '20px'
  },

  text: {
    color: '#535D6D',
    fontFamily: 'Montserrat',
    fontSize: '1.6em',
    marginBottom: '13px',
    '& p': {
      color: "rgba(0,0,0,0.74)",
      fontFamily: "Montserrat",
      fontSize: '16px',
      letterSpacing: '0.5px',
      maxWidth: '760px',
      wordBreak: "break-word",
    }
  },

  errorMsg: {
    color: 'red',
    fontFamily: 'Montserrat',
    fontSize: '1.6em',
    marginBottom: '13px',
  },

  askedQuestion: {
    marginTop: 25,
    width: '100%',
  },

  askedQuestionTitle: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2.4em',
    fontWeight: '400',
    marginBottom: '25px'
  },

  askedQuestionContent: {
    width: '100%',
    border: '2px solid',
    borderRadius: 12,
    borderColor: theme.palette.primary.main,
    padding: 4,
  },

  questionBox: {
    width: '100%',
    padding: '15px 40px',
    backgroundColor: '#f5f6f7',
    marginBottom: 10,
  },

  questionLink: {
    color: "#25ABC7",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    textDecoration: 'none'
  },

  bodyTitleActive: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: '2.1em',
    marginTop: '12px',
    marginBottom: '14px',
    fontWeight: "600",
  },

  bodySubTitleActive: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: '1.8em',
    marginBottom: '2px',
    fontWeight: "500",
  },

  flexBox: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '12px',
  },

  btnActive: {
    fontFamily: "Montserrat",
    fontSize: '1.4em',
  },

  boldText: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: '2.1em',
    marginBottom: '12px',
    fontWeight: "bold",
  },

  manageAutoPayCard: {
    width: '100%',
    minHeight: 212,
    padding: '2px 53px 2px',
    [theme.breakpoints.down('md')]: {
      padding: '16px',
    },
  },

   // schedule payment box
   schedulePaymentBox: {
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 14px 7px, rgba(0, 0, 0, 0.2) 0px 1px 3px 0px',
    width: '100%',
    padding: '3em 2.2em',
    borderRadius: '12px',
    margin: '37px 0',
    backgroundColor: '#fff',
  },
  headingText: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontWeight: '400',
    letterSpacing: '0.63px',
    lineHeight: '29px',
    marginBottom: 40,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px'
    }
  },

  disabledLink: {
    color: "#7B8390",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "bold",
    textDecoration: 'none',
  },

  activeLink: {
    color: theme.palette.primary.main,
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "600",
    textDecoration: 'none',
  },

  errText: {
    color: "red",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "600",
    textAlign: 'center',
    padding: '2em',
  },

  printIconText: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    cursor: 'pointer',
  },

  printForm: {
    fontSize: '14px',
    color: '#535D6D',
    fontFamily: "Montserrat",
    fontWeight: '600',
    textDecoration: 'underline',
  },

  upcomingPayflexBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },

  marginFix: {
    marginBottom: 0
  },

  achSuspension: {
    color: 'red',
    marginBottom: 20
  },

  clickLink: {
    color: '#007FFF',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontStyle: 'normal',
    cursor:'pointer',
    fontWeight: '700',
    lineHeight: '16px',
    textDecorationLine: 'underline'
  },
  clickLinkDisable: {
    color: '#555D6C',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '16px',
    textDecorationLine: 'underline'
  },
  completeUrSurvey: {
    color: "#121D22",
    fontFamily: "Montserrat",
    fontSize: '14px',
    fontWeight: "600",
    paddingLeft: 50
  },
  paidInFullIcon: {
    color: "#EDB36A",
    width: 25,
    height: 25,
    marginRight: 10,
  },
  servicerText: {
    color: "#000000",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "700",
    textTransform: 'uppercase'
  },
  purgedDays: {
    color: "#000000",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "700"
  }
}));
