import React, { useEffect, useState } from 'react';
import {
  Box, CssBaseline, Grid, Typography, Button, CircularProgress, IconButton, Tooltip,
  InputAdornment, FormControl, TextField, Select,
  InputLabel, TextareaAutosize, MenuItem, FormControlLabel, OutlinedInput, ListItemText, Checkbox, ClickAwayListener
} from '@material-ui/core';
import { useStyles } from './styles';
import { renderDefaultMsg, getUserType, getAppSource, isAdminCheck, maskLoan } from '../../helpers';
import SearchIcon from "@material-ui/icons/Search";
import { Requests } from './Requests';
import CustomBreadcrum from '../Breadcrum';
import { useSelector } from 'react-redux';
import { getDocumentTypeData, submitDocumentRequest, showToaster, getMyProfile, getDocumentList, getClientName, insertActivityLog, feedbackDisplay } from '../../actions';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FilterDrawer from './FilterDrawer'
import { AccessDenied } from './AccessDenied';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import Filter from '../../assets/filter.svg';
import Warning from '../../assets/warning-white.svg';

import BackButton from '../../assets/backButton.svg';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { DeleteConfirmation } from '../ManageBankAccount/DeleteConfirmationOnAccount/DeleteConfirmation';
import { PayOffVomPifPopup } from './PayOffVomPifPopup';
import { DocumentReqSubmitPopup } from './DocumentRequestSubmitPopup';
import { useLocation } from 'react-router-dom';
import { decrypt } from '../../helpers/encrypt';
import { SnackBar } from '../../components';
import CancelIcon from '@material-ui/icons/Cancel';
import databaseIcon from '../../assets/RequestDocument/Database.png';
import circleIcon from '../../assets/RequestDocument/circle.png';
import trashIcon from '../../assets/RequestDocument/trash.png';

import ClearIcon from '@material-ui/icons/Clear';
import Cookies from 'js-cookie';
import moment from 'moment';
import './styles.css';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const LightTooltip = withStyles((theme) => ({
  arrow: {
    color: '#235A86'
  },
  tooltip: {
    backgroundColor: '#235A86',
    color: '#fffff',
    fontFamily: "Montserrat",
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    padding: "16px",
    fontSize: 14,
    maxWidth: 350,
    fontWeight: 500,
    borderRadius: 8
  },
}))(Tooltip);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const DocumentRequest = (props) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const myRef = React.useRef({});
  const location = useLocation();
  const backBtnStatus = location.search.includes('back')
  const accounts = useSelector(state => state.accounts);
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.down('sm'))

  const [accountsToFilter, setAccounts] = useState([]);
  const [allSelected, setAllSelected] = useState(true);
  const [loader, setLoader] = useState(false);
  const [openFilter, setOpenFilter] = useState();
  const [filterValuesApply, setFilterValuesApply] = React.useState({ status: {'In Progress':false,'Completed':false,'Clarification Needed':false,'Submitted':false}, customEnd: null, customStart: null, requestedDates: { startDate: '', endDate: '', requestedPeriod: '' } });
  const [noResultFoundOnSearch, setResultFoundOnSearch] = useState(false);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [value, setValue] = useState('Y');
  const [openForm, setFormStatus] = useState(false);
  const [requestLists, setRequestLists] = useState([]);
  const [requestListsFromApi, setRequestListsFromApi] = useState([]);
  const [documentType, setDocumentType] = useState();
  const [documentRequestId, setDocumentId] = useState();
  const [documentDesc, setDocumentDesc] = useState("");
  const [emailConsentStatus, setEmailConsentStatus] = useState();
  const [errMessage, setErrorMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [filterApplied, setFilterApplied] = useState();
  const [popupText, setPopupText] = useState({})
  const [selectedItem, setSelectedItem] = useState({});
  const [submitUrl, setSubmitUrl] = useState(false);
  const [otherDocumentName, setOtherDocumentName] = useState();
  const [noResultFound, setNoResultFound] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [defaultSearch, setDefaultSearch] = useState('Name');
  const [requestDocLists, setRequestDocLists] = useState();
  const [selectedLoan, setSelectedLoan] = useState({ accountNumber: "" });
  const [permission, setPermission] = useState(true);
  const [accessPopup, setAccessPopup] = useState(false);
  const [btnDisabled, setDocRequestStatus] = useState(true);
  const [payoffDate, setPayOffDate] = useState();
  const [showRestrictPopup, setRestrictPopup] = useState(false);
  const [addDescriptionStatus, setDescriptionStatus] = useState(false);
  const [selectedYears, setYears] = useState([]);
  const [years, setYearsData] = useState([]);
  const [showSubmitPopup, setOpenSnackBar] = useState(false);
  const [submitMsg, setSnackBarMsg] = useState({requestNo: "", sla: ""});
  const [displayFeedback, setFeedbackDisplayStatus] = useState(false);
  const [feedBackHeaderText, setFeedbackHeaderTest] = useState();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [openToolTip, setOpenToolTip] = useState(false);
  
  const handleTooltipClose = () => {
    setOpenToolTip(false);
  };

  const handleTooltipOpen = () => {
    setOpenToolTip(true);
  };

  const handleSearch = (event) => {
    setSearchInput(event.target.value)
  }

  const handleSearchDropdown = (event) => {
    setDefaultSearch(event.target.value);
    // if (searchInput !== '') {
    setSearchInput('');
    applyMultiLoanFilter('all')
   // setRequestLists(requestListsFromApi)
    setResultFoundOnSearch(false)
    // }
  };

  const getAccessPermission = async (loan) => {
    const data = {
      "accountNumber": loan?.accountNumber,
      "userName": "",
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType()
    }

    const result = await dispatch(getClientName(data));
    if (result) {
      const { lstResponseStatus, bankDetail } = result
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        setPayOffDate(bankDetail.payoffDate)
        if (accounts?.length > 1) {
          setPermission(bankDetail.isDocumentRequestAllowed)
          setAccessPopup(!bankDetail.isDocumentRequestAllowed)
        } else {
          setDocRequestStatus(bankDetail.isDocumentRequestAllowed)
        }
      }
    }
  }

  useEffect(() => {
    Cookies.remove('redirectUrl')
    Cookies.remove('requestId')
    if (props.accountNumber) {
      getDocumentType(props)
      getDocumentListFunc()
    }
  }, [props.accountNumber])
  
  const fetchFeedbackDisplay = async () => {
    const result = await feedbackDisplay(props.accountNumber);
    if (result) {
      if (result?.responseData?.lstResponseStatus[0].statusCode == "0") {
        setFeedbackHeaderTest(result?.responseData?.responseData?.headerText)
        setFeedbackDisplayStatus(result?.responseData?.responseData?.isDisplay)
        // props.showFeedback(result?.responseData?.responseData?.headerText)
        if (Cookies.get("displaySurvey") == "true") {
          props.showFeedback(result?.responseData?.responseData?.headerText)
        }
      }
    }
  }
  
  const showFeedBackWidget = () => {
    if (displayFeedback) {
      Cookies.set("displaySurvey", true)
      props.showFeedback(feedBackHeaderText)
    }
  }

  useEffect(() => {
    fetchFeedbackDisplay()
  }, [props.renderComponent])

  useEffect(() => {
    if (((location.search.split('?requestId=')[1] !== undefined) && (requestListsFromApi?.length > 0))) {
      const input = location.search.split('?requestId=')[1]
      const queryParams = new URLSearchParams(location.search)
      setSearchInput(input)
      setDefaultSearch("Id")
      searchClick(queryParams.get('requestId'), "Id")
      if (queryParams.has('requestId')) {
        queryParams.delete('requestId')
        history.replace({
          search: queryParams.toString(),
        })
      }
    }
  }, [((location.search.split('?requestId=')[1] !== undefined) && (requestListsFromApi?.length > 0))])

  const searchClick = (searchInput, defaultSearch) => {
    if (defaultSearch == 'Name') {
      const updatedDocLists = requestListsFromApi.filter((item) => {
        return item.documentType.toLowerCase().includes(searchInput?.toLowerCase())
      })
      setRequestLists(updatedDocLists);
      
      if (updatedDocLists.length == 0) {
        setResultFoundOnSearch(true)
      }
    } else {
      const updatedDocLists = requestListsFromApi.filter((item) => {
        return item.requestNumber.toLowerCase().includes(searchInput?.toLowerCase())
      })
      
      setRequestLists(updatedDocLists);
           if (updatedDocLists == 0) {
        setResultFoundOnSearch(true)
      }
    }
  }

  useEffect(() => {
    if (props?.accountNumber && (accounts?.length == 1)) {
      getAccessPermission(props)
    }
    setAccounts(accounts)
  }, [props.accountNumber, accounts])

  const getUserDetail = async (loan) => {
    const requestData = {
      "accountNumber": loan.accountNumber,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    }

    const result = await getMyProfile(requestData)
    if (result) {
      const { lstResponseStatus } = result
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        setEmailConsentStatus(result?.isEmailConsent)
      }
    }
  }

  const getDocumentType = async (props) => {
    const data = {
      "accountNumber": props?.accountNumber,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    };

    const res = await getDocumentTypeData(data);
    if (res?.responseData?.lstResponseStatus[0]?.statusCode === '0' || res?.responseData?.lstResponseStatus[0]?.statusCode === 0) {
      setDocumentTypes([])
      setSelectedItem({})
      setDocumentId()
      setDocumentDesc("")
      setDocumentTypes(res?.responseData?.responseData)
    } else {
      setErrorMessage(renderDefaultMsg('400'))
    }
  }

  const getDocumentListFunc = async () => {
    setLoader(true)
    const data = {
      "accountNumber": null,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    };

    const res = await getDocumentList(data);
    if (res?.responseData?.lstResponseStatus[0]?.statusCode === '0' || res?.responseData?.lstResponseStatus[0]?.statusCode === 0) {
      if (res?.responseData?.responseData?.length == 0) {
        setNoResultFound(true)
      } else {
        setNoResultFound(false)
      }
      setLoader(false)
      setRequestLists(res?.responseData?.responseData);
      setRequestListsFromApi(res?.responseData?.responseData);
      setRequestDocLists(res?.responseData?.responseData)
    } else {
      setLoader(false)
      setRequestLists([]);
      setRequestListsFromApi([]);
      setRequestDocLists([]);
      setErrorMessage(renderDefaultMsg('400'))
    }
  }

 
  const handleDesc = () => {
   if ((selectedYears.length !== 0) && (documentDesc !== "")) {
    return `${(selectedItem.documentCode === 'MS') ? (documentDesc + "\\ Month(s) requested: " + selectedYears.join(", ")) : (documentDesc + "\\ Year(s) requested: " + selectedYears.join(", "))}`
   } else if ((selectedYears.length == 0) && (documentDesc == "")) {
    return "";
   } else if ((selectedYears.length == 0) && (documentDesc !== "")) {
    return `${documentDesc}`
   } else if ((selectedYears.length !== 0) && (documentDesc == "")) {
    return `${(selectedItem.documentCode === 'MS') ? ("Month(s) requested: " + selectedYears.join(", ")) : ("Year(s) requested: " + selectedYears.join(", "))}`
   }
  }

  const submitRequestDocument = async () => {
    const desc = handleDesc();
    setLoader(true)
    const data = {
      "monthYears": selectedYears?.length == 0 ? null : selectedYears.join(", "),
      "docCode": documentRequestId,
      "accountNumber": accounts.length == 1 ? props?.accountNumber : selectedLoan.accountNumber,
      "documentType": selectedItem.documentCode === 'OT' ? `${'Other Document - ' + otherDocumentName}` : documentType,
      "description": desc,
      "isEmailConsent": true,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "integrationType": selectedItem.integrationType,
      "sla": selectedItem.sla,
      "userName": ""
    };

    const res = await submitDocumentRequest(data);
    if (res?.responseData?.lstResponseStatus[0]?.statusCode === '0') {
      clearFormData()
      setSearchInput('')
      const message = `Your request#${res?.responseData?.responseData?.requestNumber} was submitted successfully. Based on your loan status and availability of documents, it may take up to ${selectedItem.sla} to process your request.`;
      setSnackBarMsg({requestNo: res?.responseData?.responseData?.requestNumber, sla: selectedItem.sla})
      setOpenSnackBar(true);
      //await dispatch(showToaster({ status: true, message: message, successToast: true }));
      getDocumentListFunc();
      setFilterApplied(false);
      setSearchInput('');
      setFilterValuesApply({ status: {'In Progress':false,'Completed':false,'Clarification Needed':false,'Submitted':false}, customEnd: null, customStart: null, requestedDates: { startDate: '', endDate: '', requestedPeriod: '' } })
      applyMultiLoanFilter('all');
      
      setLoader(false)
      setResultFoundOnSearch(false);
      setNoResultFound(false);
    } else {
      setLoader(false)
      setErrorMessage(renderDefaultMsg('400'))
    }
  }

  const clearFormData = () => {
    setSelectedLoan({})
    setSelectedItem({})
    setDocumentDesc("");
    setDocumentType()
    setDocumentId()
    setValue('Y')
    setFormStatus(false)
    setOtherDocumentName()
    setYearsData([])
    setYears([])
    setDescriptionStatus(false)
  }

  const handleRequestDescChange = (event) => {
    setDocumentDesc(event.target.value)
  }

  if (loader) {
    return (
      <Box className={classes.loader}>
        <CircularProgress />
      </Box>
    )
  }

  const selectedLoanDetails = (loan) => {
    setSelectedLoan(loan)
    getAccessPermission(loan)
    getDocumentType(loan)
  }

  let allDocumentTypes = '';
  if (documentTypes && documentTypes?.length) {
    allDocumentTypes = documentTypes.map((item) => {
      return (
        <MenuItem value={item.documentCode} key={item.documentCode} onClick={() => selectDocumentType(item)}>{item.documentName}</MenuItem>
      )
    })
  }

  let loansDetails = '';
  if (accounts && accounts?.length) {
    loansDetails = accounts.map((loan) => {
      return (
        <MenuItem value={loan.accountNumber} key={loan.accountNumber} onClick={() => selectedLoanDetails(loan)}><span className={classes.loan}>{decrypt(loan.accountNumber)} - </span> {loan.propertyAddress1}</MenuItem>
      )
    })
  }
 
  const calculateCommentLenth = () => {
    const length = documentDesc?.length
    if (isNaN(length)) {
      return 0
    } else {
      return length
    }
  }

  const getYearDisable = (option) => {
     if (typeof years[0] === 'string' ? years[0]?.includes('statement') : false) {
      return years[0]
     }
  }

  const disableCurrentYear = () => {
    const isDecember = new Date().getMonth() == 11;
    const isJanuary = new Date().getMonth() == 0;
    return isDecember || isJanuary
  }
 
  const selectDocumentType = (item) => {
    setYears([])
    setDocumentDesc("");
    setDocumentType(item?.documentName);
    setDocumentId(item?.documentCode);
    setSelectedItem(item);
    setPermission(true);
    setYearsData([])

    if (item.documentCode == 'EA') {
      const currentYear = new Date().getFullYear();
      const previousYear =  currentYear-1;
      const thirdYear = previousYear-1;
      const fourthYear = thirdYear-1;
      setYearsData([currentYear, previousYear, thirdYear, fourthYear])
    }

    if (item.documentCode == 'TS') {
      const year = new Date().getFullYear()
      const currentYear =  disableCurrentYear() ? (new Date().getMonth() == 0 ? year-1 : year) + ` (This year end statement will be available by January 31st ${ new Date().getMonth() == 0 ? year : year + 1})` : year - 1;
      const previousYear =  disableCurrentYear() ? ((new Date().getMonth() == 0) ? year -2 : year -1) : year -2;
      const thirdYear = previousYear-1;
      const fourthYear = thirdYear-1;
      const fifthYear = fourthYear-1;
      const sixthYear = fifthYear-1;
      setYearsData([currentYear, previousYear, thirdYear, fourthYear, fifthYear, sixthYear])
    }

    if (item.documentCode == 'MS') {
      var monthName = new Array("January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December");
      var d = new Date();
      d.setDate(1);
      let dates = []
      for (var i=0; i<=24; i++) {
          dates.push(monthName[d.getMonth()] + ' ' + d.getFullYear())
          d.setMonth(d.getMonth() - 1);
      }
      setYearsData(dates)
    }

    if (item.integrationType === 'Lanvera') {
      setPopupText({
        statusTitle: 'Do You Know?', documentRequest: true,
        statusBody: `Your ${item.documentName} is readily available in the document center. Click the "Document Center" button to find the document. `, handleSubmitCloseText: 'SKIP & CONTINUE', handleSubmitText: 'Document Center',
        hideCancel: false, showCloseIcon: false
      })
      setSubmitUrl('/Documentcenter');
      setShowPopup(true);
    }
    else if (item.integrationType === 'Request') {
      if (item.documentCode == 'PO') {
        setPopupText({
          statusTitle: 'Redirecting to Request Payoff Page', documentRequest: true,
          statusBody: `Click the button below to order a new payoff quote. `, handleSubmitText: 'GO TO REQUEST PAYOFF',
          hideCancel: false,
        })
      } else if (item.documentCode == 'VM') {
        setPopupText({
          statusTitle: 'Redirecting to Verification of Mortgage Page', documentRequest: true,
          statusBody: `Click the button below to order a verification of mortgage letter. `, handleSubmitText: 'GO TO VERIFICATION OF MORTGAGE',
          hideCancel: false,
        })
      }
     
      setSubmitUrl(item.redirectURL);
      setRestrictPopup(true);
    }
    else if (item.documentCode === 'PF') {
      if ((moment(payoffDate).add(15, 'day')) > new Date()) {
        setPopupText({
          statusTitle: 'Alert', documentRequest: true,
          statusBody: `We are unable to process this request at this time due to the timing of receipt of funds. Please resubmit your request on or after ${moment(payoffDate).add(15, 'day').format('MM/DD/YYYY')}. `, handleSubmitText: 'OK',
          hideCancel: true, showCloseIcon: true
        })
        setSubmitUrl(null);
        setRestrictPopup(true);
        setPermission(false)
      }
    }
  }

  const openFilters = () => {
    if (noResultFound) {
      setOpenFilter(false);
    } else {
      setOpenFilter(true);
    }
  }

  const handleRestrictSubmit = () => {
    if ((selectedItem?.integrationType == 'Request') || (selectedItem?.integrationType == 'Lanvera') || (selectedItem?.documentCode == 'PF')) {
      sendActivityData(popupText.handleSubmitText)
    }
    setRestrictPopup(false); 

    if (submitUrl)
      history.push(submitUrl);
  }

  const handleRestrictCancelIcon = () => {
    sendActivityData('Cross')
    setSelectedItem({})
    getDocumentType(props)
    // setDocumentType()
    // setDocumentId()
    setRestrictPopup(false)
  }

  const closeDrawer = () => {
    setOpenFilter(false);
  }

  const handleCancelIcon = () => {
    setShowPopup(false)
  }

  const handleSubmitClose = () => {
    if ((selectedItem?.integrationType == 'Request') || (selectedItem?.integrationType == 'Lanvera') || (selectedItem?.documentCode == 'PF')) {
      sendActivityData(popupText.handleSubmitCloseText)
    }
    setShowPopup(false)

  }
  const handleSubmit = () => {
    if ((selectedItem?.integrationType == 'Request') || (selectedItem?.integrationType == 'Lanvera') || (selectedItem?.documentCode == 'PF')) {
      sendActivityData(popupText.handleSubmitText)
    }
    setShowPopup(false); 

    if (submitUrl)
      history.push(submitUrl);
  }
  const openNewReqForm = () => {
    setFormStatus(true)
  }
  const closeForm = () => {
    clearFormData()
    setFormStatus(!openForm)
  }

  const handleAccessDeniedSubmit = () => {
    clearFields()
  }

  const clearFields = () => {
    setAccessPopup(false)
    setSelectedLoan({ accountNumber: "" })
    setSelectedItem({})
    setDocumentType()
    setDocumentId()
    setOtherDocumentName()
  }
 
  const checkValidations = () => {
    if (isAdminCheck() || (documentRequestId == undefined) || (documentRequestId == "") || ((selectedItem.documentCode === 'OT') && ((otherDocumentName?.trim().length === 0) || (otherDocumentName == undefined) || (otherDocumentName == 'null'))) || !permission || (((selectedItem.documentCode == 'EA') || (selectedItem.documentCode == 'TS') || (selectedItem.documentCode == 'MS')) && selectedYears.length == 0)) {
      return true
    } else {
      return false
    }
  }
 
 //When select radio button in filter
  //when left side drawer filters are applied
  const applyFilter = (value,filterValues) => {
    setSearchInput('');
    applyMultiLoanFilter('all');
    setFilterValuesApply(filterValues);
    
    const keys  = Object.keys(filterValues?.status) || [];
    const data = keys.filter((data)=>{
     return filterValues.status[data] === true;
    }) || [];
    const isStatusApplied = data.length?true:false;
    const isDateApplied = filterValues.requestedDates.requestedPeriod?true:false;
    const startDate = moment(filterValues.requestedDates.startDate).format('MM/DD/YYYY');
    const endDate = moment(filterValues.requestedDates.endDate).format('MM/DD/YYYY');
    setResultFoundOnSearch(false);
    const filteredData = requestListsFromApi.filter(filterValue => {
      const updatedDate = moment(filterValue.updatedDate).format('MM/DD/YYYY');
      if(isStatusApplied && isDateApplied ){
        if (filterValues.status[filterValue.status] && filterValues.requestedDates.startDate && filterValues.requestedDates.endDate &&
          updatedDate>=startDate&& updatedDate<=endDate) {
           return true;
         }
      }
      else if(isStatusApplied){
        if (filterValues.status[filterValue.status])
        return true;
      }
      else if(isDateApplied){
        if (filterValues.requestedDates.startDate && filterValues.requestedDates.endDate &&
          updatedDate>=startDate&& updatedDate<=endDate) {
           return true;
         }
      }
      return false;
    });
    if (!filteredData.length) {
      setResultFoundOnSearch(true);
    }
    else{
      setResultFoundOnSearch(false);
    }
    if(!isDateApplied && !isStatusApplied){
      setRequestLists(requestListsFromApi);
      setResultFoundOnSearch(false);
    }
    else{
      setRequestLists(filteredData);
    }

    if (!value) {
      if(isDateApplied || isStatusApplied)
      setFilterApplied(true);
      closeDrawer();
    }
  }
  const applyMultiLoanFilter=(value,isFromSearch)=>{

    setFilterValuesApply({ status: {'In Progress':false,'Completed':false,'Clarification Needed':false,'Submitted':false}, customEnd: null, customStart: null, requestedDates: { startDate: '', endDate: '', requestedPeriod: '' } })
    if(!isFromSearch)
    setSearchInput('');
    setFilterApplied(false);


    //const list  = requestLists.length>0?requestLists:requestListsFromApi;
    const filteredData = requestListsFromApi.filter(filterValue => {
      // Filter by accountNumber
      if (value && value === filterValue.accountNumber && value !== 'all') {
        return true;
      }
      return false;
    });
 
    if (value && value !== 'all') {
      if (!filteredData.length) {
        setResultFoundOnSearch(true);
      }
      else{
        setResultFoundOnSearch(false);
      }
      let acc = accounts.map((data) => {
        if (value === data.accountNumber) {
          data.selected = true;
        }
        else {
          data.selected = false;
        }
        return data;
      });
      setAccounts(acc);
      setAllSelected(false);
      setRequestLists(filteredData);
    }
    
    if (value === 'all') {
      let acc = accounts.map((data) => {
        data.selected = false;
        return data;
      });

      setAccounts(acc);
      setAllSelected(true);
      setRequestLists(requestListsFromApi);
      if(!requestListsFromApi.length){
        setResultFoundOnSearch(true);
      }
      else{
        setResultFoundOnSearch(false);
      }
    }
   
  }
  
  //When to clear to all filter
  const clearAllFilter = () => {
    setRequestLists(requestListsFromApi);
    //setFilterValues({ status: {}, requestedDates: {} })
    setFilterApplied(false);
    closeDrawer();
  }


  const handleSearchClick = (e) => {
    var lists = requestDocLists;
    e?.preventDefault();
    setResultFoundOnSearch(false)
    setFormStatus(false)
    setFilterValuesApply({ status: {'In Progress':false,'Completed':false,'Clarification Needed':false,'Submitted':false}, customEnd: null, customStart: null, requestedDates: { startDate: '', endDate: '', requestedPeriod: '' } })
      setFilterApplied(false);
      applyMultiLoanFilter('all',true);
      //setSearchInput('');
    if (searchInput === '') {
      setRequestLists(requestDocLists)
    } else {
      if (defaultSearch == 'Name') {

        const updatedDocLists = lists.filter((item) => {
          return item.documentType.toLowerCase().includes(searchInput?.toLowerCase())
        })
        setRequestLists(updatedDocLists)
        if (updatedDocLists.length == 0) {
          setResultFoundOnSearch(true)
        }
      } else {
        const updatedDocLists = lists.filter((item) => {
          return item.requestNumber.toLowerCase().includes(searchInput?.toLowerCase())
        })
        setRequestLists(updatedDocLists)
        if (updatedDocLists == 0) {
          setResultFoundOnSearch(true)
        }
      }
    }
  }

  const clearSearch = () => {
    setSearchInput('')
    setRequestLists(requestDocLists)
    setResultFoundOnSearch(false)
  }

  const sendActivityData = async (buttonName) => {
    const remark = `${documentType + ' Popup'} - ` + `User clicked on ${buttonName} button.`;
    const data = {
      "accountNumber":  accounts.length == 1 ? props?.accountNumber : selectedLoan.accountNumber,
      "activityCode": "UAL203",
      "activityStatus": "Success",
      "action": (selectedItem?.documentCode == 'PF') ? 'Paid in full letter popup' : buttonName,
      "controller": `${documentType + ' Popup'}`,
      "apiName": "Documents/popupTelemetry",
      "activityRemark":  remark,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    await insertActivityLog(data);
  }

  const addDescription = () => {
    setDescriptionStatus(!addDescriptionStatus)
    setDocumentDesc("")
  }

  const handleCloseSnackBar = () => {
    setOpenSnackBar(false)
  }

  return (
    <CssBaseline>
      <Box container="true" maxWidth='xl' className={classes.root}>
        <Box className={classes.container}>
          <CustomBreadcrum backPage="Documents" backPageLink="/DocumentCenter" activePage="Document Requests" />
          <h1 className={classes.pageHeading} color="textPrimary" component='h4'>Request New Document</h1>
          <>
            <Box className={classes.infoStrip}>
            <p className={classes.errorMsg} dangerouslySetInnerHTML={{ __html: errMessage !== '' ? errMessage : '' }} />

              { backBtnStatus &&
               <Grid container spacing={1} justifyContent="space-around" columns={{ xs: 12, sm: 12, md: 12 }} >
                <Grid item xs={12} sm={12} md={12} className={classes.backToDocument}>
                <img src={BackButton}/>
                <Link className={'BackText'} to='/DocumentCenter'> BACK TO DOCUMENT CENTER</Link>
                </Grid>
                </Grid>
              }
              
              <Grid container spacing={1} justifyContent="space-around" columns={{ xs: 12, sm: 12, md: 12 }} >
                {btnDisabled &&
                  <Grid item xs={12} sm={3} md={3} className={classes.requestBtnBox}>
                    <Button className={classes.requestBtn} size="large" fullWidth onClick={() => openNewReqForm()}
                      variant="contained"
                      disabled={openForm}
                      color="primary">
                      REQUEST NEW DOCUMENT
                    </Button>
                  </Grid>
                }
                {!btnDisabled &&
                  <Grid item xs={12} sm={3} md={3} className={classes.requestBtnBox}>
                    <Button className={classes.documentHeadDisable} size="large" fullWidth
                      variant='outlined'>
                      REQUEST NEW DOCUMENT
                     
                      {!mediaQuery && <LightTooltip
                        title={"Due to the status of your loan, “Request new document” option is disabled. Please contact us directly to request the document needed."}
                        arrow  >
                        <span style={{ marginLeft: '3px', marginTop: '3px' }} ><ErrorOutlineIcon /></span>
                      </LightTooltip> }
                      {mediaQuery && 
                      <ClickAwayListener onClickAway={handleTooltipClose}>
                      <LightTooltip
                        title={"Due to the status of your loan, “Request new document” option is disabled. Please contact us directly to request the document needed."}
                          arrow
                          open={ openToolTip }
                        >
                          <span style={{ marginLeft: '3px', marginTop: '3px' }} onClick={handleTooltipOpen}><ErrorOutlineIcon /></span>
                        </LightTooltip>
                      </ClickAwayListener>}
                    </Button>
                  </Grid>
                }
                <Grid item xs={12} sm={1} md={1} className={classes.groupByBox}>
                  <Typography className={classes.searchLabel}>Search By : </Typography>
                  <RadioGroup row aria-label="Search By" name="Search By" value={defaultSearch}
                    onChange={handleSearchDropdown} className={classes.groupByRadio} >
                    <FormControlLabel value="Name"
                      control={<Radio />} label="Document Name" checked={defaultSearch === 'Name' ? true : false} 
                      classes={{ label:
                        classes.radioBtnName
                      }} />
                    <FormControlLabel value="Id"
                      control={<Radio />} label="Request ID" checked={defaultSearch === 'Id' ? true : false} 
                      classes={{ label:
                        classes.radioBtnName
                      }} />
                  </RadioGroup>
                </Grid>

                <Grid item xs={12} sm={8} md={8} className={classes.searchBox}>
                  <Grid container spacing={1} justifyContent="space-around" columns={{ xs: 12, sm: 12, md: 12 }} >
                    <Grid item xs={12} sm={3} md={3} className={classes.requestBtnBox}>
                      <TextField
                        id="outlined-select-document-name"
                        select
                        label={""}
                        className={classes.documentNameSelect}
                        value={defaultSearch}
                        disabled={noResultFound ? true : false}
                        onChange={handleSearchDropdown}
                        InputLabelProps={{ shrink: false }}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                          style: {
                            borderRadius: '80px 0px 0px 80px',
                            height: '45px',
                            background: '#D9D9D9',
                            padding: '20px',
                            padding: '20px 10px 20px 20px',
                            fontSize: '15px'
                          }
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem key={1} value={'Name'}>
                          Document Name
                        </MenuItem>
                        <MenuItem key={2} value={'Id'}>
                          Request ID
                        </MenuItem>
                      </TextField>
                    </Grid>

                    <Grid item xs={12} sm={9} md={9} className={classes.requestBtnBox}>

                      <form noValidate autoComplete="off" onSubmit={handleSearchClick}>
                        <TextField
                          placeholder={defaultSearch == 'Name' ? 'Type Document Name' : 'Type Request ID'}
                          variant="outlined"
                          InputLabelProps={{ shrink: false }}
                          className={classes.searchTextBox}
                          onChange={handleSearch}
                          value={searchInput}
                          disabled={noResultFound ? true : false}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment>
                                {(searchInput !== "") &&
                                  <IconButton
                                    aria-label="clear search"
                                    onClick={() => clearSearch()}
                                    edge="end"
                                  >
                                    <ClearIcon />
                                  </IconButton>}
                                <IconButton className={noResultFound ? classes.searchIconDisable : classes.searchIcon} onClick={() => handleSearchClick()}>
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                            className: classes.searchField,
                          }}
                        />
                      </form>

                    </Grid>
                  </Grid>

                </Grid>
              </Grid>

              {openForm &&
                <Box className={classes.formContainer}>
                  <Box>

                    <Grid container>
                      <Grid item md={12} xs={12} lg={12}>
                        <Box>

                          <Grid container>
                            <Grid item md={11} xs={11} lg={11}>
                              <Box className={classes.requestNewDoc}>Request New Document</Box>
                            </Grid>
                            <Grid item md={1} xs={1} lg={1}>
                              <IconButton className={classes.closeIcon} onClick={() => closeForm()}>
                                <CancelIcon />
                              </IconButton>
                            </Grid>
                          </Grid>

                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={classes.requestContainer}>
                    {(accounts?.length > 1) && (loansDetails?.length > 0) &&

                      <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%', marginBottom: '20px' }}>
                        <InputLabel id="borrower-type-select-outlined">Select Loan <span style={{ color: "red" }}>*</span></InputLabel>
                        <Select
                          labelId="borrower-type-outlined-label"
                          // inputRef={el => myRef.current['borrowerTypeFocus'] = el}
                          required
                          value={selectedLoan?.accountNumber}
                          label="Select Loan *"
                          className={classes.inputStyle}
                          fullWidth
                        >
                          {loansDetails}
                        </Select>
                      </FormControl>
                    }

                    {allDocumentTypes?.length > 0 &&

                      <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
                        <InputLabel id="borrower-type-select-outlined">Select Document <span style={{ color: "red" }}>*</span></InputLabel>
                        <Select
                          labelId="borrower-type-outlined-label"
                          // inputRef={el => myRef.current['borrowerTypeFocus'] = el}
                          required
                          value={selectedItem.documentName}
                          label="Select Document *"
                          className={classes.inputStyle}
                          fullWidth
                        >
                          {allDocumentTypes}
                        </Select>
                      </FormControl>
                    }
                    { ((selectedItem.documentCode == 'EA') || (selectedItem.documentCode == 'TS') || (selectedItem.documentCode == 'MS')) &&
                   
                    <Box style={{ paddingTop: '20px', width: '100%' }}>                          
                        
                           <Autocomplete
                              multiple
                              required
                              id="checkboxes-tags-demo"
                              options={years}
                              value={selectedYears}
                              disableCloseOnSelect
                              getOptionDisabled={(option) =>
                                 option == getYearDisable(option)
                              }
                              onChange={(event, value) => setYears(value)}
                              getOptionLabel={(option) => option.toString()}
                              style={{ 
                                color: '#535D6D',
                                fontSize: '15px',
                                fontFamily: 'Montserrat',
                                fontWeight: 600
                             }}
                              renderOption={(option, { selected }) => (
                                <React.Fragment>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    checked={selected}
                                   
                                  />
                                  {option}
                                </React.Fragment>
                              )}
                              renderInput={(params) => (
                                <TextField {...params} variant="outlined" label={(selectedItem.documentCode == 'MS') ? "Select month(s)" : "Select year(s)"} className={classes.inputStyle} />
                              )}
                            />
                     </Box>   

                   }
                    {selectedItem.documentCode === 'OT' && <Box className={classes.textareaSection}>
                      <TextField
                        required
                        color="primary"
                        variant="outlined"
                        type="text"
                        label="Enter Document Name"
                        value={otherDocumentName}
                        onChange={(event) => setOtherDocumentName(event.target.value)}
                        className={classes.textBox}
                        InputLabelProps={{
                          FormLabelClasses: {
                            asterisk: classes.labelAsterisk
                          }
                        }}
                        inputProps={{ maxLength: 30 }}
                      />
                      <Typography className={classes.textAreaValidation}></Typography>
                    </Box>}
                    
                  

                  { (selectedItem.documentName && !addDescriptionStatus) &&
                  <Box style={{ display: 'flex',marginBottom: '10px', marginTop: '20px', marginLeft: '10px'}}>
                     <Grid container spacing={2}  columns={{ xs: 12, sm: 12, md: 12 }}>
                      <Box><img src={circleIcon} style={{cursor: 'pointer'}} onClick={() => addDescription()}/></Box>
                      <Box className={classes.addDescription} onClick={() => addDescription()}>Add Description </Box>
                     </Grid>
                  </Box>
                } 
                 
                  { addDescriptionStatus &&
                    <Box className={classes.textareaSection}>

                      <TextareaAutosize
                        style={{ height: '96px' }}
                        color="primary"
                        id={`outlined_impacted250`}
                        variant="outlined"
                        type="text"
                        name="comments"
                        maxLength="500"
                        onChange={(event) => handleRequestDescChange(event)}
                        value={documentDesc}
                        placeholder='Request Description'
                        InputLabelProps={{
                          FormLabelClasses: {
                            asterisk: classes.labelAsterisk
                          }
                        }}
                        className={`${classes.textArea}`}
                     
                      >
                         </TextareaAutosize>
                      <Typography className={classes.textAreaValidation}>{calculateCommentLenth()}/500</Typography>
                      <Box style={{ display: 'flex',marginBottom: '10px', marginTop: '20px', marginLeft: '10px'}}>
                        <Grid container spacing={2}  columns={{ xs: 12, sm: 12, md: 12 }}>
                          <Box><img src={trashIcon} style={{cursor: 'pointer'}} onClick={() => addDescription()}/></Box>
                          <Box className={classes.addDescription} onClick={() => addDescription()}>Delete Description </Box>
                        </Grid>
                      </Box>
                    </Box>
                  }   

                    {/* { !emailConsentStatus &&
                      <Box>
                        <Box className={classes.radioBtnDesc}>Do you agree to receive digital communication for this request? <span style={{color:"red"}}>*</span></Box>
                        <div className={classes.radioBtn}>
                          <RadioGroup row aria-label="digital_comm" name="digital_comm" value={value}
                            onChange={handleRadioChange}>
                            <FormControlLabel value="Y"
                              control={<Radio />} label="Yes" checked={value === 'Y' ? true : false}/>
                            <FormControlLabel value="N" checked={value === 'N' ? true : false}
                              control={<Radio />} label="No" />
                          </RadioGroup>
                        </div>
                      </Box>
                    } */}

                    <Grid container className={classes.footerSection}>
                      <Grid item md={12} xs={12} lg={12}>
                        <Grid container style={{ marginTop: '12px' }}>
                          <Grid item xs={5} md={6} className={classes.pr35}>
                            <Box className={classes.cancelBtnBox}>
                              <Button
                                className={classes.cancelBtn}
                                variant="outlined"
                                onClick={closeForm}
                              >CANCEL</Button>
                            </Box>
                          </Grid>
                          <Grid item xs={7} md={6} className={classes.pl35}>
                            <Box className={classes.saveBtnBox}>
                              {loader ?
                                <Button disabled={true} type='submit' className={classes.saveBtn} variant="contained" color='primary'>
                                  <CircularProgress color='primary' />
                                </Button>
                                :
                                <Button
                                  className={classes.saveBtn}
                                  variant="contained"
                                  disabled={checkValidations()}
                                  onClick={() => submitRequestDocument()}
                                  color="primary">
                                  SUBMIT</Button>}
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              }

              {/* <Grid item xs={12} sm={12} md={12} className={classes.requestBtnBox}>
                  <Box className={classes.disableRequestFlow}>
                    <ErrorOutlineIcon style={{fill:"#EDB36A"}} className="icon"/>
                     <span className="text">Due to the status of your loan, “Request new document” option is disabled. Please contact us directly to request the document needed.</span>
                  </Box>
                </Grid> */}
              <Box>
                <Grid container spacing={1} justifyContent="space-around" style={{ padding: '12px 0px' }} columns={{ xs: 12, sm: 12, md: 12 }} >
                  <Grid item xs={8} sm={8} md={9}>
                    <h2 className={classes.documentRequest}>Document Requests</h2>
                  </Grid>

                  {/* <Grid item xs={12} sm={12} md={6}>
                   {(requestLists?.length > 0) &&
                    <Box className={classes.greenBox}>
                      <Box className={classes.warningBox}>
                        <img src={Warning} />
                        <Box style={{ marginLeft: '10px' }}>After 90 days from the resolution, the request will be disappeared from "Document Requests" history.</Box></Box>
                    </Box>
                  } 
                </Grid> */}

                  <Grid item xs={4} sm={4} md={3} className={classes.filterSection}>

                    {/* {(noResultFoundOnSearch && (searchInput != ''))? <Box className={classes.disableFilter}>Filters <img src={Filter}  className={classes.disableIcon} /></Box> :  */}
                      
                      <Box className={classes.filterText} onClick={openFilters}>Filters <img src={Filter}  className={classes.image} /> 
                        {filterApplied && <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" className={classes.filterApplied} viewBox="0 0 9 9" fill="none">
                          <circle cx="4.5" cy="4.5" r="4.5" fill="#E02020" />
                        </svg>}
                      </Box>
                  </Grid>
                </Grid>

                {(accountsToFilter?.length > 1) &&
                  <Box style={{ display: 'flex',marginBottom: '20px', marginTop: '12px'}}>
                     <Grid container spacing={2}  columns={{ xs: 12, sm: 12, md: 12 }}>
                      <Box className={`${classes.allLoanFilter} ${allSelected ? 'selected' : ''}`} onClick={() => applyMultiLoanFilter('all')}>All</Box>
                    {accountsToFilter.map((loan) => {
                      return (
                        <Box className={`${classes.loanNumber} ${loan.selected ? 'selected' : ''}`} onClick={() => applyMultiLoanFilter(loan.accountNumber)}>Loan {maskLoan(decrypt(loan.accountNumber))}</Box>
                      )
                    })}
                     </Grid>
                  </Box>
                }
                <Grid>
                  {openFilter && <FilterDrawer  closeDrawer={closeDrawer} clearAllFilter={clearAllFilter} filterValuesApplied={filterValuesApply} applyFilter={applyFilter} />}
                </Grid>

                {(requestLists?.length > 0) &&
                  <Box className={classes.containerPadding}>
                    <Requests accountNumber={props.accountNumber} requestLists={requestLists} accounts={accounts} showFeedBackWidget={showFeedBackWidget}></Requests>
                  </Box>}
                {((requestLists?.length == 0) && !openForm && noResultFound) &&
                  <Box className={classes.noRecordFoundContainer}>
                    <img src={databaseIcon} />
                    <h2 className={classes.documentHead2}>No Records Found</h2>
                    <h2 className={classes.noRequestData}>No request data to show you right now.</h2>
                  </Box>
                }
                {noResultFoundOnSearch && !noResultFound &&
                  <Box className={classes.noRecordFoundContainer}>
                    <img src={databaseIcon} />
                    <h2 className={classes.documentHead2}>No Matching Results Found</h2>
                  </Box>
                }
              </Box>

              {showPopup &&
                <DeleteConfirmation
                  open={showPopup}
                  popupText={popupText}
                  handleCancelIcon={handleCancelIcon}
                  handleSubmitClose={handleSubmitClose}
                  handleSubmit={handleSubmit}
                />
              }

              {
                accessPopup &&
                <AccessDenied
                  open={accessPopup}
                  selectedLoan={selectedLoan}
                  handleAccessDeniedSubmit={handleAccessDeniedSubmit}
                />
              }
              {
                showRestrictPopup &&
                <PayOffVomPifPopup
                  open={showRestrictPopup}
                  popupText={popupText}
                  handleCancelIcon={handleRestrictCancelIcon}
                  handleSubmit={handleRestrictSubmit}
                />
              }
              { showSubmitPopup &&
                <DocumentReqSubmitPopup 
                  open={showSubmitPopup}
                  submitMsg={submitMsg}
                  handleCancelIcon={handleCloseSnackBar}
                />
              }
            </Box>
          </>

        </Box>
      </Box>
      {/* {snackBarMsg.length !== 0 && <SnackBar
        severity={"success"}
        message={snackBarMsg} open={openSnackBar}
        handleClose={handleCloseSnackBar}
      />} */}
    </CssBaseline>
  )
}
