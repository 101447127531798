import React, { lazy, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Cookies from 'js-cookie';
import { fiv9options } from '../src/helpers'

const AccountVerificationPage = lazy(() => import("./pages/AccountVerificationPage"));
const CreateAccountPage = lazy(() => import("./pages/CreateAccountPage"));
const SignInPage = lazy(() => import("./pages/SignInPage"));
const HomePage = lazy(() => import("./pages/HomePage"));
const RecoverPasswordPage = lazy(() => import("./pages/RecoverPasswordPage"));
const RecoverUsernamePage = lazy(() => import("./pages/RecoverUsernamePage"));
const ResetPasswordPage = lazy(() => import("./pages/ResetPasswordPage"));
const DashboardPage = lazy(() => import('./pages/DashboardPage'));
const VerifyAccountPage = lazy(() => import("./pages/VerifyAccountPage"));
const HelpLandingPage = lazy(() => import("./pages/HelpLandingPage"));
const HelpDescription = lazy(() => import("./pages/HelpDescription"));
const Terms = lazy(() => import("./pages/Terms"));
const Privacy = lazy(() => import("./pages/Privacy"));
const SecurityDisclaimer = lazy(() => import("./pages/SecurityDisclaimer"));
const TransactionHistoryPage = lazy(() => import("./pages/TransactionHistoryPage"));
const NotificationPage = lazy(() => import("./pages/NotificationPage"));
const LoanInfoPage = lazy(() => import("./pages/LoanInfoPage"));
const ManageBankAccountPage = lazy(() => import("./pages/ManageBankAccountPage"));
const AddBankAccountPage = lazy(() => import("./pages/AddBankAccountPage"));
const ManageUserProfilePage = lazy(() => import("./pages/ManageUserProfilePage"));
const VerifyEmailPage = lazy(() => import("./pages/VerifyEmailPage"));
const RefinancePage = lazy(() => import("./pages/RefinancePage"));
const PaymentOptionPage = lazy(() => import("./pages/PaymentOptionPage"));
const PayoffRequestPage = lazy(() => import("./pages/PayoffRequestPage"));
const VOMPage = lazy(() => import("./pages/VOMPage"));
const FinancialHardshipPage = lazy(() => import("./pages/FinancialHardshipPage"));
const CovidFinancialHardshipPage = lazy(() => import("./pages/CovidFinancialHardshipPage"));
const FinancialInfoPage = lazy(() => import("./pages/FinancialInfoPage"));
const FeesSchedulePage = lazy(() => import("./pages/FeesSchedulePage"));
const ThirdPartyRequestPage = lazy(() => import("./pages/ThirdPartyRequestPage"));
const ThirdPartyRequestManagePage = lazy(() => import("./pages/ThirdPartyRequestManagePage"));
const LossMitigationPage = lazy(() => import("./pages/LossMitigationPage"));
const DocumentcenterPage = lazy(() => import("./pages/DocumentcenterPage"));
const AboutusPage = lazy(() => import("./pages/AboutusPage"));
const ContactusPage = lazy(() => import("./pages/ContactusPage"));
const WhatsnewPage = lazy(() => import("./pages/WhatsnewPage"));
const TodolistPage = lazy(() => import("./pages/TodolistPage"));
const FinancialHardshipAdditionalFormPage = lazy(() => import("./pages/FinancialHardshipAdditionalFormPage"));
const HardshipFlowPage = lazy(() => import("./pages/HardshipFlowPage"));
const RefinanceMortgageFaqPage = lazy(() => import("./pages/RefinanceMortgageFaqPage"));
const GettingStartedPage = lazy(() => import("./pages/GettingStartedPage"));
const ScrollToTop = lazy(() => import("./pages/ScrollToTop"));
const PageNotFoundPage = lazy(() => import("./pages/PageNotFoundPage"));
const UpdateSsnPage = lazy(() => import("./pages/UpdateSsnPage"));

const RecoverPasswordBySsnPage = lazy(() => import("./pages/RecoverPasswordBySsnPage"));
const RequestSubOrdinationPage = lazy(() => import("./pages/RequestSubOrdinationPage"));
const NewlyBoardedPage = lazy(() => import("./pages/NewlyBoardedPage"));
const EscrowInfoPage = lazy(() => import("./pages/EscrowInfoPage"));
const EscrowManagePage = lazy(() => import("./pages/EscrowManagePage"));
const DisasterAssistancePage = lazy(() => import("./pages/DisasterAssistancePage"));
const EmailTextPolicy = lazy(() => import("./pages/EmailTextPolicyPage"));
const RefinanceAdvertisementPage = lazy(() => import("./pages/RefinanceAdvertisementPage"));
const IdentityTheftsPage = lazy(() => import("./pages/IdentityTheftsPage"));
const PromiseToPayPage = lazy(() => import("./pages/PromiseToPayPage"));
const EmailSigninPage = lazy(() => import("./pages/EmailSigninPage"));
const DocumentRequestPage = lazy(() => import("./pages/DocumentRequestPage"));
const FormCenterPage = lazy(() => import("./pages/FormCenterPage"));
const MaturityExtensionPage = lazy(() => import("./pages/MaturityExtensionPage"));
const PaperLessPage = lazy(() => import("./pages/PaperLessPage"));

const PrivateRoute = ({ children, ...rest }) => {

  return (
    <Route {...rest}
      render={({ location }) => {
        if (Cookies.get('privateToken')) {
          return children;
        }
        return (
          <Redirect to={{
            pathname: "/SignIn",
            state: { from: location, error: "Please login first to access this functionality" }
          }} />
        );
      }}
    />
  );
}

const Routes = () => {
  useEffect(() => { 
    if (!Cookies.get('privateToken')) { 
      if (window.location.pathname.includes('Communication')) {
        Cookies.set('redirectUrl', window.location.pathname)
      } else if (window.location.pathname.includes('Identity')) {
        Cookies.set('redirectUrl', window.location.pathname)
      } else if (window.location.pathname.includes('EscrowInfo')) {
        Cookies.set('redirectUrl', window.location.pathname)
      } else if (window.location.href.includes('DocumentRequest?requestId')) {
        Cookies.set('redirectUrl', 'DocumentRequest')
        let params = (new URL(document.location)).searchParams;
        let requestId = params.get("requestId");
        Cookies.set('requestId', requestId)
      } else if (window.location.pathname.includes('paperlessconsentY')) {
        Cookies.set('redirectUrl', "Communication")
      }
    }
  })

  useEffect(() => {
  
    const emailOptInVerbiage = "By submitting this email, you agree to receive a response at any time of day, and are confirming that such delivery and receipt is not occurring at an unusual or inconvenient time or place"

    var observationTarget = document.body;
    var observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
            if (mutation.addedNodes.length && mutation.addedNodes[0].className === 'five9-frame') {
               
                document.querySelector("#five9-maximize-button").addEventListener('click', () => {
                    document.querySelector(".five9-frame").style.display = 'none';
                    Swal.fire({
                        title: 'Confirmation',
                        text: emailOptInVerbiage,
                        icon: 'info',
                        confirmButtonText: 'Continue',
                        showDenyButton: true,
                        denyButtonText: "Deny"
                    }).then((result) => {
                        document.querySelector(".five9-frame").style.display = '';
                        if(result.isDenied || result.isDismissed) {
                            document.querySelector("#five9-minimize-icon").click();
                        }
                    })
                })
            }
        });
       });
        var config = { attributes: false, childList: true, characterData: false };
        observer.observe(observationTarget, config);
        window?.Five9SocialWidget?.addWidget(fiv9options);
        return () => window?.Five9SocialWidget?.removeWidget()
  }, []);
 
  
  const base = Cookies.get("privateLabelUrl");
  const basename = `/${base}/`;

  return (
    <Router basename={basename}>
      <ScrollToTop>
        <Switch>
          <Route exact path='/' >
            <HomePage />
          </Route>
          <Route exact path='/RecoverUsername' >
            <RecoverUsernamePage />
          </Route>
          <Route exact path='/RecoverPassword' >
            <RecoverPasswordPage />
          </Route>
          <Route exact path='/RecoverPasswordBySsn' >
            <RecoverPasswordBySsnPage />
          </Route>
          <Route path='/ResetPassword'>
            <ResetPasswordPage />
          </Route>
          <Route exact path='/AccountVerification'>
            <AccountVerificationPage />
          </Route>
          <Route exact path='/SignIn' >
            <SignInPage />
          </Route>
          <Route exact path='/EmailSignin'>
            <EmailSigninPage />
          </Route>
          <Route exact path='/ContactUs' component={ContactusPage} />
          <Route exact path='/AboutUs' component={AboutusPage} />
          <Route exact path='/Whatsnew' component={WhatsnewPage} />
          <Route exact path='/Additionalform' component={FinancialHardshipAdditionalFormPage} />
          <Route exact path='/HelpLanding' component={HelpLandingPage} />
          <Route exact path='/Terms' component={Terms} />
          <Route exact path='/Privacy' component={Privacy} />
          <Route exact path='/SecurityDisclaimer' component={SecurityDisclaimer} />
          <Route exact path='/CreateAccount' component={CreateAccountPage} />
          <Route exact path='/VerifyAccount' component={VerifyAccountPage} />
          <Route exact path='/VerifyEmail' component={VerifyEmailPage} />
          <Route exact path='/Refinance' component={RefinancePage} />
          <Route exact path='/NewlyBoarded' component={NewlyBoardedPage} />
          <Route exact path='/RefinanceMortgageFaq' component={RefinanceMortgageFaqPage} />
          <Route path="/HelpDescription/:pageName">
            <HelpDescription />
          </Route>
          <Route exact path='/ThirdPartyRequest' component={ThirdPartyRequestPage} />
          <PrivateRoute exact path='/ManageThirdPartyRequest'>
            <ThirdPartyRequestManagePage />
          </PrivateRoute>
          <PrivateRoute exact path='/Dashboard'>
            <DashboardPage />
          </PrivateRoute>
          <PrivateRoute exact path='/TransactionHistory'>
            <TransactionHistoryPage />
          </PrivateRoute>
          <PrivateRoute exact path='/Notification'>
            <NotificationPage />
          </PrivateRoute>
          <PrivateRoute exact path='/Notification/:nid'>
            <NotificationPage />
          </PrivateRoute>
          <PrivateRoute exact path='/LoanInfo'>
            <LoanInfoPage />
          </PrivateRoute>
        

          <PrivateRoute exact path='/UpdateSsn'>
            <UpdateSsnPage />
          </PrivateRoute>
          <PrivateRoute exact path='/ManageBankAccount'>
            <ManageBankAccountPage />
          </PrivateRoute>
          <PrivateRoute exact path='/AddBankAccount'>
            <AddBankAccountPage />
          </PrivateRoute>
          <PrivateRoute exact path='/UserProfile/:id'>
            <ManageUserProfilePage />
          </PrivateRoute>
          <PrivateRoute exact path='/PaymentOption'>
            <PaymentOptionPage />
          </PrivateRoute>
          <PrivateRoute exact path='/PayoffRequest'>
            <PayoffRequestPage />
          </PrivateRoute>
          <PrivateRoute exact path='/vom'>
            <VOMPage />
          </PrivateRoute>
          <PrivateRoute exact path='/FinancialHardship'>
            <FinancialHardshipPage />
          </PrivateRoute>
          <PrivateRoute exact path='/Covid19'>
            <CovidFinancialHardshipPage />
          </PrivateRoute >
          {/* <PrivateRoute exact path='/FinancialInfo'>
            <FinancialInfoPage />
          </PrivateRoute> */}
          <PrivateRoute exact path='/FeesSchedule'>
            <FeesSchedulePage />
          </PrivateRoute>
          <PrivateRoute exact path='/LossMitigationApp'>
            <LossMitigationPage />
          </PrivateRoute>
          <PrivateRoute exact path='/DocumentCenter'>
            <DocumentcenterPage />
          </PrivateRoute>
          <PrivateRoute exact path='/WorkoutStatus'>
            <TodolistPage />
          </PrivateRoute>
          <PrivateRoute exact path='/HardshipFlow'>
            <HardshipFlowPage />
          </PrivateRoute>
          <PrivateRoute exact path='/GettingStarted'>
            <GettingStartedPage />
          </PrivateRoute>
          <PrivateRoute exact path='/RequestSubordination'>
            <RequestSubOrdinationPage />
          </PrivateRoute>
          <PrivateRoute path='/EscrowInfo'>
            <EscrowInfoPage />
          </PrivateRoute>
          <PrivateRoute path='/EscrowManage'>
            <EscrowManagePage />
          </PrivateRoute>
          <PrivateRoute exact path='/DisasterAssistance'>
            <DisasterAssistancePage />
          </PrivateRoute >
          <Route exact path='/EmailTextPolicy' component={EmailTextPolicy} />
          <PrivateRoute exact path='/RefinanceAdvertisement'>
            <RefinanceAdvertisementPage/>
          </PrivateRoute>
          <PrivateRoute exact path='/IdentityTheft'>
            <IdentityTheftsPage/>
          </PrivateRoute>
          <PrivateRoute exact path='/PromiseToPay'>
            <PromiseToPayPage/>
          </PrivateRoute>
          <PrivateRoute exact path='/DocumentRequest'>
            <DocumentRequestPage/>
          </PrivateRoute>
          <PrivateRoute exact path='/FormCenter'>
            <FormCenterPage/>
          </PrivateRoute>
          <PrivateRoute exact path='/MaturityExtension'>
            <MaturityExtensionPage/>
          </PrivateRoute>
          <Route exact path='/paperlessconsentY' component={PaperLessPage} />
          <Route path='*' exact={true} component={PageNotFoundPage} />
        </Switch>
      </ScrollToTop>
    </Router>
  )
}

export default Routes
