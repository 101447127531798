import React, { Component } from 'react';
import Button from './Button';
import Form from './Form';
import PropTypes from 'prop-types';
import { updateMyFeedbackProtected } from '../../actions';
import Cookies from 'js-cookie';
import './styles.css'


const isEmpty = str => !str.trim().length;

const handleCustomPosition = ((position, formStyles) => {
	var customFormStyles;
	if (position === "left") {
		customFormStyles = { ...formStyles, left: "5%" };
	}
	else {
		customFormStyles = { ...formStyles, right: "8%" };
	}
	return customFormStyles;
})


const ButtonStyles = {
	fontSize:'16px',
	background: "#34495e",
	color: "#fff",
	fontWeight: "600",
	zIndex: 99,
	padding:'16px 89px 4px 44px',
	position:'fixed',
	borderRadius:'5px 5px 0 0',
	border: 'none',
	bottom:0,
	backgroundColor:'#1A4664',
	// width: 'auto',
	// cursor: 'pointer',
	minWidth: '222px !important',
	// right: '1% !important'
  }

class Feedback extends Component {

	constructor(props) {
		console.log(props)
		super(props);
		this.state = {
			showButton: false,
			showForm: true,
			showModal: false,
			nameInput: '',
			messageInput: '',
			emailInput: '',
			ratingInput: -1,
			resText: this.props.buttonText,
			triggerText: '',
			disableButton: false,
			bodyText: this.props.text
		};
		this.handleButtonClick = this.handleButtonClick.bind(this);
		this.handleCloseWithoutSave = this.handleCloseWithoutSave.bind(this);
		this.handleCloseWithSave = this.handleCloseWithSave.bind(this);
		// this.handleSubmit = this.handleSubmit.bind(this);
		this.handleMessageInput = this.handleMessageInput.bind(this);
	}
	handleMessageInput(inputName, content) {
		if (inputName === 'email') {
			this.setState({ emailInput: content });
		} else if (inputName === 'name') {
			this.setState({ nameInput: content });
		} else if (inputName === 'rating') {
			this.setState({ ratingInput: content });
		} else if (inputName === 'message') {
			this.setState({ messageInput: content },()=>{
				console.log("messageInput", this.state.messageInput);
			});
		}
	}
	handleRatingInput(ratingInput) {
		this.setState({ ratingInput: ratingInput });
	}
	handleNameInput(nameInput) {
		this.setState({ nameInput: nameInput });
	}
	handleEmailInput(emailInput) {
		this.setState({ emailInput: emailInput });
	}
	handleButtonClick() {
		const { handleButtonClick } = this.props;

		this.setState({ showButton: false, showForm: true });
		handleButtonClick();
	}
	handleSubmit =async () => {
		const { showButtonOnSubmit, handleSubmit, handleCloseWithoutSave } = this.props;
		// Check if the values are missing.
		if (
			// isEmpty(this.state.nameInput) || isEmpty(this.state.emailInput) || isEmpty(this.state.messageInput) || 
			(this.state.ratingInput === -1)
			) {
			// alert("Fields are missing!");
		} else {
			handleSubmit({
				message: this.state.messageInput,
				rating: this.state.ratingInput,
			});
			if (showButtonOnSubmit) {
				this.setState({ showButton: true });
			}
			this.setState({ showForm: false, 
				messageInput: '', ratingInput: -1, 
			});
			const data = {
				"loanNumber": "",
				"userName": "",
				"feedbackRating": this.state.ratingInput,
				"feedbackComment": this.state.messageInput,
				"feedbackType": "Document Request"
			  }
		

			try {
				const res = await updateMyFeedbackProtected(data);
				if (res && res?.responseData?.lstResponseStatus[0].statusCode == '0') {
				Cookies.remove("displaySurvey")
				this.setState({ resText: res?.responseData?.lstResponseStatus[0].statusDesc },()=>{
					this.setState({triggerText: true, disableButton: true})
					setTimeout(() => {
						this.setState({ 
							resText: this.props.buttonText, triggerText: false, disableButton: false })
					}, 4000)
				});
				}
				handleCloseWithoutSave();
			  } catch (e) {    
				return null;
			  }
		}
	}

	handleCloseWithSave() {

		const { handleCloseWithSave, showButtonOnClose } = this.props;
		if (showButtonOnClose) {
			this.setState({ showButton: true });
		}
		this.setState({ showForm: false });
		localStorage.setItem('closeFeedback', true)
		handleCloseWithSave();
	}


	handleCloseWithoutSave() {

		const { handleCloseWithoutSave, showButtonOnClose } = this.props;
		if (showButtonOnClose) {
			this.setState({ showButton: false });
		}
		this.setState({ 
		showForm: false,
		ratingInput: -1,
		messageInput: '',
		// showMessageInput: false
         });

		localStorage.setItem('closeFeedback', true)
		Cookies.remove("displaySurvey")
		handleCloseWithoutSave();
		this.props.hideFeedback()
	}

	render() {
		const {
			headerText,
			buttonText,
			position,
			buttonStyles,
			headerStyles,
			headerBtnStyles,
			headerBtnText,
			bodyText,
			showNameInput,
			showEmailInput,
			showRatingInput,
			showMessageInput,
			numberOfStars,
			style
		} = this.props;

		return (
			<div >
				{this.state.showForm &&
					// <div style={{minHeight: '420px', minWidth: '320px'}}>
						<Form
							style={style}
							headerText={headerText}
							numberOfStars={numberOfStars}
							position={position}
							headerStyles={headerStyles}
							headerBtnStyles={headerBtnStyles}
							headerBtnText={headerBtnText}
							handleCloseWithoutSave={this.handleCloseWithoutSave}
							handleCloseWithSave={this.handleCloseWithSave}
							handleSubmit={this.handleSubmit}
							bodyText={this.state.bodyText}
							showNameInput={showNameInput}
							showEmailInput={showEmailInput}
							showRatingInput={showRatingInput}
							showMessageInput={showMessageInput}
							nameInput={this.state.nameInput}
							emailInput={this.state.emailInput}
							ratingInput={this.state.ratingInput}
							messageInput={this.state.messageInput}
							handleMessageInput={this.handleMessageInput}
							handleCustomPosition={handleCustomPosition}
                            requestSubmit={false}
						/>
					// </div>
				}
				{this.state.showButton &&
					<React.Fragment>
						{/* <Button
							position={position}
							styleBtn={!this.state.triggerText ? 'feedBtnStyle' : 'feedBtnStyles'}
							text={this.state.resText}
							handleButtonClick={this.handleButtonClick}
							handleCustomPosition={handleCustomPosition}
							disableButton={this.state.disableButton}
							>
						</Button>
						<img src={feedbackEmoji} alt="feedback icon" 
							className='iconStyle'
						style={{
							position: 'fixed',
							right: '200px',
							bottom: '4px',
							height: '26px',
							width: '26px',
							zIndex: '999'
						}} /> */}
                         <Form
							style={style}
							headerText={headerText}
							numberOfStars={numberOfStars}
							position={position}
							headerStyles={headerStyles}
							headerBtnStyles={headerBtnStyles}
							headerBtnText={headerBtnText}
							handleCloseWithoutSave={this.handleCloseWithoutSave}
							handleCloseWithSave={this.handleCloseWithSave}
							handleSubmit={this.handleSubmit}
							bodyText={bodyText}
							showNameInput={showNameInput}
							showEmailInput={showEmailInput}
							showRatingInput={showRatingInput}
							showMessageInput={showMessageInput}
							nameInput={this.state.nameInput}
							emailInput={this.state.emailInput}
							ratingInput={this.state.ratingInput}
							messageInput={this.state.messageInput}
							handleMessageInput={this.handleMessageInput}
							handleCustomPosition={handleCustomPosition}
                            requestSubmit={true}
						/> 

					</React.Fragment>
				}
                
			</div>
		)
	}
}

Feedback.propTypes = {
	headerText: PropTypes.string,
	bodyText: PropTypes.string,
	position: PropTypes.string,
	handleClose: PropTypes.func,
	handleSubmit: PropTypes.func,
	handleButtonClick: PropTypes.func,
	showButtonOnClose: PropTypes.bool,
	showButtonOnSubmit: PropTypes.bool,
	buttonStyles: PropTypes.object,
	headerStyles: PropTypes.object,
	headerBtnStyles: PropTypes.object,
	bodyStyles: PropTypes.object,
	footerStyles: PropTypes.object,
	buttonText: PropTypes.string,
	headerBtnText: PropTypes.string,
	showEmailInput: PropTypes.bool,
	showRatingInput: PropTypes.bool,
	showMessageInput: PropTypes.bool,
	handleCloseWithoutSave: PropTypes.func,
	handleCloseWithSave: PropTypes.func
}

Feedback.defaultProps = {
	position: 'right',
	handleSubmit: () => { },
	handleCloseWithSave: () => { },
	handleCloseWithoutSave: () => { },
	handleButtonClick: () => { },
	showButtonOnClose: true,
	showButtonOnSubmit: true,
	modal: false
}

export default Feedback;
