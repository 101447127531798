import React, { useState, useEffect } from 'react';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from './styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Link, useHistory } from 'react-router-dom';
import { Box, IconButton } from '@material-ui/core';
import { useSelector } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import bsiLogo from '../../assets/bsiLogo.svg';
import sunAmericaLogo from '../../assets/sunAmericaLogo.svg';
import Cookies from 'js-cookie';
import CancelIcon from '@material-ui/icons/Cancel';
import { setPaymentOption } from '../../actions';
import { useDispatch } from 'react-redux'
import { insertActivityLog, getDocLanverDetails, hideAnnouncement,updateReleaseAnnouncements } from '../../actions'
import { getAppSource, getUserType, isAdmin, getMortgageLabel, getPointPrivateLabel } from '../../helpers'
import { LeavingSiteConfirmation } from '../../components';

export const SideBar = (props) => {
  
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useDispatch();
  const mytheme = useSelector(state => state.theme);
  const selectedAccountData = useSelector(state => state.selectedAccountData);
  const releaseAnnouncement = useSelector(state => state.releaseAnnouncement);

  const accInfo = useSelector(state => state.accountInfoData);
  const browserName = Cookies.get('browser');
  const { headerBackgrounColorCode, privateLabelLogoURL, privateLabelName } = mytheme;
  const privateLabelUrl = Cookies.get("privateLabelUrl")
  const subOrdinateStatus = Cookies.get("subOrdinate")
  const escrowStatus = Cookies.get("escrow")
  const promisePlan =  Cookies.get('promisePlan');
  const isMenuExtentionMaturity = Cookies.get('maturityExtensionMenu');
  const selectedAccount = useSelector(state => state.selectedAccount);
  const { windows } = props;
  const classes = useStyles();
  const themes = useSelector(state => state.theme);
  const { primaryColorCode, secondaryColorCode } = themes;
  const status = useSelector(state => state.announcementReducer);
  const isDocRequestAllowed = Cookies.get('isDocRequestAllowed');

  const [openMenus, setOpenMenues] = useState({
    paymentOptions: false,
    loanDetails: false,
    documentCenter: false,
    help: false,
    quickLinks: false,
    newLoanMenu: false
  });
  const [lanverDocLink, setLanverDocLink] = useState();
  const [taxInsuranceMenuStatus, setTaxInsuranceMenu] = React.useState(false);
  const [popupStatus, setPopup] = useState(false);
  const [link, setLink] = useState();
  const [activityRemark, setActivityRemark] = useState('');
  const [stayHereRemark , setStayHereActivityRemark] = useState('');

  useEffect(() => {
    // if (props?.mobileOpen) {
    //   const timer = setTimeout(() => {
    //     updateReleaseAnnouncement()
    //   }, 6000);
    //   return () => {
    //     clearTimeout(timer);
    //   }
    // }
  }, [])

  const hideReleaseAnnouncement = async () => {
    await dispatch(hideAnnouncement(false));
  }

  useEffect(() => {
    if (releaseAnnouncement?.length > 0) {
      var filterData = releaseAnnouncement?.filter(item => item.pageURL == 'EscrowManage')
      if (filterData?.length > 0) {
        setTaxInsuranceMenu(true)
      } else {
        setTaxInsuranceMenu(false)
      }
    } else {
      if (Cookies.get('releaseIds') !== undefined) {
      const releaseIds = JSON.parse(Cookies.get('releaseIds'))
      var releaseIdData = releaseIds?.filter(item => item.pageURL == 'EscrowManage')
      if (releaseIdData?.length > 0) {
        setTaxInsuranceMenu(true)
      } else {
        setTaxInsuranceMenu(false)
      }
    }
  }
  }, [releaseAnnouncement])
 
  const updateReleaseAnnouncement = async () => {
    if (status && !isAdmin() && Cookies.get('privateToken')) {
      const response = await updateReleaseAnnouncements();
      Cookies.remove('releaseIds');
      Cookies.remove('announcement');
      setTaxInsuranceMenu(false)
      hideReleaseAnnouncement()
    }
}
 
const toggleNewLoanDetails = () => {
  setOpenMenues({
    paymentOptions: false,
    loanDetails: false,
    documentCenter: false,
    help: false,
    quickLinks: false,
    newLoanMenu: !openMenus.newLoanMenu
  })
}

  const toggleLoanDetails = () => {
    setOpenMenues({
      paymentOptions: false,
      loanDetails: !openMenus.loanDetails,
      documentCenter: false,
      help: false,
      quickLinks: false,
    })
  }

  const toggleDocuments = () => {
    setOpenMenues({
      paymentOptions: false,
      loanDetails: false,
      documentCenter: !openMenus.documentCenter,
      help: false,
      quickLinks: false,
    })
  }

  const toggleHelp = () => {
    setOpenMenues({
      paymentOptions: false,
      loanDetails: false,
      documentCenter: false,
      help: !openMenus.help,
      quickLinks: false,
    })
  }

  const toggleQuickLinks = () => {
    setOpenMenues({
      paymentOptions: false,
      loanDetails: false,
      documentCenter: false,
      help: false,
      quickLinks: !openMenus.quickLinks,
    })
  }

  const togglePaymentOptions = () => {
    setOpenMenues({
      paymentOptions: !openMenus.paymentOptions,
      loanDetails: false,
      documentCenter: false,
      help: false,
      quickLinks: false,
    })
  }

  const navigateToPaymentPage = (option) => {
    props.handleDrawerToggle()
    dispatch(setPaymentOption(option));
  }
 
  const sendActivityData = async () => {
    const text = "User clicked on Refinance Your Loan option under Loan Details Menu.";
    const remark =  accInfo.refinanceURL.includes('gomortgage') ? 'GoMortgage - ' + text : 
    accInfo.refinanceURL.includes('alliedmg') ? 'AlliedMortgage - ' + text :  `User clicked on Refinance Your Loan submenu under Loan Details Menu of MLW and redirected to refinance tile (${accInfo?.refiCompany})`
    
    const data = {
      "accountNumber": accInfo.accountNumber,
      "activityCode": "UAL192",
      "activityStatus": "Success",
      "action": accInfo?.refiCompany,
      "controller": "Dashboard",
      "apiName": "GetAccountInfo",
      "activityRemark": remark,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    await insertActivityLog(data);
  }

  const container = windows !== undefined ? () => windows().document.body : undefined;
  const route = () => {
    sendActivityData();
    if (accInfo.refinanceURL !== null && accInfo.refinanceURL !== " ") {
      if (accInfo.refinanceURL.toLowerCase().includes('RefinanceAdvertisement'.toLowerCase())) {
        history.push(accInfo.refinanceURL + `?refiCompany=${accInfo?.refiCompany}`)
      } else {
        window.open(accInfo.refinanceURL, '_blank')
      }
    }
  }

  const routeToDocumentCenter = async () => {
    props.handleDrawerToggle();
    const res = await getDocLanverDetails(selectedAccount);
    if (res) {
      var newWin = window.open(res.url,"_blank");             
      if(!newWin || newWin.closed || typeof newWin.closed=='undefined') 
        { 
            alert('A popup blocker is preventing the application from opening the page. To allow pop-ups, go to Safari app and choose Safari>Preferences, then click websites and allow popup for your website.')
        }
      }
  }
 
  const openPopup = (link, remark, stayHereRemark) => {
    setLink(link)
    // sendActivityLog(remark)
    setActivityRemark(remark)
    setStayHereActivityRemark(stayHereRemark)
    setPopup(true)
  }
 
  const sendActivityLog = async (remark) => {
   
    const data = {
      "accountNumber": selectedAccount,
      "activityCode": "UAL202",
      "activityStatus": "Success",
      "action": accInfo?.refiCompany,
      "controller": "Dashboard",
      "apiName": "GetAccountInfo",
      "activityRemark": remark,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    await insertActivityLog(data);
  }

  const handleClosePopup = () => {
    setActivityRemark('')
    sendActivityLog(stayHereRemark)
    setStayHereActivityRemark('')
    setPopup(false)
  }

  const handleOk = () => {
    setPopup(false)
    sendActivityLog(activityRemark)
    window.open(link, '_blank')
  }


  return (
    <>
    <nav className={classes.drawer} aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={props.mobileOpen}
          onClose={props.handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <Box className={classes.appBar} style={{ backgroundColor: headerBackgrounColorCode }}>
            <Link to="/" className={classes.logoContainer}>
              {/* {privateLabelUrl.toUpperCase() === "BSI" ? <img className={classes.logoStyle} src={privateLabelLogoURL} alt={privateLabelName} /> : <img className={classes.logoStyle} src={sunAmericaLogo} alt="logo" />} */}
              <img className={classes.logoStyle} src={privateLabelLogoURL} alt={privateLabelName} />
            </Link>
            <IconButton onClick={() => props.handleDrawerToggle()}><CancelIcon style={{ fill: '#000' }} /></IconButton>
          </Box>
          {!props.login && <Box>
            <Link to='/AboutUs' className={classes.link}>
              <Box className={classes.linkContainer}>
                <p className={classes.linkText}>About Us</p>
              </Box>
            </Link>
            <Link to='/ThirdPartyRequest' className={classes.link}>
              <Box className={classes.linkContainer}>
                <p className={classes.linkText}>Third party Requester</p>
              </Box>
            </Link>
            <Link to='/HelpDescription/FAQs' className={classes.link}>
              <Box className={classes.linkContainer}>
                <p className={classes.linkText}>FAQs</p>
              </Box>
            </Link>
            <Link to='/ContactUs' className={classes.link}>
              <Box className={classes.linkContainer}>
                <p className={classes.linkText}>Contact Us</p>
              </Box>
            </Link>
          </Box>}
          {props.login &&
            <Box>
              <Link to={Cookies.get('privateToken') ? '/Dashboard' : '/'} className={classes.link}>
                <Box className={classes.linkContainer}>
                  <p className={classes.linkText}>Home</p>
                </Box>
              </Link>
              { !getPointPrivateLabel() &&
              <Box className={classes.link} onClick={() => togglePaymentOptions()}>
                <Box className={classes.linkContainer}>
                  <p className={classes.linkText}>Payments</p>
                  {openMenus.paymentOptions ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Box>
                {
                  openMenus.paymentOptions &&
                  <Box className={classes.subMenuContainer}>
                    <Box className={classes.subMenu}>
                      <Link onClick={() => navigateToPaymentPage("FAQ")} className={classes.subMenuLink} to='/PaymentOption'>Payment Options</Link>
                      <Link onClick={() => navigateToPaymentPage("ONETIMEPAYMENT")} className={classes.subMenuLink} to='/PaymentOption'>One-Time Payment</Link>
                      <Link onClick={() => navigateToPaymentPage("MANAGEAUTOPAY")} className={classes.subMenuLink} to='/PaymentOption'>Set Up Autopay</Link>
                      <Link onClick={props.handleDrawerToggle} className={classes.subMenuLink} to='/ManageBankAccount'>My Bank Accounts</Link>
                      <Link onClick={props.handleDrawerToggle} className={classes.subMenuLink} to='/TransactionHistory'>Transaction History</Link>
                      <Link onClick={props.handleDrawerToggle} className={classes.subMenuLink} to='/FinancialHardship'>Financial Hardship</Link>
                      {promisePlan == 'true' && 
                      <Link onClick={props.handleDrawerToggle} className={classes.subMenuLink} to='/PromiseToPay'>Promise Plan</Link> }
                    </Box>
                  </Box>
                }
              </Box>
             }
              { ((Cookies.get('refinanceFlag') == 'true') && !getPointPrivateLabel()) &&
              <Box className={classes.link} onClick={() => toggleNewLoanDetails()}>
                <Box className={classes.linkContainer}>
                
                  <div className={classes.badge} style={{backgroundColor: primaryColorCode}}>new</div>
                    
                  <p className={classes.linkText}>Get A new loan</p>
                  {openMenus.newLoanMenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Box>
                {
                  openMenus.newLoanMenu &&
                  <Box className={classes.subMenuContainer}>
                    <Box className={classes.subMenu}>
                      <Box onClick={() => openPopup("https://bsifinancialrefinanceonline.mymortgage-online.com/", 'User clicked on  Get a New Loan>Refinance Submenu with “Continue” button on MLW', 'User clicked on  Get a New Loan>Refinance Submenu with “Stay Here” button on MLW')} className={classes.subMenuLink}>Refinance</Box>
                      <Box onClick={() => openPopup("https://bsifinancialrefinanceonline.mymortgage-online.com/", 'User clicked on  Get a New Loan>Equity Loan Submenu with “Continue” button on MLW', 'User clicked on  Get a New Loan>Equity Loan Submenu with “Stay Here” button on MLW')} className={classes.subMenuLink}>Equity Loan</Box>
                      <Box onClick={() => openPopup("https://bsifinancialrefinanceonline.mymortgage-online.com/", 'User clicked on  Get a New Loan>Purchase Submenu with “Continue” button on MLW', 'User clicked on  Get a New Loan>Purchase  Submenu with “Stay Here” button on MLW')} className={classes.subMenuLink}>Purchase</Box>
                      <Box onClick={() => openPopup("'https://bsifinancialrefinanceonline.mymortgage-online.com/RATES.html", 'User clicked on  Get a New Loan>Personal Quote Submenu with “Continue” button on MLW', 'User clicked on  Get a New Loan>Personal Quote Submenu with “Stay Here” button on MLW')} className={classes.subMenuLink}>Personal Quote</Box>
                      <Box onClick={() => openPopup("https://bsifinancialrefinanceonline.mymortgage-online.com/Calculators.html", 'User clicked on  Get a New Loan>Calculators Submenu with “Continue” button on MLW', 'User clicked on  Get a New Loan>Calculators Submenu with “Stay Here” button on MLW')} className={classes.subMenuLink}>Calculators</Box>
                      <Box onClick={() => openPopup("'https://bsifinancialrefinanceonline.mymortgage-online.com/index.html", 'User clicked on  Get a New Loan>Learn More Submenu with “Continue” button on MLW', 'User clicked on  Get a New Loan>Learn More Submenu with “Stay Here” button on MLW')} className={classes.subMenuLink}>Learn More</Box>
                    </Box>
                  </Box>
                }
              </Box>
              }
              { !getPointPrivateLabel() &&
              <Box className={classes.link} onClick={() => toggleLoanDetails()}>
                <Box className={classes.linkContainer}>
                { ((taxInsuranceMenuStatus && status) || isMenuExtentionMaturity=='true') &&
                    <div className={classes.badge} style={{backgroundColor: primaryColorCode}}>new</div>
                    }
                  <p className={classes.linkText}>LOAN DETAILS</p>
                  {openMenus.loanDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Box>
                {
                  openMenus.loanDetails &&
                  <Box className={classes.subMenuContainer}>
                    <Box className={classes.subMenu}>
                      <Link onClick={props.handleDrawerToggle} className={classes.subMenuLink} to='/LoanInfo'>Loan Information</Link>
                    
                      {escrowStatus == 'true' ?  <Link onClick={props.handleDrawerToggle} className={classes.subMenuLink} to='/EscrowInfo'>Taxes & Insurance Information  {taxInsuranceMenuStatus && status &&
                    <div className={classes.menuBadge} style={{backgroundColor: primaryColorCode}}>new</div>
                    }</Link> : null}
                                        
                      {accInfo && accInfo.isRefinance && (privateLabelUrl?.toUpperCase() === "BSI") && <Link className={classes.subMenuLink} onClick={() => route()}>Refinance Your Loan</Link>}
                      <Link onClick={props.handleDrawerToggle} className={classes.subMenuLink} to='/vom'>Request Mortgage Verification</Link>
                      <Link onClick={props.handleDrawerToggle} className={classes.subMenuLink} to='/PayoffRequest'>Request Payoff</Link>
                      {subOrdinateStatus == 'true' ?
                      <Link onClick={props.handleDrawerToggle} className={classes.subMenuLink} to='/RequestSubordination'>Request Subordination</Link> : null}
                      <Link onClick={props.handleDrawerToggle} className={classes.subMenuLink} to='/ManageThirdPartyRequest'>Manage Third-Party Requests</Link>
                      <Link onClick={props.handleDrawerToggle} className={classes.subMenuLink} to='/WorkoutStatus'>Workout Status</Link>
                      {isMenuExtentionMaturity=='true' && <Link onClick={props.handleDrawerToggle} className={classes.subMenuLink} to='/MaturityExtension'>Maturity Extension Application 
                      <div className={classes.menuBadge} style={{backgroundColor: primaryColorCode}}>new</div>
                    </Link>}   
                    </Box>
                  </Box>
                }
              </Box>
              }
              { getPointPrivateLabel() &&
              <Box className={classes.link} >
                <Box className={classes.linkContainer} onClick={() => toggleLoanDetails()}>
                  <p className={classes.linkText}>CONTRACT DETAILS</p>
                  {openMenus.loanDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Box>
                {
                  openMenus.loanDetails &&
                  <Box className={classes.subMenuContainer}>
                    <Box className={classes.subMenu}>
                      <Link onClick={props.handleDrawerToggle} className={classes.subMenuLink} to='/LoanInfo'>Contract Information</Link>
                      <Link onClick={props.handleDrawerToggle} className={classes.subMenuLink} to='/vom'>Request {getMortgageLabel()} Verification</Link>
                      <Link onClick={props.handleDrawerToggle} className={classes.subMenuLink} to='/PayoffRequest'>Request Payoff</Link>
                      <Link onClick={props.handleDrawerToggle} className={classes.subMenuLink} to='/ManageThirdPartyRequest'>Manage Third-Party Requests</Link>
                    </Box>
                  </Box>
                }
              </Box>
              }
               { !getPointPrivateLabel() &&
               <>
              {browserName.includes('Safari ') ?
               <Box className={classes.link} onClick={() => toggleDocuments()}>
               <Box className={classes.linkContainer}>
              
                 <p className={classes.linkText}> {(privateLabelUrl.toUpperCase() === "BSI") ? 'Documents & Forms' : 'Documents'}</p>
                 {openMenus.documentCenter ? <ExpandLessIcon /> : <ExpandMoreIcon />}
               </Box>
               { openMenus.documentCenter &&
                  <Box className={classes.subMenuContainer}>
                    <Box className={classes.subMenu}>
                      <Box onClick={routeToDocumentCenter} className={classes.subMenuLink} >Document Center</Box>
                      { (privateLabelUrl.toUpperCase() === "BSI") &&
                        <Link onClick={props.handleDrawerToggle} className={classes.subMenuLink} to='/formCenter'>Form Center</Link>
                      }
                      { ((privateLabelUrl.toLowerCase() !== "aafmaa") && (isDocRequestAllowed == 'true')) &&
                      <Link onClick={props.handleDrawerToggle} className={classes.subMenuLink} to='/DocumentRequest'>Document Requests</Link>
                      }
                    </Box>
                  </Box>}
             </Box> :
              <Box  className={classes.link} onClick={() => toggleDocuments()}>
                <Box className={classes.linkContainer}>
                
                  <p className={classes.linkText}> {(privateLabelUrl.toUpperCase() === "BSI") ? 'Documents & Forms' : 'Documents'}</p>
                  {openMenus.documentCenter ? <ExpandLessIcon /> : <ExpandMoreIcon />}

                </Box>
                { openMenus.documentCenter &&
                  <Box className={classes.subMenuContainer}>
                    <Box className={classes.subMenu}>
                      <Link onClick={props.handleDrawerToggle} className={classes.subMenuLink} to='/DocumentCenter'>Document Center</Link>
                      { (privateLabelUrl.toUpperCase() === "BSI") &&
                      <Link onClick={props.handleDrawerToggle} className={classes.subMenuLink} to='/FormCenter'>Form Center</Link>
                     }
                      { ((privateLabelUrl.toLowerCase() !== "aafmaa") && (isDocRequestAllowed == 'true')) &&
                      <Link onClick={props.handleDrawerToggle} className={classes.subMenuLink} to='/DocumentRequest'>Document Requests</Link>
                      }
                    </Box>
                  </Box>}
              </Box>
             }
             </> }
             { getPointPrivateLabel() &&
               <>
              {browserName.includes('Safari ') ?
               <Box className={classes.link} onClick={() => toggleDocuments()}>
               <Box className={classes.linkContainer} onClick={routeToDocumentCenter}>
                 <p className={classes.linkText}>DOCUMENTS</p>
               </Box>
             </Box> :
              <Box  className={classes.link} onClick={() => toggleDocuments()}>
                <Box className={classes.linkContainer}>
                
                  <Link className={classes.linkText} to='/DocumentCenter'>DOCUMENTS</Link>
                </Box>
              </Box>
             }
             </> }
             { !getPointPrivateLabel() &&
              <Box className={classes.link} onClick={() => toggleHelp()}>
                <Box className={classes.linkContainer}>
                  <p className={classes.linkText}>SUPPORT</p>
                  {openMenus.help ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Box>
                {
                  openMenus.help &&
                  <Box className={classes.subMenuContainer}>
                    <Box className={classes.subMenu}>
                      <Link onClick={props.handleDrawerToggle} className={classes.subMenuLink} to='/HelpLanding'>Help</Link>
                      <Link onClick={props.handleDrawerToggle} className={classes.subMenuLink} to='/HelpDescription/FAQs'>FAQs</Link>
                      <Link onClick={props.handleDrawerToggle} className={classes.subMenuLink} to='/FeesSchedule'>Fees Schedule</Link>
                    </Box>
                  </Box>
                }
              </Box>
            }
              { getPointPrivateLabel() &&
              <Box className={classes.link} onClick={() => toggleHelp()}>
                <Box className={classes.linkContainer}>
                  <p className={classes.linkText}>SUPPORT</p>
                  {openMenus.help ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Box>
                {
                  openMenus.help &&
                  <Box className={classes.subMenuContainer}>
                    <Box className={classes.subMenu}>
                      <Link onClick={props.handleDrawerToggle} className={classes.subMenuLink} to='/HelpLanding'>Help</Link>
                    </Box>
                  </Box>
                }
              </Box>
            }
              {/* <Box className={classes.link} onClick={() => toggleQuickLinks()}>
                <Box className={classes.linkContainer}>
                  <p className={classes.linkText}>QUICK LINKS</p>
                  {openMenus.quickLinks ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Box>
                {
                  openMenus.quickLinks &&
                  <Box className={classes.subMenuContainer}>
                    <Box className={classes.subMenu}>
                      <Link className={classes.subMenuLink} to='/'>Download 1098 form</Link>
                      <Link className={classes.subMenuLink} to='/'>Verification of Mortgage</Link>
                      <Link className={classes.subMenuLink} to='/'>Fees</Link>
                      <Link className={classes.subMenuLink} to='/'>Payoff</Link>
                      <Link className={classes.subMenuLink} to='/'>Help Topics</Link>
                    </Box>
                  </Box>
                }
              </Box> */}
            </Box>
          }
        </Drawer>
      </Hidden>
    </nav>
    <LeavingSiteConfirmation 
        open={popupStatus}
        handleClose={handleClosePopup}
        handleOk={handleOk}
      />
    </>
  )
}



