import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
  Box, Typography, Button, Tooltip,
  CircularProgress, InputAdornment, TextField, ClickAwayListener
} from "@material-ui/core";
import { useStyles, useStylesBootstrap } from './styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import CancelIcon from '@material-ui/icons/Cancel';
import InfoIcon from '@material-ui/icons/Info';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Cookies from 'js-cookie';
import ReactToPrint from 'react-to-print'
import PrintIcon from '@material-ui/icons/Print';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ErrorIcon from '@material-ui/icons/Error';

import { Calender } from "../SharedComponent/Calender";
import TermAndConditions from '../TermAndConditions';
import { SuccessMsgAlert } from './SuccessMsgAlert';
import { AddEditAccount } from '../SharedComponent/AddBankAccAlert';
import { maskAccount, scrollTop, checkDigitWithDecimal, renderDefaultMsg, getUserType, getAppSource, maskAccountNo } from '../../helpers';
import moment from 'moment';
import { OtpMultiLoan } from '../../components';

import {
  getOneTimeACHPaymentDetails, getBankAccountDetail,
  getContent, getDraftOneTimePayment, setPaymentOption, getRecurringACHInfo
} from '../../actions';

import { decrypt } from '../../helpers/encrypt';
import { AchAdvertisementAlert } from "../AchAdvertisementAlert";
import { DeleteConfirmation } from '../ManageBankAccount/DeleteConfirmationOnAccount/DeleteConfirmation';
import { AdditionalPaymentAlert } from "../AdditionalPaymentAlert";
import { useHistory } from 'react-router-dom';
import { debounce } from "debounce";
import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { ReviewPayment } from '../ReviewPayment';
import spinner from '../../assets/spinner.gif';
import { BENEFIT_OF_PAYING_MORE, PROCESSING_FEE } from '../../helpers/constants';


const LightToolTip = withStyles((theme) => ({
  arrow: {
    color: '#235A86',
    // backgroundColor: '#ffffff',
  },
  tooltip: {
    backgroundColor: '#235A86',
    color: '#fffff',
    fontFamily: "Montserrat",
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    padding: "16px",
    fontSize: 14,
    maxWidth: 400,
    fontWeight: 500,
    borderRadius: 8,
    // border: "1px solid #4D4D4D"
  },
}))(Tooltip);
import { insertActivityLog } from '../../actions';

function BlueOnWhiteTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} placement="bottom-end" />;
}

export const OneTimePayment = (props) => {

  const classes = useStyles();
  const selectedAccount = useSelector(state => state.selectedAccount);
  const dispatch = useDispatch();
  const [showMultiLoanBtn, setShowMultiLoanBtn] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const componentRef = useRef();
  const history = useHistory();
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.down('sm'));

  const [addPay, setAddPay] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [radioValue, setRadioValue] = useState('');
  const [open, setOpen] = React.useState(false);
  const [draftOTPError, setDraftOTPError] = useState('');
  const [draftOTPRes, setDraftOTPRes] = useState([]);
  const [otpApiData, setOtpApiData] = useState(null);
  const [otpApiErrorData, setOtpApiErrorData] = useState('');
  const [bankName, setBankName] = useState([]);
  const [totalPayment, setTotalPayment] = useState('');
  const [makePayLoading, setMakePayLoading] = useState(false);
  const [selectedAccountNo, setSelectedAccountNo] = useState('');
  const [selectedBank, setSelectedBank] = useState('');
  const [updatedProcessFee, setUpdatedProcessFee] = useState('');
  const [tncSummary, setTncSummary] = useState('');
  const [bankNameError, setBankNameError] = useState('');
  const [selectedBankError, setSelectedBankError] = useState('');
  const selectedAccountData = useSelector(state => state.selectedAccountData);
  const [newAddedAccount, setNewAddedAccount] = useState(null);
  const [loanType, setLoanType] = useState('S');
  const [isOpen, setOpenStatus] = useState(false);
  const [lstRecStatus, setlstRecurStatus] = useState('');
  const [additionalPayAmount, setAdditionalPayAmount] = useState({
    principalAmount: 0,
    escrowAmount: 0
  });
  const [saveAmountData, setSaveAmountData] = useState({
    processingFee: 0,
    principalAmount: 0,
    escrowAmount: 0,
    forbearanceApplicationFee: 0,
  });
  const [otpPaymentData, setOtpPaymentData] = useState({
    processingFee: 0,
    principalAmount: 0,
    escrowAmount: 0,
    bankAccount: '',
  });
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [disablePaymentButton, setPaymentBtnDisable]= useState(false);
  const [error, setError] = useState('');
  const [reviewPopupStatus, setReviewPopupStatus] = useState(false);
  const [paymentDate, setDate] = useState('');
  const [openMonthyPaymentTooltip, setOpenMonthyPaymentTooltip] = useState(false);
  const [openOutstandingBalanceTooltip, setOpenOutstandingBalanceTooltip] = useState(false);
  const [openCustomBalanceTooltip, setOpenCustomBalanceTooltip] = useState(false);
  const [openPayingMoreTooltip, setOpenPayingMoreTooltip] = useState(false);
  const [openProcessingFeeTooltip, setOpenProcessingFeeTooltip] = useState(false);

  const handleMonthyPaymentTooltipClose = () => {
    setOpenMonthyPaymentTooltip(false);
  };

  const handleMonthyPaymentTooltipOpen = () => {
    setOpenMonthyPaymentTooltip(true);
  };
 
  const handleOutstandingBalanceTooltipClose = () => {
    setOpenOutstandingBalanceTooltip(false);
  };

  const handleOutstandingBalanceTooltipOpen = () => {
    setOpenOutstandingBalanceTooltip(true);
  };

  const handleCustomBalanceTooltipClose = () => {
    setOpenCustomBalanceTooltip(false);
  };

  const handleCustomBalanceTooltipOpen = () => {
    setOpenCustomBalanceTooltip(true);
  };

  const handlePayingMoreTooltipClose = () => {
    setOpenPayingMoreTooltip(false);
  };

  const handlePayingMoreTooltipOpen = () => {
    setOpenPayingMoreTooltip(true);
  };

  const handleProcessingFeeTooltipClose = () => {
    setOpenProcessingFeeTooltip(false);
  };

  const handleProcessingFeeTooltipOpen = () => {
    setOpenProcessingFeeTooltip(true);
  };

  const getAccountsData = async () => {
    const res = await Cookies.get('totalAccounts');
    if (res) {
      Number(res) > 1 ? setShowMultiLoanBtn(true) : setShowMultiLoanBtn(false)
    }
  }

  const toggleOpen = () => {
    setOpen(!open);
  }

  const toggleOpenSuccess = () => {
    setOpen(!open);
    props.click('FAQ');
  }

  const changeAddRemovePay = () => {
    setAddPay(!addPay);
    setAdditionalPayAmount({
      principalAmount: 0,
      escrowAmount: 0
    })
    setOtpPaymentData({
      ...otpPaymentData,
      principalAmount: 0,
      escrowAmount: 0
    });
    setSaveAmountData({
      ...saveAmountData,
      principalAmount: 0,
      escrowAmount: 0,
    });
  }
  const ToggleAccountPopup = (saved) => {
    if (saved === 'Saved') {
      getData();
    }
    setShowPopup(!showPopup);
  }

  const handleRadioChange = (event, val) => {
    if (event.target.value === 'onlyForbearance') {
      setOtpPaymentData({
        ...otpPaymentData,
        ['paymentType']: event.target.value,
        ['paymentTypeValue']: val,
        processingFee: 0,
        forbearanceApplicationFee: val,
        principalAmount: 0,
        escrowAmount: 0,
        graysDays: '',
        propertyState: '',
        investorCode: '',
        bankProfileID: 0,
      });
      setAdditionalPayAmount({
        principalAmount: 0,
        escrowAmount: 0
      })
    } else {
      setOtpPaymentData({
        ...otpPaymentData,
        ['paymentType']: event.target.value,
        ['paymentTypeValue']: val,
        processingFee: otpPaymentData.processingFee,   //saveAmountData.processingFee   changed
        principalAmount: saveAmountData.principalAmount,
        escrowAmount: saveAmountData.escrowAmount,
        forbearanceApplicationFee: saveAmountData.forbearanceApplicationFee,
      });
    }
    setRadioValue(event.target.value);
  };

  const handleCustomAmount = (event) => {
    if (checkDigitWithDecimal(event.target.value)) {
      setOtpPaymentData({
        ...otpPaymentData,
        paymentTypeValue: event.target.value,
      })
    }
  }


  const handleAdditionalPayChange = (event, ke) => {
    if (checkDigitWithDecimal(event.target.value)) {
      setDraftOTPError('');
      setAdditionalPayAmount({
        ...additionalPayAmount,
        [ke]: event.target.value,
      });
      setOtpPaymentData({
        ...otpPaymentData, [ke]: event.target.value
      });
      setSaveAmountData({
        ...saveAmountData,
        [ke]: event.target.value
      })
    }
  }

  useEffect(() => {
    getAccountsData()
  }, [])
  /**When user redirect from multiloan late 
   * fee paymemt warning popup then
   *  need to open mutliloan widgets by default */
  useEffect(()=>{
    if(props.isFromWarningMultiL){
      setLoanType('M');
    }
  },[props.isFromWarningMultiL])

  useEffect(() => {
    if (otpApiData && otpPaymentData) {
      setPaymentTotal();
    }
  }, [otpPaymentData, otpApiData])

  const getContentData = async () => {
    const res = await dispatch(getContent('PC016'));
    if (res && res.length !== 0) {
      setTncSummary(res[0].contentValue)
    }
  }

  const setPaymentTotal = () => {
    if (otpPaymentData.paymentType === 'onlyForbearance') {
      setTotalPayment(otpPaymentData.forbearanceApplicationFee)
    } else {
      setTotalPayment((otpPaymentData.paymentTypeValue ? Number(otpPaymentData.paymentTypeValue) : 0) + otpPaymentData.processingFee +
        Number(otpPaymentData.principalAmount) + Number(otpPaymentData.escrowAmount) +
        otpPaymentData.forbearanceApplicationFee)
    }
  }

  const updateProcessFee = (fee, date, processNote, delinquentDays, isDisplay) => {
    const processingNote = processNote?.replace("[PaymentAmount]", otpPaymentData.paymentTypeValue);
    // const processingNote = pNote[0] + otpPaymentData.paymentTypeValue;
    setUpdatedProcessFee(fee);
    setOtpPaymentData({
      ...otpPaymentData,
      processingFee: fee,
      paymentDate: date,
      processingFeeNote: processingNote,

    })
    setOtpApiData({
      ...otpApiData,
      delinquentDays: delinquentDays,
      processingFeeIsDisplay: isDisplay
    })
  }
 
  useEffect(() => {
    getData();
    getContentData();
    // scrollTop();
  }, [])

  useEffect(() => {
    selctBankAcc();
  }, [bankName]);

  const selctBankAcc = () => {
    if (bankName && bankName.length) {
      const selectBankAccDetail = bankName.find(bankAccount => bankAccount.bankAccountNumber === newAddedAccount);
      if (selectBankAccDetail) {
        changeSelectedBank(selectBankAccDetail);
        setNewAddedAccount(null);
      } else {
        const selectBankAccPreff = bankName.find(bankAccount => bankAccount.isPreferredAcc === true);
        selectBankAccPreff ? changeSelectedBank(selectBankAccPreff) : changeSelectedBank(bankName[0])
      }
    }
  }


  const newAddedBankAccount = (newAddedBank) => {
    setNewAddedAccount(newAddedBank);
  }

  useEffect(() => {
    if (selectedAccount) {
      // scrollTop();
       getOTPDetails();
    }
    selctBankAcc();
  }, [selectedAccount]);

  const getOTPDetails = async () => {
    setDataLoading(true);
    const propertyState = selectedAccountData.propertyState;
    const bsi_InvestorCode = selectedAccountData.bsi_InvestorCode;
    const response = await getOneTimeACHPaymentDetails(selectedAccount, propertyState);
    if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode === '0') {
      setOtpApiData(response);
     
      setOtpPaymentData({
        ...otpPaymentData,
        paymentType: response.delinquentDays > 1 ? 'Outstanding' : 'Regular',
        paymentTypeValue: response.delinquentDays > 1 ? response.totalAmountDue : response.monthlyAmountDue,
        paymentDate: response.paymentDueDate,
        latePaymentDate: response.latePaymentDate,
        processingFee: response.processingFee.processingFee,
        forbearanceApplicationFee: response.forbearanceApplicationFee,
        graysDays: response.graceDays,
        propertyState: propertyState,
        investorCode: bsi_InvestorCode,
      });
      setSaveAmountData({
        ...saveAmountData,
        processingFee: response.processingFee.processingFee,
        forbearanceApplicationFee: response.forbearanceApplicationFee,
      })
      setOtpApiErrorData('');
      setDataLoading(false);
      if (props.data == null) {
        getRecurringData()
      } else {
        setOpenStatus(props.data.isRecurringAllowed)
        setlstRecurStatus(props.data.lstRecurACH)
      }
    } else if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode !== '0') {
      setOtpApiData(response);
      setOtpApiErrorData(response.lstResponseStatus[0].statusDesc);
      setDataLoading(false);
    } else {
      setOtpApiErrorData(renderDefaultMsg('400'));
      setDataLoading(false);
    }
  }
   
  const getData = async () => {
    const data = {
      "accountNumber": "",
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    };
    const response = await getBankAccountDetail(data);
    if (response && response.lstResponseStatus && response.lstResponseStatus.length && response.lstResponseStatus[0].statusCode === '0') {
      setBankNameError('');
      setBankName(response.lstBEBankAccountDetails);
    } else if (response && response.lstResponseStatus && response.lstResponseStatus.length && response.lstResponseStatus[0].statusCode !== '0') {
      setBankNameError(response.lstResponseStatus[0].statusDesc);
    } else {
      setBankNameError(renderDefaultMsg('400'));
    }
  };

  const changeSelectedBank = (data) => {
    setSelectedAccountNo(data.bankProfileID);
    setSelectedBank(data);
  }

  const validateBankDetails = () => {
    if (selectedBank === '' || selectedAccountNo === '') {
      return true;
    }
    return false;
  }
 
  const sendActivityData = async () => {
    const data = {
      "accountNumber": selectedAccount,
      "activityCode": "UAL082",
      "activityStatus": "Success",
      "action": 'DraftOneTimePayment',
      "controller": 'Payment',
      "apiName": "Payment/DraftOneTimePayment",
      "activityRemark": 'UI- Borrower Clicked on Make payment Button',
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    await insertActivityLog(data);
  }

  const draftOneTimePayment = async () => {
    setError('')
    setDate('')
    setDraftOTPError('');
    setMakePayLoading(true);
    if (validateBankDetails()) {
      setMakePayLoading(false);
      setSelectedBankError('Please add a bank account.')
      setPaymentBtnDisable(false)
    } else {
      window.scrollTo({ top: 480, left: 0})
      setSelectedBankError('');
      setDataLoading(true)
      const data = {
        "accountNumber": selectedAccount,
        "abaRoutingNumber": selectedBank.abaRoutingNumber,
        "bankCity": selectedBank.bankCity && selectedBank.bankCity.length ? selectedBank.bankCity : 'NA',
        "bankName": selectedBank.bankName && selectedBank.bankName.length ? decrypt(selectedBank.bankName) : 'NA',
        "bankState": selectedBank.bankState && selectedBank.bankState.length ? selectedBank.bankState : 'NA',
        "bankAccountNumber": selectedBank.bankAccountNumber,
        "accountType": selectedBank.accountType,
        "borrowerType": selectedBank.borrowerType,
        "borrowerName": selectedBank.borrowerName,
        "paymentDate": moment(otpPaymentData.paymentDate).format("L"),
        "paymentAmount": otpPaymentData.paymentTypeValue,
        "additionalPrincipal": otpPaymentData.principalAmount !== '' ? otpPaymentData.principalAmount : 0,
        "additionalEscrow": otpPaymentData.escrowAmount !== '' ? otpPaymentData.escrowAmount : 0,
        "confirmationNumber": 0,
        "achDraftStatus": "",
        "graysDays": otpPaymentData.graysDays,
        "propertyState": otpPaymentData.propertyState,
        "investorCode": otpPaymentData.investorCode,
        "bankProfileID": selectedBank.bankProfileID,
        "draftFee": otpPaymentData.processingFee,
        "privateLabelUrl": Cookies.get("privateLabelUrl"),
        "ipAddress": Cookies.get('publicIp'),
        "browser": Cookies.get("browser"),
        "appSource": getAppSource(),
        "userType": getUserType(),
        "userName": "",
        "delinquentDays": otpApiData.delinquentDays,
        "loanStatus": otpApiData.loanStatus,
        "processingFeeNote": otpPaymentData.processingFeeNote,
        "IsForbearanceApplicationFeePayed": otpApiData.forbearanceApplicationFee === 0 ? false : true,
        "isCustomAmount": otpApiData.isCustomAmount
      };
     
      const response = await getDraftOneTimePayment(data);
      if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode === '0') {
        setMakePayLoading(false);
        setDraftOTPRes(response);
        setDraftOTPError('');
        setError('')
        toggleOpen();
        setPaymentBtnDisable(false)
        sendActivityData()
        setDataLoading(false)
      } else if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode !== '0') {
        setMakePayLoading(false);
        setDataLoading(false)
        if (response.lstResponseStatus[0].statusDesc?.toUpperCase()?.includes("DUPLICATE DRAFT")) {
          setError(response.lstResponseStatus[0].statusDesc)
          setDate(otpPaymentData.paymentDate)
          setDraftOTPError('')
          if (mediaQuery) {
            window.scrollTo({ top: 1000, left: 0, behavior: 'smooth' });
          }
        } else {
          setDraftOTPError(response.lstResponseStatus[0].statusDesc);
          setError('')
        }
        setPaymentBtnDisable(false)
      } else {
        setDataLoading(false)
        setMakePayLoading(false);
        setDraftOTPError(renderDefaultMsg('400'));
        setError('')
        setPaymentBtnDisable(false)
      }
    }

  }
 
  const addedNewAccount = (newAcc) => {
    setSelectedBankError('');
    bankName.push(newAcc);
    changeSelectedBank(newAcc);
  }

  const closeAlert = () => {
    setOpenStatus(false)
  }

  const handleCloseAlert = () => {
    setAlertOpen(false)
    setReviewPopupStatus(true)
  }
  
  const callDebounce = () => {
    debounce(handleMakePayment(), 500);         
  }
  
  const handleCloseReviewPopup = () => {
    setReviewPopupStatus(false)
  }

  const handleContinueReviewPopup = () => {
    setReviewPopupStatus(false)
    draftOneTimePayment()
  }

  const handleMakePayment = () => {
    // setPaymentBtnDisable(true)
    if (validateBankDetails()) {
      setSelectedBankError('Please add a bank account.') 
    } else {
      if (totalPayment < otpApiData.maxPaymentAllowed && otpPaymentData.principalAmount < otpApiData.unpaidPrincipalBalance && otpPaymentData.principalAmount > (otpApiData.unpaidPrincipalBalance - 5000))
      {
        setAlertOpen(true)
      } else {
        setReviewPopupStatus(true)
      }
    }   
  }

  const route = () => {
    closeAlert()
    props.routeToAutoPay()
  }

  const getRecurringData = async () => {
    const res = await getRecurringACHInfo(selectedAccount);
    if (res && res.lstResponseStatus[0].statusCode === '0') {
      setOpenStatus(res.isRecurringAllowed);
      setlstRecurStatus(res.lstRecurACH);
    } 
  }
  
  const routeToRequestPayOff = () => {
    history.push('/PayoffRequest');
  }

  const handleClose = () => {
    setAlertOpen(false);
  }

  const routeToSetUpAutoPay = () => {
    props.routeToAutoPay()
  }

  let allCoBorrwerNameData = '';
  if (bankName && bankName.length) {
    allCoBorrwerNameData = bankName.map((bank, index) => {
      return (
        <MenuItem value={bank.bankProfileID} key={`${bank.bankProfileID}_${bank.nickName}`} onClick={() => changeSelectedBank(bank)}>
          {`${bank.nickName} ${maskAccountNo(decrypt(bank.bankAccountNumber))}`}</MenuItem>
      )
    })
  }

  if (otpApiErrorData !== "") {
    return (
      <Box className={classes.apiErrorBox}>
        <h4 className={classes.apiError}>{otpApiErrorData}</h4>
      </Box>
    )
  }

  if (otpApiData === null) {
    return (
      <Box className={classes.loader}>
        <CircularProgress />
      </Box>
    )
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const changeLoanType = (type) => {
    setLoanType(type);
  }
 
  if (loanType === 'M') {
    return (
      <Box className={classes.multiLoanRoot}>
        <OtpMultiLoan changeLoanType={setLoanType} click={() => props.click('FAQ')} customerCareNo={props.customerCareNo} />
      </Box>
    )
  }
  
  // if (makePayLoading) {
  //   return(
  //   <Box className={classes.loader1}>
  //    <CircularProgress />
  //   </Box> 
  //   )
  // }

  if (dataLoading === false) {
    return (
      <Box className={classes.root} ref={componentRef} >
        <div>
          {showMultiLoanBtn ?
            <Box className={classes.infoBox}>
              <p className={classes.infoBoxText}>There are multiple loans linked to your account.
              You can make a payment on multiple loans at a time or choose to pay on the loan that
               you are currently signed in for.</p>
              <Box className={classes.flexEnd}>
                <Button className={classes.swtichBack}
                  onClick={() => changeLoanType('M')}>
                  SWITCH TO MULTI LOAN PAYMENT
                </Button>
              </Box>
            </Box>
            : null}

          <Grid container alignItems="center" className={classes.oneTimePaymentContainer}>
            <Box className={`${classes.paymentDetailsBox} ${classes.paymentTypeBox}`} style={{ background: '#fff' }}>
              <Box className={classes.paymentDetails}>
                <Typography className={`${classes.otpSubHeading}`}>
                  Payment Type
                </Typography>
                <Box className={`${classes.paymentBreakBox} ${classes.block}`}>
                  <Box width='100%'>
                    <div className={classes.radioBtn}>
                      <Box style={{ width: '70%' }}>
                        <RadioGroup row className={classes.alignCenter} aria-label="account_type" name="account_type" value={radioValue}
                          onChange={(e) => handleRadioChange(e, otpApiData.monthlyAmountDue)}>
                          <FormControlLabel value="Regular" checked={otpPaymentData.paymentType === 'Regular' ? true : false}
                            control={<Radio />}
                            label="Regular Monthly Payment" />
                         
                          {!mediaQuery && <LightToolTip
                            arrow placement="bottom" title={"Regular monthly payment of principal, interest, and escrow"} >
                            <InfoIcon className={classes.paymentRadioInfo} />
                          </LightToolTip> }
                          {mediaQuery && 
                          <ClickAwayListener onClickAway={handleMonthyPaymentTooltipClose}>
                          <LightToolTip
                              arrow placement="bottom" title={"Regular monthly payment of principal, interest, and escrow"}
                              open={ openMonthyPaymentTooltip }
                            >
                               <InfoIcon className={classes.paymentRadioInfo} onClick={handleMonthyPaymentTooltipOpen}/>
                            </LightToolTip>
                          </ClickAwayListener>}
                        </RadioGroup>
                        <Typography className={`${classes.bodySmallFont} ${classes.radioDes}`} arrow placement="bottom">
                          Regular monthly payment of principal, interest, and escrow
                      </Typography>
                      </Box>
                      <Typography className={`${classes.bodyFont} ${classes.bodyTitle} ${classes.alignSelftEnd}`} style={{ marginTop: '10px !important' }}>
                        ${numberWithCommas(otpApiData.monthlyAmountDue.toFixed(2))}
                      </Typography>
                    </div>

                    <div className={classes.radioBtn}>
                      <Box style={{ width: '70%' }}>
                        <RadioGroup row className={classes.alignCenter} aria-label="account_type" name="account_type" value={radioValue}
                          onChange={(e) => handleRadioChange(e, otpApiData.totalAmountDue)}>
                          <FormControlLabel value="Outstanding" checked={otpPaymentData.paymentType === 'Outstanding' ? true : false}
                            control={<Radio />}
                            label="Total Outstanding Balance" />
                         
                          {!mediaQuery && <LightToolTip
                            arrow placement="bottom" title={"Total outstanding balance including past dues / fees, etc."} >
                            <InfoIcon className={classes.paymentRadioInfo} />
                          </LightToolTip> }
                          {mediaQuery && 
                          <ClickAwayListener onClickAway={handleOutstandingBalanceTooltipClose}>
                            <LightToolTip
                                arrow placement="bottom" title={"Total outstanding balance including past dues / fees, etc."}
                                open={ openOutstandingBalanceTooltip }
                              >
                                <InfoIcon className={classes.paymentRadioInfo} onClick={handleOutstandingBalanceTooltipOpen}/>
                              </LightToolTip>
                          </ClickAwayListener>}
                        </RadioGroup>
                        <Typography className={`${classes.bodySmallFont} ${classes.radioDes}`}>
                          Total outstanding balance including past dues / fees, etc.
                      </Typography>
                      </Box>
                      <Typography className={`${classes.bodyFont} ${classes.bodyTitle} ${classes.alignSelftEnd}`} style={{ marginTop: '10px !important' }}>
                        ${numberWithCommas(otpApiData.totalAmountDue.toFixed(2))}
                      </Typography>
                    </div>

                    {otpApiData && otpApiData.isCustomAmount && <div className={classes.radioBtn}>
                      <Box style={{ width: '70%' }}>
                        <RadioGroup row className={classes.alignCenter} aria-label="account_type" name="account_type" value={radioValue}
                          onChange={(e) => handleRadioChange(e, otpApiData.monthlyAmountDue)}>
                          <FormControlLabel value="isCustomAmount" checked={otpPaymentData.paymentType === 'isCustomAmount' ? true : false}
                            control={<Radio />}
                            label="Custom Amount" />
                          
                          {!mediaQuery && <LightToolTip
                            arrow placement="bottom" title={"When there are no fees due, enter any custom amount greater than $0.00. To only pay fees due, enter $0.00"} >
                            <InfoIcon className={classes.paymentRadioInfo} />
                          </LightToolTip> }
                          {mediaQuery && 
                          <ClickAwayListener onClickAway={handleCustomBalanceTooltipClose}>
                            <LightToolTip
                                arrow placement="bottom" title={"When there are no fees due, enter any custom amount greater than $0.00. To only pay fees due, enter $0.00"}
                                open={ openCustomBalanceTooltip }
                              >
                                <InfoIcon className={classes.paymentRadioInfo} onClick={handleCustomBalanceTooltipOpen}/>
                              </LightToolTip>
                          </ClickAwayListener>}
                        </RadioGroup>
                        <Typography className={`${classes.bodySmallFont} ${classes.radioDes}`}>
                          When there are no fees due, enter any custom amount greater than $0.00. To only pay fees due, enter $0.00
                      </Typography>
                      </Box>
                      <Box className={classes.alignTextBox}>
                        <TextField
                          type="text"
                          disabled={otpPaymentData.paymentType === 'isCustomAmount' ? false : true}
                          value={otpPaymentData.paymentType === 'Outstanding' ? otpApiData.monthlyAmountDue : otpPaymentData.paymentTypeValue}
                          size='small'
                          variant='outlined'
                          onChange={handleCustomAmount}
                          className={classes.customTextInput}
                          InputProps={{
                            classes: { root: classes.textField },
                            startAdornment: (
                              <InputAdornment position="start">
                                <AttachMoneyIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      {/* <Typography className={`${classes.bodyFont} ${classes.bodyTitle} ${classes.alignSelftEnd}`} style={{ marginTop: '10px !important' }}>
                        ${numberWithCommas(otpApiData.monthlyAmountDue.toFixed(2))}
                      </Typography> */}
                    </div>}
                  </Box>
                </Box>
              </Box>
              <Box className={`${classes.additionPayment}`}>
                <Box className={`${classes.paymentDetails} ${classes.pt0}`}>
                  <Box className={classes.flexBox}>
                    <Typography className={classes.otpSubHeading}>
                      Additional Payment
                </Typography>
                    <Typography className={classes.payingMoreLink}>
                      Benefits of Paying More
                   
                      {!mediaQuery && <LightToolTip
                            arrow placement="bottom" title={BENEFIT_OF_PAYING_MORE} >
                            <InfoIcon className={classes.paymentRadioInfo} style={{marginLeft: '5px'}}/>
                          </LightToolTip> }
                      {mediaQuery && 
                      <ClickAwayListener onClickAway={handlePayingMoreTooltipClose}>
                        <LightToolTip
                            arrow placement="bottom" title={BENEFIT_OF_PAYING_MORE}
                            open={ openPayingMoreTooltip }
                          >
                            <InfoIcon className={classes.paymentRadioInfo} style={{marginLeft: '5px'}} onClick={handlePayingMoreTooltipOpen}/>
                          </LightToolTip>
                      </ClickAwayListener>}
                    </Typography>
                  </Box>
                  <Box className={classes.additionalDetails}>
                    {!addPay ? <Typography className={classes.addRemovePay} onClick={changeAddRemovePay}>
                      <AddCircleIcon className={classes.addPayRadioInfo} />
                    Add Additional Payment
                  </Typography> : null}
                    {addPay ? <>
                      <Box className={classes.inputContainer} style={{ position: 'relative' }}>
                        <TextField
                          color='primary'
                          id="outlined-loanNumber"
                          label="Additional Principal Amount"
                          variant="outlined"
                          type="text"
                          value={`${additionalPayAmount.principalAmount}`}
                          onChange={(event) => handleAdditionalPayChange(event, 'principalAmount')}
                          fullWidth
                          
                          className={`${classes.inputStyle} ${classes.pl20}`}
                          classes={{
                            root: classes.additionalPayTextField
                          }}
                          InputProps={{
                            classes: { root: classes.textField },
                            startAdornment: (
                              <InputAdornment position="start">
                                <AttachMoneyIcon />
                              </InputAdornment>
                            ),
                          }}
                          InputLabelProps={{
                            classes: { root: classes.textField }
                          }} />
                      </Box>

                      {otpApiData.isEscrowAllowed && <Box className={classes.inputContainer}>
                        <TextField
                          color='primary'
                          id="outlined-loanNumber"
                          label="Additional Escrow Amount"
                          variant="outlined"
                          type="text"
                          value={`${additionalPayAmount.escrowAmount}`}
                          onChange={(event) => handleAdditionalPayChange(event, 'escrowAmount')}
                          fullWidth
                          className={`${classes.inputStyle} ${classes.pl20}`}
                          classes={{
                            root: classes.additionalPayTextField
                          }}
                          InputProps={{
                            classes: { root: classes.textField },
                            startAdornment: (
                              <InputAdornment position="start">
                                <AttachMoneyIcon />
                              </InputAdornment>
                            ),
                          }}
                          InputLabelProps={{
                            classes: { root: classes.textField }
                          }} />
                      </Box>}
                      <Typography className={classes.addRemovePay} onClick={changeAddRemovePay}>
                        <CancelIcon className={classes.addPayRadioInfo} />
                    Remove Additional Payment
                  </Typography></> : null}
                  </Box>
                </Box>
              </Box>
              <Box className={`${classes.additionPayment}`}>
                <Box className={`${classes.paymentDetails} ${classes.ptPhone0}`}>
                  <Typography className={classes.otpSubHeading}>
                    Payment Information
                </Typography>
                  <div className={bankName.length !== 0 ? classes.addedAccount : ''}>
                    {(bankNameError !== '' && selectedBankError === '') && <Box className={classes.bankAccountError}>{bankNameError}</Box>}
                    <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
                      {bankName.length ? <><InputLabel id="borrower-type-select-outlined">Bank Account</InputLabel>
                        <Select
                          labelId="borrower-type-outlined-label"
                          id="borrower-type-select-outlined"
                          value={selectedAccountNo}
                          label="Bank Name"
                          color="primary"
                          className={classes.inputStyle}
                          fullWidth >
                          {allCoBorrwerNameData}
                        </Select></> : null}
                      {((bankNameError === '' && selectedBankError !== '') || bankName.length === 0) &&
                        <h5 className={classes.bankAccountError} style={{ textAlign: 'left', marginLeft: 0 }}>{selectedBankError}</h5>}
                    </FormControl>
                  </div>
                  <Box className={classes.addAccLink}>
                    <Typography className={classes.addRemovePay} onClick={ToggleAccountPopup}>
                      <AddCircleIcon className={classes.addPayRadioInfo} />
                    Add Bank Account
                  </Typography>
                  </Box>
                  <Box className={`${classes.payCalenders} ${classes.addedAccount}`}>
                    {otpApiData !== null ? <Calender
                      selectedAccount={selectedAccount}
                      processingFee={otpApiData.processingFee.processingFee}
                      dueDate={otpApiData.paymentDueDate}
                      latePaymentDate={otpApiData.latePaymentDate}
                      updateProcessFee={updateProcessFee}
                      processNote={otpApiData.processingFee.processingFeeNote}
                      forbearanceStatus={otpApiData.forbearanceStatus}
                      propertyState={otpPaymentData.propertyState}
                      loanStatus={otpApiData.loanStatus}
                      investorCode={otpPaymentData.investorCode}
                      interestRate={otpApiData.interestRate}
                      isBPL={otpApiData.isBPL}
                      delinquentDays={otpApiData.delinquentDays}
                      graceDays={otpApiData.graceDays} 
                      warningCode={otpApiData.warningCode}
                      lstRecurACH={props.data.lstRecurACH}
                      estDateTime={otpApiData.estDateTime} 
                      error={error}
                      paymentDate={paymentDate}
                      /> : null}

                    {updatedProcessFee > otpApiData.processingFee.processingFee
                      && <Box className={classes.warning}>
                        <ErrorIcon style={{ color: 'red', marginRight: '8px', fontSize: '22px' }} />
                        <div ><p className={classes.colorRed}>Warning:</p>
                          <h5>Payment for this date will result in a processing fee.</h5>
                        </div>
                      </Box>}
                     
                      { ((moment(otpPaymentData.paymentDate) > moment(otpApiData.latePaymentDate)) && (moment(otpApiData.latePaymentDate) > moment(otpApiData.estDateTime))) &&
                        <Box className={classes.imageContainer}>
                          <InfoIcon className={classes.infoIcon} />
                          <Typography className={classes.paragraph}>Scheduling payment for this date will result in a late fee.</Typography>
                        </Box>
                      }
                  </Box>
                </Box>
              </Box>

            </Box>
            <Box className={`${classes.paymentDetailsBox} ${classes.posRelative}`}>
              <Box className={classes.paymentDetails}>
                <Typography className={classes.paymentHeading}>
                  Payment Details
              </Typography>

                <Box className={classes.paymentBreaks}>

                  <Box className={classes.paymentBreakBox}>
                    <Typography className={classes.bodyFont}>
                      {otpPaymentData.paymentType === 'isCustomAmount' && 'Custom Amount'}
                      {otpPaymentData.paymentType === 'Regular' && 'Regular Monthly Payment'}
                      {otpPaymentData.paymentType === 'Outstanding' && 'Total Outstanding Balance'}
                      {otpPaymentData.paymentType === 'onlyForbearance' && 'Only Forbearance Fee'}
                    </Typography>
                    <Typography className={`${classes.bodyFont} ${classes.paymentDetailsFont}`}>
                      {otpPaymentData.paymentTypeValue ? "$" + numberWithCommas(parseFloat(otpPaymentData.paymentTypeValue).toFixed(2)) : '$ 0.00'}
                    </Typography>
                  </Box>
                  <Divider orientation="horizontal" flexItem />

                   {(otpPaymentData.processingFee !== null && otpPaymentData.processingFee !== '0'
                    && otpApiData.processingFeeIsDisplay)
                    &&  <>
                    <Box className={`${classes.paymentBreakBox}`}>
                      <Typography className={classes.bodyFont}>
                        Processing Fee 
                        </Typography>
                       
                       {!mediaQuery && <LightToolTip
                            arrow placement="bottom" title={PROCESSING_FEE} >
                            <InfoIcon className={classes.processingFeeInfo}/>
                          </LightToolTip> }
                      {mediaQuery && 
                      <ClickAwayListener onClickAway={handleProcessingFeeTooltipClose}>
                        <LightToolTip
                            arrow placement="bottom" title={PROCESSING_FEE}
                            open={ openProcessingFeeTooltip }
                          >
                            <InfoIcon className={classes.processingFeeInfo} onClick={handleProcessingFeeTooltipOpen}/>
                          </LightToolTip>
                      </ClickAwayListener>}   
                      <Typography className={`${classes.bodyFont} ${classes.paymentDetailsFont}`}>
                        ${numberWithCommas(otpPaymentData.processingFee.toFixed(2))}
                      </Typography>
                    </Box>
                    
                    </>
                    }

                  {otpApiData.forbearanceApplicationFee !== 0 &&
                    <Box className={`${classes.paymentBreakBox}`}>
                      <Typography className={classes.bodyFont}>
                        Forbearance Application Fee
                      </Typography>
                      <Typography className={`${classes.bodyFont} ${classes.paymentDetailsFont}`}>
                        ${numberWithCommas(otpApiData.forbearanceApplicationFee.toFixed(2))}
                      </Typography>
                    </Box>}

                  {additionalPayAmount.principalAmount !== 0 && <Box className={`${classes.paymentBreakBox}`}>
                    <Typography className={classes.bodyFont}>
                      Additional Principal Payment
                  </Typography>
                    <Typography className={`${classes.bodyFont} ${classes.paymentDetailsFont}`}>
                      ${numberWithCommas(Number(additionalPayAmount.principalAmount).toFixed(2))}
                    </Typography>
                  </Box>}
                  {additionalPayAmount.escrowAmount !== 0 && <Box className={`${classes.paymentBreakBox}`}>
                    <Typography className={classes.bodyFont}>
                      Additional Escrow Payment
                  </Typography>
                    <Typography className={`${classes.bodyFont} ${classes.paymentDetailsFont}`}>
                      ${numberWithCommas(Number(additionalPayAmount.escrowAmount).toFixed(2))}
                    </Typography>
                  </Box>}
                </Box>

                <Box className={classes.paymentTotalBox}>
                  <Box className={`${classes.paymentTotal} ${classes.paymentBreakBox}`}>
                    <Typography className={classes.bodyFont} style={{ fontWeight: '600' }}>
                      Payment Total
                  </Typography>
                    <Typography className={`${classes.bodyFont} ${classes.paymentDetailsTotal}`}>
                      ${numberWithCommas(totalPayment.toFixed(2))}
                    </Typography>
                  </Box>
                </Box>

                <Box>
                  <Typography className={`${classes.otpSubHeading} ${classes.termNCon}`}>
                    Authorization
                </Typography>

                  <Box className={`${classes.termNConitionBox} `}>
                    <Box className={classes.tncTextContainer}>
                      <TermAndConditions data={tncSummary} ref={componentRef} />
                    </Box>
                  </Box>

                  <Box p={1} textAlign="right" className={classes.printButton}>
                    <Box p={1} textAlign="right" className={classes.printButton}>
                      <ReactToPrint
                        trigger={() => <Box className={classes.printBtnIcon}>
                          Print Form
                        <PrintIcon style={{ fill: '#535D6D', fontSize: 25, marginLeft: "5px", }} />
                        </Box>}
                        content={() => componentRef.current}
                      />
                    </Box>
                  </Box>

                </Box>
                {draftOTPError !== '' && totalPayment <= otpApiData.maxPaymentAllowed && <h5 className={classes.errorMsg}>{draftOTPError}</h5>}

                {totalPayment > otpApiData.maxPaymentAllowed && <h5 className={classes.errorMsg}>
                  Payment Amount Restricted: Total payment amount can not be greater than ${numberWithCommas(otpApiData.maxPaymentAllowed)}.<p className={classes.errorMsg2}> To complete a payment greater than ${numberWithCommas(otpApiData.maxPaymentAllowed)}, please contact Customer Support.</p></h5> }

              {totalPayment <= otpApiData.maxPaymentAllowed && otpPaymentData.principalAmount >= otpApiData.unpaidPrincipalBalance && 
              <>
                <h5 className={classes.errorMsg}>
                 The amount you’ve designated is greater than your principal balance.
                 </h5>
                 <p  className={classes.errorMsg1}>Please select <a style={{color: 'blue'}} href={'./PayoffRequest'}>Request Payoff</a> if you intend to pay off your loan. The instructions regarding submission of funds to satisfy your loan in full will be outlined in the payoff statement. Please note that we cannot accept a payoff online. If you wish to make a payment towards your loan balance, please resubmit an amount that does not exceed your principal balance.</p>
                 </>
                 }
              

                <Box className={classes.btns}>
                  {/* <Button className={classes.cancelBtn}
                    onClick={() => dispatch(setPaymentOption('FAQ'))}
                    type='submit' size="large" variant="outlined" >
                    Cancel
                </Button> */}
                 
                <Button className={classes.paymentBtn}
                      onClick={() => callDebounce()}
                      disabled={disablePaymentButton || (otpApiData.isPaymentAllowed === false || (totalPayment > otpApiData.maxPaymentAllowed || totalPayment === 0 || otpPaymentData.principalAmount >= otpApiData.unpaidPrincipalBalance))  ? true : false}
                      type='submit' size="large" variant="contained" color="primary">
                      {makePayLoading ? <CircularProgress color='inherit' size={20} /> :  'REVIEW & CONTINUE'}
                </Button> 
                
                </Box>
              </Box>
            </Box>
          </Grid>
        </div>

        {showPopup ? <AddEditAccount
          newAddedBank={newAddedBankAccount}
          toggleOpen={ToggleAccountPopup}
          open={showPopup}
          bankAccounts={bankName}
          addedAcc={addedNewAccount}
          renderPage={'oneTime'}
        /> : null}

        {
          draftOTPRes && draftOTPRes.confirmationNumber && <SuccessMsgAlert
            toggleOpen={toggleOpenSuccess}
            open={true}
            totalAmount={totalPayment}
            code={draftOTPRes.confirmationNumber}
            paymentDate={moment(otpPaymentData.paymentDate).format("L")}
            selectedBank={selectedBank}
            routeToSetUpAutoPay={routeToSetUpAutoPay}
            isAutoPaySch={props.data.lstRecurACH}
            showAutoPay={props.data.isRecurringAllowed}
          />
        }
        {/* {
          isOpen && lstRecStatus == null && !props.isFromWarningPopup &&
       <DeleteConfirmation
          open={isOpen}
          popupText={{handleSubmitCloseText:'No',showCloseIcon:true,handleSubmitText:'Yes',statusTitle:'Autopay Setup',statusBody:'Would you like to have your monthly payment set up as a recurring draft?'}}
          handleCancelIcon={closeAlert}
          handleSubmitClose={closeAlert}
          handleSubmit={route}
        />

        } */}
        {
          isAlertOpen &&
          <AdditionalPaymentAlert open={true} handleCloseAlert={handleCloseAlert} routeToRequestPayOff={routeToRequestPayOff} handleClose={handleClose}
          single={true}/>
        }
        { reviewPopupStatus &&
          <ReviewPayment paymentData={otpPaymentData} bankData={selectedBank} handleContinueReviewPopup={handleContinueReviewPopup} handleCloseReviewPopup={handleCloseReviewPopup} totalPayment={totalPayment} />
        }
        
        
      </Box >
    )
  }
  return (
      <Box className={classes.spinner}>
        <img src={spinner} alt="spinner"/>
        <Typography className={classes.processing}>Processing</Typography>
        <Typography className={classes.refresh}>Please do not refresh the page or click the “Back” or “Close” button of your browser</Typography>
      </Box>
      //  <Box className={classes.loader}>
      //    <CircularProgress />  
      // </Box>
  )

}