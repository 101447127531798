import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { TextField, OutlinedInput, InputLabel } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import { TableRow, TableCell } from '@material-ui/core';

export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


export const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "10px"
  },
  head: {
    backgroundColor: "#535D6D",
    color: "#ffffff",
    fontSize: "14px",
    fontWeight: "400",
    letterSpacing: "0.36px",
    fontFamily: "Inter",
    // borderRight: "1px solid #ffffff",
    verticalAlign: "top",
    padding: "10px 20px",
    width: '30px'
  },
  body: {
    fontSize: "14px",
    fontWeight: "400",
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Inter",
    padding: "10px 20px"
  }
}))(TableCell);

export const useStyles = makeStyles((theme) => (
  {
    noDataFoundText: {
      fontFamily: "Montserrat",
      textAlign: 'center',
      fontSize: '16px',
      padding: '5px',
      fontWeight: '600'
    },
    nonEscrowed: {
      color: '#8E8E8E',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '600'
    },
    progress: {
      color: '#fd8e07',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '600'
    },
    formControl: {
      // minWidth: '200px',
    },
    container: {
      width: '90%',
      minWidth: "33.3em",
      marginBottom: '9em',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '5em',
      },
    },
    formContainer: {
      // minHeight: "300px",
      backgroundColor: '#fff',
      borderRadius: '10px',
      boxShadow: '0px 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    formContainerAccordian: {
      // minHeight: "300px",
      backgroundColor: '#FAFAFA',
      borderRadius: '0px 0px 10px 10px',
      boxShadow: '0px 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    taxformContainer1: {
      padding: '47px 87px 0px 42px',
      backgroundColor: '#FAFAFA',
      borderRadius: '0px 0px 10px 10px',
      [theme.breakpoints.down('sm')]: {
        padding: '25px',
      },
    },
    instText: {
      paddingLeft: 0,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: "0"
      },
    },
    headingText: {
      color: "#535D6D",
      fontFamily: "Montserrat",
      fontSize: "24px",
      fontWeight: "600",
      letterSpacing: "0.18px",
      lineHeight: "24px",
      marginTop: '3px',
    },
    subHeading: {
      color: "rgba(0,0,0,0.87)",
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: "500",
      letterSpacing: "0.15px",
      lineHeight: "24px",
    },
    text: {
      color: "rgba(0,0,0,0.74)",
      fontFamily: "Montserrat",
      fontSize: "16px",
      letterSpacing: "0.5px",
      lineHeight: "20px",
    },
    accountContainer: {
      padding: "0 4em 0 7.9em",
      borderRight: "1px solid #dcdddf",
      borderTop: "1px solid #dcdddf",
      marginTop: '4px',
      [theme.breakpoints.down('sm')]: {
        padding: "0 1.6em",
        borderRight: "none",
        width: "100%"
      },
    },
    accountEmail: {
      padding: "4em 2.3em 4em 0",
      [theme.breakpoints.down('sm')]: {
        padding: "2em 2.3em 2em 0",
      },
      '& .MuiTypography-body1': {
        fontFamily: "Montserrat",
        color: '#202020',
        fontSize: "16px",
        letterSpacing: "0.36px",
        lineHeight: "19px",
      },
      '& .MuiTypography-body1:last-child': {
        marginTop: '12px'
      }
    },
    formInputContainer: {
    },
    inputContainer: {
      marginBottom: '3.9em',
      width: "75%",
      [theme.breakpoints.down('sm')]: {
        width: "100%"
      },
    },
    accordianContainer: {
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        // width: '300px'
      }
    },
    faqRoot: {
      width: '100%',
      minHeight: '100vh',
      paddingTop: '20px',
      backgroundPosition: "bottom left",
      backgroundRepeat: "no-repeat",
      backgroundSize: 'contain',
    },
    transactionHistoryRoot: {
      width: '100%',
      // minHeight: '100vh',
      paddingTop: '20px',
      backgroundPosition: "bottom left",
      backgroundRepeat: "no-repeat",
      backgroundSize: 'contain',
    },
    transactionHeading: {
      width: '746px',
      height: '19px',
      color: "#444444",
      fontFamily: "Inter",
      fontSize: "16px",
      fontWeight: "700",
      lineHeight: "20px",
      padding: '30px',
      [theme.breakpoints.down('sm')]: {
        fontSize: "14px",
        fontWeight: "700",
        width: '310px'
      }
    },
    transactionDesc: {
      color: '#474747',
      width: '100%',
      height: '23px',
      fontFamily: "Inter",
      fontStyle: 'Italic',
      fontSize: "14px",
      fontWeight: "300",
      lineHeight: "20px",
      padding: '0px 30px 22px 30px',
      [theme.breakpoints.down('sm')]: {
        // width: '350px',
        padding: '20px 30px 35px 30px',
      }
    },
    paymentDetailsBox: {
      width: '100%',
      padding: '30px',
      borderRadius: '20px',
      [theme.breakpoints.down('sm')]: {
        paddingTop: '40px'
      }
    },
    escrowed: {
      color: '#fd8e07',
      width: '100%',
      height: '23px',
      fontFamily: "Inter",
      fontStyle: 'Italic',
      fontSize: "16px",
      fontWeight: "600",
      lineHeight: "19px",
      paddingLeft: '16px',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '0px',
        marginBottom: '20px'
      }
    },
    expand_icon: {
      color: '#000000',
      cursor:'pointer'
    },
    collapse_icon: {
      color: '#000000',
      marginRight: 10,
      transform: 'rotate(180deg)',
      cursor:'pointer'
    },
    accDetails: {
      // paddingBottom: '24px'
    },
    table: {
      width: '100%',
      overflowX: 'auto',
      borderRadius: '10px',
      border: '1px solid #BCBCBC'
    },

    root: {
      width: '100%',
      backgroundPosition: "bottom left",
      backgroundRepeat: "no-repeat",
      backgroundSize: 'contain',
      '& .Mui-selected': {
        // backgroundColor: 'transparent',
        color: '#555D6C',
      },
      '& .MuiPaginationItem-root': {
        // backgroundColor: 'transparent',
        color: '#007FFF',
      },
    },
    container: {
      width: '120em',
      minWidth: "33.3em",
      marginTop: '3.6em',
      marginBottom: '6.6em',
      [theme.breakpoints.down('sm')]: {
        margin: '42px 16px',
      },
    },
    description: {
      maxWidth: '780px',
      height: '56px',
      lineHeight: '19px',
      fontSize: '16px',
      fontWeight: '400',
      paddingTop: '9px'
    },
    taxInfo: {
      // marginTop: "35px",
      lineHeight: '19px',
      fontSize: '18px',
      fontWeight: '700',
      lineHeight: '22px',
      color: '#626262'
    },
    howItWork: {
      fontSize: '16px',
      fontWeight: '700',
      lineHeight: '17px',
      color: '#444444',
      fontFamily: 'Inter',
      paddingBottom: '20px'
    },
    howItWorkDesc: {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '17px',
      color: '#444444',
      fontFamily: 'Inter',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        fontSize: '15px',
        lineHeight: '18px',
        fontWeight: '600'
      },
    },
    taxInfoContainer: {
      width: '490px',
      height: "146px",
    },
    box1: {
      width: '364px',
      height: "285px",
      border: "1px solid #BCBCBC",
      marginTop: "24px",
      marginRight: '20px',
      borderRadius: '10px'
    },
    line: {
      margin: '13px 0px 30px 0px',
      borderTop: 'dotted 0px'
    },
    box1heading: {
      backgroundColor: '#626262',
      color: 'white',
      height: "40px",
      padding: '10px 65px 28px 19px',
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
      fontSize: '16px',
      fontWeight: '700'
    },
    box1Bottomheading: {
      height: "30px",
      padding: '10px',
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '24px'
    },
    transactionText: {
      color: '#626262',
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '10px',
      fontFamily: 'Inter',
      borderRadius: '0px 5px 0px 0px',
      borderBottom: '1px solid #BFBFBF',
      marginBottom: '8px',
      marginTop: '8px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '15px',
      },
    },
    detailedTransaction: {
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '17px',
      padding: '20px 10px 20px 0px',
      textAlign: 'right',
      fontFamily: 'Inter'
    },
    boxText: {
      padding: '16px 16px 2px 16px',
      fontSize: '16px',
      fontWeight: '500',
      color: '#979797',
      lineHeight: '20px',
      fontFamily: 'Inter',
      [theme.breakpoints.down('sm')]: {
        padding: '16px 16px 2px 0px',
        fontWeight: '600'
      },
    },
    boxTextValue: {
      padding: '16px 0px 2px 0px',
      fontSize: '16px',
      fontWeight: '600',
      color: '#636363',
      lineHeight: '21px',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      [theme.breakpoints.down('sm')]: {
        padding: '16px 10px 2px 2px'
      },
    },
    accContainer: {
      width: '1087px',
      height: '166px',
      border: '1px solid #A1A1A1'
    },
    tableValue: {
      fontSize: '16px',
      fontWeight: '600'
    },
    accordionSum: {
      minHeight: '67px',
      paddingTop: '5px'
    },
    iconAlign: {
      marginRight: '700px',
      height: '20px',
      backgroundColor: '#0094FF'
    },
    icon: {
      verticalAlign: 'bottom',
      height: 20,
      width: 20,
    },
    details: {
      alignItems: 'center',
      padding: '10px 0 28px 0',
      [theme.breakpoints.down('sm')]: {
        flexDirectio: 'column'
      },
    },
    column: {
      flexBasis: '20%',

    },
    column2: {
      flexBasis: '30%',
    },
    helper: {
      borderLeft: `2px solid ${theme.palette.divider}`,
      padding: theme.spacing(1, 2),
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    phoneContainer: {
      justifyContent: "space-between",
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'intial'
      }
    },
    mr10: {
      marginRight: 15,
    },
    btn: {
      color: theme.palette.primary.contrastText,
      fontFamily: 'Montserrat',
      fontSize: '16px',
      height: '36px',
      width: '135px',
      fontWeight: '600',
      marginLeft:'10px',
      [theme.breakpoints.down('xs')]: {
        margin: '35px auto 0 auto'
      },
    },
    subBtn: {
      color: theme.palette.primary.contrastText,
      fontFamily: 'Montserrat',
      fontSize: '16px',
      height: '36px',
      width: '135px',
      fontWeight: '600',
      marginLeft:'10px',
      [theme.breakpoints.down('sm')]: {
        margin: '30px auto 0 auto'
      },
    },
    textareaSection: {
      width:'100%',
      '& .MuiFormLabel-root': {
        asterisk: {
          color: 'red',
          '&$error': {
            color: 'red'
          },
        }
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: '15px'
      },
    },
    textAreaValidation: {
      color: '#202020',
      float: 'right',
      [theme.breakpoints.down('sm')]: {
        float: 'left',
      },
    },
    textArea: {
      color: "#202020",
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: 500,
      width: '100%',
      padding: "10px",
      border: 'none',
      backgroundColor: 'transparent',
      outline: 'none',
      height: '56px',
      borderRadius: '4px',
      border: '1px solid #9E9E9E',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
        width: '85%'
      },
      borderRadius: '4px',
      border: '1px solid #9E9E9E',
      "&::placeholder": {    
        fontSize: '14px', 
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '1rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        lineHeight: 1
       }
    },
    // modifiedPlaceholder: {
    //   fontSize: '14px', 
    //   color: 'rgba(0, 0, 0, 0.54)',
    //   fontSize: '1rem',
    //   fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    //   fontWeight: 400,
    //   lineHeight: 1
     
    // },
    careNo: {
      marginBottom: '0',
      [theme.breakpoints.down('xs')]: {
        marginBottom: '0',
      },
    },
    requestId: {
      fontFamily: 'Montserrat',
      fontSize: '16px',
      height: '10px',
      width: '200px',
      fontWeight: '600',
      color: '#000000',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px'
      }
    },
    documentType: {
      fontFamily: 'Montserrat',
      fontSize: '14px',
      height: '10px',
      fontWeight: '600',
      color: '#555D6C',
      paddingTop: 10,
      paddingBottom: 5,
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
        paddingBottom: '26px'
      }
    },
    label: {
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      color: '#0B1941',
      [theme.breakpoints.down('xs')]: {
        fontSize: '12px',
      },
    },
    date: {
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontWeight: '600',
      color: '#4D4D4D',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      }
    },
    completedText: {
      fontFamily: 'Montserrat',
      fontSize: '13px',
      color: '#555D6C',
      fontWeight: '600',
      paddingTop: 5,
      paddingBottom: 5,
      textAlign: "start",
      [theme.breakpoints.down('xs')]: {
        fontSize: '12px',
        wordBreak: 'break-word'
      },
    },
    documentDate: {
      color: '#4D4D4D',
      fontFamily: 'Montserrat',
      fontSize: '12px',
      fontWeight: '600',
      paddingRight: 18,
      paddingTop: 8,
      float: 'right'
    },
    details: {
      fontFamily: 'Montserrat',
      fontSize: '16px',
      height: '10px',
      width: '300px',
      fontWeight: '600',
      color: '#000000'
    },
    details1: {
      fontFamily: 'Montserrat',
      fontSize: '16px',
      width: '300px',
      fontWeight: '600',
      color: '#000000'
    },
    documentName: {
      color: '#979797',
      fontFamily: "Inter",
      fontSize: "14px",
      lineHeight: "22px",
      fontWeight: '500',
      paddingBottom: '10px',
      paddingTop: '25px'
    },
    documentDesc: {
      color: '#202020',
      fontFamily: "Inter",
      fontSize: "14px",
      lineHeight: "22px",
      fontWeight: '600',
      padding: '2px 0px 20px 0px',
    },
    documentValue: {
      color: '#202020',
      fontFamily: "Inter",
      fontSize: "14px",
      lineHeight: "22px",
      fontWeight: '500',
      padding: '2px 0px 20px 34px',
    },
    detailsSection: {
      paddingLeft: 30
    },
    clarificationSection: {
      borderRadius: '0px 0px 4px 4px',
      border: '1px solid #979797',
      background: 'rgb(0 0 0 / 3%)',
      padding: '9px 30px 3px',
      [theme.breakpoints.down('sm')]: {
        padding: '15px 15px',
      }
    },
    nameTagUN:{
      border: '2px solid #88B0C9',
      width: '30px',
      height: '30px',
      borderRadius: '50px',
      textAlign: 'center',
      '& .text': {
        color: '#88B0C9',
        textAlign: 'center',
        fontFamily: 'Montserrat',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: 'normal'
      }
    },
    verticalHr:{
      height:'7%',
      position: 'absolute',
      marginLeft: '15px',
    },
    horizontalHr:{
      width: '70px',
      marginTop: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '35px',
      },
    },
    inputStyle: {
      fontFamily: "Montserrat",
      fontSize: 16,
      fontWeight: "600",
      '&.Mui-checked': {
        color: '#000',
      },
      '& .MuiOutlinedInput-input':{
        padding:'11px 14px'
      },
      '& .MuiInputLabel-outlined': {
        color: 'rgba(0,0,0,0.87)',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: '500',
        letterSpacing: '0.15px',
        lineHeight: '2px',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#535D6D'
      },
      '& .Mui-focused.MuiInputLabel-outlined': {
        color: theme.palette.primary.main,
        fontWeight: '500',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
      },
      '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
      }
    },
    nameTag: {
      border: '2px solid #EDB36A',
      width: '30px',
      height: '30px',
      borderRadius: '50px',
      textAlign: 'center',
      '& .text': {
        color: '#F59720',
        textAlign: 'center',
        fontFamily: 'Montserrat',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: 'normal'
      }
    },
    headingText:{
      color: '#202020',
    fontFamily: 'Montserrat',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '20px',
    marginTop: '3px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    },
    commentSection:{
      display: 'flex', 
      marginLeft: '85px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '13px',
      },
    },
    headingDate:{
      color: '#4D4D4D',
      fontFamily: 'Montserrat',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: 'normal',
      marginTop: '7px',
      paddingLeft: '16px'
    },
    agentComment:{
      color: '#202020',
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '20px',
      marginLeft:'45px',

      '&.wdt':{
        width:'90%',
        marginTop:'10px',
        display:'flex',
        [theme.breakpoints.down('sm')]: {
          display: 'block',
          wordBreak: 'break-word',
          width: '100%'
        },
      }

    },
    documentDetails: {
      paddingTop: 30
    },
    transferBox: {
      width: '90%',
      textAlign: 'center',
      backgroundColor: '#dee3e7',
      fontSize: 14,
      borderRadius: 4,
      padding: 10,
      borderRadius: 5,
      lineHeight: '16px',
      wordBreak: 'break-word',
      [theme.breakpoints.down('sm')]: {
        width: '88%',
        textAlign: 'center',
        backgroundColor: '#dee3e7',
        fontSize: 11,
        borderRadius: 2,
        padding: 10,
        borderRadius: 4,
        marginTop: 20
      },
    },
    pagination: {
      float: "right",
      paddingTop: 6
    },
    downloadLink: {
      color: '#007FFF',
      fontWeight: 600,
      paddingLeft: 4,
      paddingTop: 4,
      fontSize: 14,
      fontFamily: 'Montserrat',
      textDecoration: 'underline',
      [theme.breakpoints.down('sm')]: {
        fontSize: 12
      },
    },
    warningBox: {
      display: 'flex',
      marginTop: 10
    },
    disappeared: {
      color: '#202020',
      fontFamily: 'Montserrat',
      fontSize: '12px',
      fontWeight: '600',
      marginLeft: 10
    },
    requestHistory: {
      position: 'absolute',
      cursor: 'pointer',
      fontSize: '18px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px'
      },
    },
    downloadIcon: {
      height: '24px', 
      width: '24px',
      [theme.breakpoints.down('sm')]: {
        height: '20px', 
        width: '20px',
      },
    },
    loadingRoot: {
      width: '100%',
      // height: '100vh',
      // padding: '6.4em 6%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // backgroundColor: '#ccc',
    },
  })
)

export const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: '#25ABC7',
    fontSize: '40px'
  },
  tooltip: {
    color: "#fff",
    backgroundColor: '#25ABC7',
    padding: '20px 15px',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    letterSpacing: '0.55px',
    lineHeight: '21px',
    width: '500px',
    maxWidth: '500px',
    borderRadius: '10px'
  },
}));

